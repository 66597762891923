import appsignal from "@utils/appsignal";
import request from "./request";

export const getSSOEmailDomains = async (
  abortSignal?: AbortSignal
): Promise<{
  email_domains: string[];
  excluded_emails: string[];
}> => {
  try {
    const response = await request.get("/sso/email_domains", { signal: abortSignal });
    if (!response) {
      return {
        email_domains: [],
        excluded_emails: [],
      };
    }
    return response.data;
  } catch (err) {
    if (err?.code !== "ECONNABORTED" && err?.message !== "canceled") {
      appsignal.sendError(err, (span) => {
        span.setAction("sso#getSSOEmailDomains");
      });
    }
    return {
      email_domains: [],
      excluded_emails: [],
    };
  }
};
