import { RootState } from "@redux/store";
import { setPrevUrlCookie } from "@utils/authUtils";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { USER_ROLES } from "src/constants/users";
import PrivateRoute from "./PrivateRoute";

const PrivateSponsorRoute = ({ ...props }) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  if (!currentUser.id) {
    setPrevUrlCookie(window.location.href);
    window.location.href = "/login";
  }

  if (currentUser.role !== USER_ROLES.sponsor) {
    console.warn("User %o attempted to access sponsor-only route.", currentUser);
    return <Redirect to="/403" />;
  }

  return <PrivateRoute {...props} />;
};

export default PrivateSponsorRoute;
