import toast from "@components/Toast/Toast";
import {
  proposal_institution_name_options_institutions_path,
  proposal_review_system_filter_options_path,
  review_system_options_request_for_expert_proposals_path,
  review_system_options_request_for_startup_proposals_path,
} from "@routes/routes";
import {
  ProposalsShowResponseWithProposal,
  RfpProposalForEvaluationModal,
  ScientistProposalLibraryResponse,
  SponsorDropdownRequest,
  UniversityProposalLibraryOptionsResponse,
  UniversityProposalLibraryResponse,
} from "@tsTypes/index";
import { CompanyProposalLibraryFilterOptions } from "@tsTypes/proposals";
import { ProposalDraft } from "@tsTypes/proposals/draft";
import { ProposalReviewSystemFilterOptionsResponse } from "@tsTypes/proposals/proposal_review_system_filter_options";
import { RequestableType } from "@tsTypes/requests";
import { Proposal } from "@tsTypes/schema";
import appsignal from "@utils/appsignal";
import { t } from "@utils/i18n";
import { SORT_BY_OPTIONS } from "src/views/UniversityProposalLibrary/searchOptions";
import request from "./request";

export type ProposalAsJson = Omit<Proposal, "areas_of_expertise"> & {
  areas_of_expertise: string[];
};

export const getProposalDataBySlug = async (
  slug: string,
  accessCode?: string
): Promise<ProposalsShowResponseWithProposal> => {
  const response = await request.get(`/proposals/${slug}?access_code=${accessCode}`);
  return response.data;
};

export const fetchProposalMaterials = async (slug) => {
  const response = await request.get(`/proposals/${slug}?materials=true`);
  return response.data;
};

export const batchFetchByRequest = async (requestId) => {
  const response = await request.get(`/proposals/batch_fetch`, {
    params: {
      request_id: requestId,
    },
  });
  return response.data.proposals;
};

export const markProposalAsSeen = async (proposalId) => {
  const response = await request.put(`/proposals/${proposalId}/seen`);
  return response.data;
};

export const getProposalInteractionsByRFP = async (rfpId) => {
  const response = await request.get(`/proposals/last_interactions`, {
    params: {
      request_for_proposal_id: rfpId,
    },
  });
  return response.data.proposal_interactions;
};

export const getProposalRating = async (proposalId) => {
  const response = await request.get("/proposal_ratings", {
    params: {
      proposal_id: proposalId,
    },
  });

  return response.data.ratings;
};

export const setProposalRating = async (proposalId, ratings) => {
  const response = await request.post(
    "/proposal_ratings",
    {
      ratings: {
        confidence_levels: ratings.confidenceLevels.join(","),
        weights: ratings.weights.join(","),
      },
    },
    {
      params: {
        proposal_id: proposalId,
      },
    }
  );

  return response.data.ratings;
};

export const setRequestForProposalWeights = async (requestId, weights) => {
  const response = await request.post(`/rfps/${requestId}/weights`, {
    weights: weights.join(","),
  });

  return response.data.weights;
};

export const uploadFile = async (fileFormData, options) => {
  const response = await request.post("/upload_file", fileFormData, options);

  return response.data;
};

export const deleteFile = async (proposalId) => {
  const response = await request.get(`/remove_upload/${proposalId}`);

  return response.data.success;
};

export const getCompanyProposals = async (
  options: CompanyProposalLibraryFilterOptions,
  abortSignal: AbortSignal
) => {
  try {
    const response = await request.get("/proposals", {
      signal: abortSignal,
      params: {
        options: {
          request_id_filter: options.requestIdFilter,
          country_filter: options.countryFilter,
          keyword_filter: options.keywordFilter,
          institution_type_filter: options.institutionTypeFilter,
          institution_name_filter: options.institutionNameFilter,
          status_filter: options.statusFilter,
          search_filter: options.searchFilter,
          should_order_desc: options.searchDirection === "DESC",
          page_number: options.pageNumber,
          technology_readiness_level_filter: options.technologyReadinessLevelFilter,
        },
      },
    });

    return response.data;
  } catch (e) {
    if (!abortSignal.aborted) {
      appsignal.sendError(e, (span) => {
        span.setAction("proposals#getCompanyProposals");
        span.setTags({ options: JSON.stringify(options) });
      });
    }
    return {
      proposals: [],
      proposalCount: 0,
      filteredProposalCount: 0,
      suggestions: [],
    };
  }
};

export const getCompanyProposalLibraryOptions = async (): Promise<{
  keywords: {
    areas_of_expertise: string[];
  };
  institution_names: {
    all: string[];
    universities: string[];
    startups: string[];
  };
}> => {
  try {
    const response = await request.get(proposal_institution_name_options_institutions_path());
    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("proposals#getCompanyProposalLibraryOptions");
    });
    return {
      keywords: {
        areas_of_expertise: [],
      },
      institution_names: {
        all: [],
        universities: [],
        startups: [],
      },
    };
  }
};

export const getScientistProposals = async (
  options: {
    activeFilter: boolean;
    pageNumber: number;
  },
  abortSignal: AbortSignal
): Promise<ScientistProposalLibraryResponse> => {
  try {
    const response = await request.get("/scientist_proposal_library", {
      signal: abortSignal,
      params: {
        options: {
          active_filter: options.activeFilter,
          page_number: options.pageNumber,
        },
      },
    });

    return response.data;
  } catch (e) {
    if (!abortSignal.aborted) {
      appsignal.sendError(e, (span) => {
        span.setAction("proposals#getScientistProposals");
      });
    }
    return { proposals: [], active_count: 0, archive_count: 0 };
  }
};

export const getUniversityProposals = async (
  options: {
    activeFilter: boolean;
    researcherNameFilter: string[];
    sponsorNameFilter: string[];
    requestIdFilter: string[];
    keywordFilter: string[];
    sortBy: (typeof SORT_BY_OPTIONS)[number]["value"];
    pageNumber: number;
  },
  abortSignal: AbortSignal
): Promise<UniversityProposalLibraryResponse> => {
  try {
    const response = await request.get("/university_proposal_library", {
      signal: abortSignal,
      params: {
        options: {
          active_filter: options.activeFilter,
          researcher_name_filter: options.researcherNameFilter,
          sponsor_name_filter: options.sponsorNameFilter,
          request_id_filter: options.requestIdFilter,
          keyword_filter: options.keywordFilter,
          sort_by: options.sortBy,
          page_number: options.pageNumber,
        },
      },
    });

    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("proposals#getUniversityProposals");
    });
    return { proposals: [], active_count: 0, archive_count: 0 };
  }
};

export const getUniversityProposalLibraryOptions =
  async (): Promise<UniversityProposalLibraryOptionsResponse> => {
    try {
      const response = await request.get("/university_proposal_library_options");

      return response.data;
    } catch (e) {
      appsignal.sendError(e, (span) => {
        span.setAction("proposals#getUniversityProposalLibraryOptions");
      });
      return {
        keywords: {
          areas_of_expertise: [],
        },
        researcher_name_options: [],
        sponsor_name_options: [],
        rfp_options: [],
      };
    }
  };

export const getProposalReviewSystemFilterOptions = async (
  selectedRequest: SponsorDropdownRequest
): Promise<ProposalReviewSystemFilterOptionsResponse> => {
  try {
    let requestPath = "";
    switch (selectedRequest.requestable_type) {
      case RequestableType.RFP:
      case RequestableType.OPEN_CALL:
        requestPath = proposal_review_system_filter_options_path();
        break;
      case RequestableType.REQUEST_FOR_STARTUP:
        requestPath = review_system_options_request_for_startup_proposals_path();
        break;
      case RequestableType.REQUEST_FOR_EXPERT:
        requestPath = review_system_options_request_for_expert_proposals_path();
        break;
      default:
        throw new Error("Invalid request");
    }
    const response = await request.get(requestPath, {
      params: {
        request_id: selectedRequest.id,
      },
    });
    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("proposals#getProposalReviewSystemFilterOptions");
    });
    return {
      countries: [],
      organizations: {
        universities: [],
        startups: [],
        all: [],
      },
    };
  }
};

export const deleteProposal = async (proposalId: number): Promise<{ success: boolean }> => {
  const response = await request.delete(`/proposals/${proposalId}`);
  return response.data;
};

export const setProposalPrivate = async (proposalId: number, isPrivate: boolean): Promise<void> => {
  try {
    await request.put(`/proposals/${proposalId}`, {
      proposal: {
        is_private: isPrivate,
      },
    });
  } catch (error) {
    toast.error(t("error.default"));
    appsignal.sendError(error, (span) => {
      span.setAction("proposals#setProposalPrivate");
      span.setTags({ proposalId: proposalId.toString(), isPrivate: isPrivate.toString() });
    });
  }
};

export const getProposalForEvaluationModal = async (
  proposalId: number
): Promise<RfpProposalForEvaluationModal> => {
  const response = await request.get(`/proposals/${proposalId}`, {
    params: {
      is_for_evaluation_modal: true,
    },
  });

  return response.data;
};

export const getProposalForProposalForm = async (proposalSlug: string): Promise<ProposalDraft> => {
  const response = await request.get(`/proposals/${proposalSlug}`, {
    params: {
      for_proposal_form: true,
    },
  });
  return response.data.proposal;
};

export const updateProposal = async (
  proposalSlug: string,
  proposal: ProposalDraft,
  isForProposalForm = false
): Promise<ProposalDraft> => {
  const response = await request.put(`/proposals/${proposalSlug}`, {
    proposal,
    for_proposal_form: isForProposalForm,
  });
  return response.data.proposal;
};

export const getDraftProposal = async (requestSlug: string): Promise<ProposalDraft | null> => {
  const response = await request.get(`/proposal/draft/${requestSlug}`);
  return response.data.proposal ?? null;
};

export const saveDraftProposal = async (
  requestSlug: string,
  proposal: ProposalDraft
): Promise<ProposalDraft> => {
  const response = await request.post(`/proposal/draft/${requestSlug}`, {
    proposal,
  });
  return response.data.proposal;
};
