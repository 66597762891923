import { COLORS, FONTS, STYLES } from "@constants";
import { RequestAttachmentPartial } from "@tsTypes/request_attachments/_request_attachment";
import { formatBytes, formatMIMEContentType } from "@utils/textUtils";
import styled from "styled-components";
import Icon from "../Icon";

interface Props {
  attachment: RequestAttachmentPartial;
  onClick: () => void;
}

export default function RequestAttachment({ attachment, onClick }: Props) {
  const { title, size_in_bytes, mime_content_type } = attachment;

  const fileSize = formatBytes(size_in_bytes);
  const type = formatMIMEContentType(mime_content_type);

  return (
    <Container onClick={onClick}>
      <div>
        <Icon name="Attachment" size="md" color={COLORS.NEUTRAL_500} margin="0" />
      </div>
      <Info>
        <Name>{title}</Name>
        <Meta>
          {type} &bull; {fileSize}
        </Meta>
      </Info>
    </Container>
  );
}

const Name = styled.div`
  ${FONTS.MEDIUM_3}
  ${STYLES.TWO_LINES}
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 12px 8px 8px;
  gap: 12px;
  max-width: 280px;
  min-height: 64px;
  border-radius: 8px;
  background: ${COLORS.NEUTRAL_50};

  &:hover {
    cursor: pointer;
    background: ${COLORS.NEUTRAL_100};
    ${Name} {
      text-decoration: underline;
    }
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Meta = styled.div`
  ${FONTS.REGULAR_3}
  ${STYLES.ONE_LINE}
  color: ${COLORS.NEUTRAL_600};
`;
