import { UniversityMembersOptionsResponse, UniversityMembersResponse } from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { SORT_BY_OPTIONS } from "src/views/UniversityTeam/searchOptions";
import request from "./request";

export const getUniversityMembers = async (
  options: {
    facultyFilter: boolean;
    nameFilter: string[];
    titleFilter: string[];
    keywordFilter: string[];
    sortBy: typeof SORT_BY_OPTIONS[number]["value"];
  },
  abortSignal: AbortSignal
): Promise<UniversityMembersResponse> => {
  try {
    const response = await request.get("/universities/members", {
      signal: abortSignal,
      params: {
        options: {
          faculty_filter: options.facultyFilter,
          name_filter: options.nameFilter,
          title_filter: options.titleFilter,
          keyword_filter: options.keywordFilter,
          sort_by: options.sortBy ?? "NAME",
        },
      },
    });

    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("universities#getUniversityMembers");
    });
    return { members: [], count: 0 };
  }
};

export const getUniversityMembersTabCounts = async (): Promise<{
  faculty_count: number;
  admin_count: number;
}> => {
  try {
    const response = await request.get("/universities/members/tab_counts");

    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("universities#getUniversityMembersTabCounts");
    });
    return { faculty_count: 0, admin_count: 0 };
  }
};

export const getUniversityMembersOptions = async (): Promise<UniversityMembersOptionsResponse> => {
  try {
    const response = await request.get("/universities/members/options");

    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("universities#getUniversityMembersOptions");
    });
    return {
      name_options: [],
      title_options: [],
      keyword_options: {
        disciplines: [],
        areas_of_expertise: [],
      },
    };
  }
};

export const sendInviteFromUniversityAdmin = async ({
  email,
  message,
}: {
  email: string;
  message: string;
}): Promise<boolean> => {
  try {
    const response = await request.post("/notifications/invite_from_university_admin", {
      to_emails: email,
      message,
    });

    if (!response.data.success) {
      throw new Error("Failed to send invite");
    }

    return true;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("universities#sendInviteFromUniversityAdmin");
    });
    return false;
  }
};

export const sendUniversityAdminInviteToSubmit = async ({
  toUserIds,
  requestIds,
}: {
  toUserIds: number[];
  requestIds: number[];
}): Promise<boolean> => {
  try {
    await request.post("/notifications/university_admin_invite_to_submit", {
      to_user_ids: toUserIds,
      request_ids: requestIds,
    });
    return true;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("universities#sendUniversityAdminInviteToSubmit");
    });
    return false;
  }
};
