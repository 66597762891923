import {
  proposal_path,
  request_for_expert_proposal_path,
  request_for_startup_proposal_path,
} from "@routes/routes";
import { ProposalyType } from "@tsTypes/proposaly";
import { assertAbsurd } from "./tsUtils";

export const getProposalyPath = ({
  proposalyType,
  slug,
}: {
  proposalyType: ProposalyType;
  slug: string;
}) => {
  switch (proposalyType) {
    case ProposalyType.PROPOSAL:
      return proposal_path(slug);
    case ProposalyType.REQUEST_FOR_STARTUP_PROPOSAL:
      return request_for_startup_proposal_path(slug);
    case ProposalyType.REQUEST_FOR_EXPERT_PROPOSAL:
      return request_for_expert_proposal_path(slug);
    default:
      assertAbsurd(proposalyType);
  }
};
