import api from "./request";

export const getRequestComments = async (requestId: number, sortBy: string) => {
  const response = await api.get(`/request_comments`, {
    params: {
      request_id: requestId,
      sort_by: sortBy,
    },
  });
  return response.data;
};

export const createRequestComment = ({
  requestId,
  text,
  replyToId,
  open,
}: {
  requestId: number;
  text: string;
  replyToId?: number;
  open: boolean;
}) =>
  api.post("/request_comments", {
    request_id: requestId,
    reply_to_id: replyToId,
    text,
    open,
  });

export const editRequestComment = (id: number, text?: string, open?: boolean) =>
  api.put(`/request_comments/${id}`, { text, open });

export const upvoteRequestComment = (id: number) => api.post(`/request_comments/${id}/upvote`);

export const downvoteRequestComment = (id: number) => api.post(`/request_comments/${id}/downvote`);

export const deleteRequestComment = (id: number) => api.delete(`/request_comments/${id}`);
