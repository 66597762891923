import { Link } from "react-router-dom";
import styled from "styled-components";

import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";

import macbook from "src/views/AboutUs/images/macbook.png";

interface Props {
  breakpoint: string;
  children: any;
}

const MobileRestricted = ({ breakpoint, children }: Props) => {
  return (
    <Controller breakpoint={breakpoint}>
      <Children>{children}</Children>
      <Restriction>
        <Image src={macbook} alt="Marketplace" />
        <Text>
          Access Halo on a larger screen
          <br />
          to enjoy the full experience
        </Text>
        <Link to="/">
          <Button
            type="button"
            variant="secondary"
            size="md"
            iconName="Arrow Right"
            iconPosition="right"
          >
            Go home
          </Button>
        </Link>
      </Restriction>
    </Controller>
  );
};

export default MobileRestricted;

const Children = styled.div``;

const Restriction = styled.div``;

const Controller = styled.div`
  ${Children} {
    display: unset;
  }
  ${Restriction} {
    display: none;
  }
  @media screen and (max-width: ${({ breakpoint }) => breakpoint}) {
    ${Children} {
      display: none;
    }
    ${Restriction} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 15vh;
      gap: 40px;
    }
  }
`;

const Image = styled.img`
  width: 333px;
`;

const Text = styled.div`
  ${FONTS.REGULAR_1}
  color: ${COLORS.NEUTRAL_500};
  text-align: center;
`;
