import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import styled from "styled-components";
import Icon from "../Icon";

interface Props {
  value: string;
  onChange: (value: string) => any;
  onSubmit: (value: string) => any;
  placeholder: string;
  buttonText?: string | null;
  shouldSubmitOnClear?: boolean;
}

export const SearchBarInput = ({
  value,
  onChange,
  onSubmit,
  placeholder,
  buttonText = "Apply",
  shouldSubmitOnClear = true,
}: Props) => {
  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(value);
      }}
    >
      <Icon name="Search" color={COLORS.NEUTRAL_500} margin="0 4px 0 0" />
      <Input value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
      <ClearContainer
        onClick={() => {
          onChange("");
          if (shouldSubmitOnClear) {
            onSubmit("");
          }
        }}
      >
        <Icon name="X" color={COLORS.NEUTRAL_350} size="xs" margin="0 6px 0 0" />
      </ClearContainer>
      {Boolean(buttonText) && <Button type="submit">{buttonText}</Button>}
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  align-items: center;
  padding: 0 3px 0 6px;
  height: 36px;
  border: 1px solid ${COLORS.NEUTRAL_200};
  border-radius: 6px;

  &:focus-within {
    border: 1px solid ${COLORS.HALO_BLUE};
  }

  &:hover {
    cursor: pointer;
    &:not(:focus-within) {
      border: 1px solid ${COLORS.NEUTRAL_400};
    }
    &:focus-within {
      border: 1px solid ${COLORS.HALO_BLUE};
    }
  }

  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    height: 40px;
  }
`;

const Input = styled.input`
  flex-grow: 1;
  min-width: 0;
  border: none;
  outline: none;
  color: ${COLORS.BLACK};
  ${FONTS.TAG_MEDIUM_1};

  &::placeholder {
    opacity: 1;
    color: ${COLORS.NEUTRAL_500};
    ${FONTS.REGULAR_2};
  }
`;

const ClearContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

const Button = styled.button`
  padding: 7px 14px;
  background-color: ${COLORS.BLUE_LIGHT_300};
  color: ${COLORS.HALO_BLUE};
  border: none;
  border-radius: 4px;
  ${FONTS.BUTTON_3}

  &:hover {
    background-color: ${COLORS.BLUE_LIGHT_400};
  }

  &:active {
    background-color: ${COLORS.BLUE_LIGHT_500};
  }
`;
