import { Button, PageContainer } from "@components/library";
import { COLORS, FONTS } from "@constants";
import styled from "styled-components";

const NotFound404 = () => {
  return (
    <PageContainer hasTopDivider={true}>
      <BlueBox>
        <Title>404.</Title>
        <Subtitle>Sorry, the page you&rsquo;re looking for is unavailable.</Subtitle>
        <Text>We may have removed this page, or the URL was entered incorrectly.</Text>
        <Button onClick={() => history.go(-1)}>Go back</Button>
      </BlueBox>
    </PageContainer>
  );
};

const BlueBox = styled.div`
  padding: 64px;
  background-color: ${COLORS.BLUE_LIGHT_100};
  border-radius: 8px;
`;

const Title = styled.div`
  ${FONTS.HEADING_JUMBO}
  color: ${COLORS.BLACK}
`;

const Subtitle = styled.div`
  ${FONTS.HEADING_3_REGULAR}
  color: ${COLORS.BLACK}
`;

const Text = styled.div`
  ${FONTS.REGULAR_1}
  color: ${COLORS.BLACK}
  margin-top: 8px;
  margin-bottom: 56px;
`;

export default NotFound404;
