import toast from "@components/Toast";
import api from "@requests/request";
import appsignal from "@utils/appsignal";
import { requestProposalUrlPath } from "@utils/requestUtils";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// Redirects from old url /submit_proposal/:request_slug to /submit/:request_type/:request_slug
export default function SubmitProposalRedirect() {
  const { rfp_identifier: requestSlug } = useParams();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/requests/${requestSlug}`);
        history.push(requestProposalUrlPath(response.data.request));
      } catch (err) {
        console.error(err);
        toast.error(
          "Sorry! We couldn't find the request you're looking for. Please visit the My Proposals page to see your proposals."
        );
        history.push("/404");
        appsignal.sendError(err, (span) => {
          span.setAction("SubmitProposalRedirect#useEffect");
        });
      }
    })();
  }, [requestSlug]);

  return null;
}
