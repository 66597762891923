import Conversation from "@components/Messenger/Conversation";
import { COLORS, STYLES } from "@constants";
import {
  fetchCurrentThreadByParticipants,
  fetchCurrentThreadByProposal,
} from "@redux/actions/messengerActions";
import { RootState } from "@redux/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import MessengerHeader from "./MessengerHeader";
import ParticipantsInfoModal from "./ParticipantsInfoModal";

const Messenger = () => {
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);

  const dispatch = useDispatch<ThunkDispatch<any, any, Action>>();

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const messengerData = useSelector((state: RootState) => state.messenger.messengerData);
  const isMessengerOpen = useSelector((state: RootState) => state.messenger.isMessengerOpen);
  const isMessengerThreadLoading = useSelector(
    (state: RootState) => state.messenger.isMessengerThreadLoading
  );
  const isMessengerMinimized = useSelector(
    (state: RootState) => state.messenger.isMessengerMinimized
  );
  const isMessengerExpanded = useSelector(
    (state: RootState) => state.messenger.isMessengerExpanded
  );
  const hasUnreadMessage = useSelector((state: RootState) => state.messenger.hasUnreadMessage);
  const messengerThread = useSelector((state: RootState) => state.messenger.messengerThread);
  const messengerOffsetRight = useSelector(
    (state: RootState) => state.messenger.messengerOffsetRight
  );

  useEffect(() => {
    (async () => {
      if (!messengerData.recipient) return;

      const userId: number = messengerData.recipient.id;
      const { proposalyId, proposalyType } = messengerData;

      if (proposalyId && proposalyType) {
        await dispatch(fetchCurrentThreadByProposal(userId, proposalyType, proposalyId));
      } else {
        await dispatch(fetchCurrentThreadByParticipants(currentUser.id, userId));
      }
    })();
  }, [messengerData]);

  if (!isMessengerOpen) return null;

  if (isMessengerThreadLoading && isMessengerOpen) {
    return (
      <MessengerContainer
        isMessengerMinimized={isMessengerMinimized}
        messengerOffsetRight={messengerOffsetRight}
      >
        <Loader active inline="centered" style={{ margin: "auto" }} />
      </MessengerContainer>
    );
  }

  return (
    <>
      <ParticipantsInfoModal
        messengerThread={messengerThread}
        isOpen={isParticipantsModalOpen}
        closeModal={() => setIsParticipantsModalOpen(false)}
      />
      <MessengerContainer
        isMessengerMinimized={isMessengerMinimized}
        isMessengerExpanded={isMessengerExpanded}
        messengerOffsetRight={messengerOffsetRight}
        data-testid="messenger"
      >
        <MessengerHeader
          recipient={messengerData?.recipient}
          proposalTitle={messengerData?.proposalTitle}
          isMessengerMinimized={isMessengerMinimized}
          messengerThread={messengerThread}
          isMessengerExpanded={isMessengerExpanded}
          hasUnreadMessage={hasUnreadMessage}
          setIsParticipantsModalOpen={setIsParticipantsModalOpen}
        />
        <ConversationContainer>
          <Conversation currentThread={messengerThread} />
        </ConversationContainer>
      </MessengerContainer>
    </>
  );
};

export default Messenger;

const MessengerContainer = styled.div`
  display: flex;
  flex-direction: ${({ isMessengerMinimized }) => (isMessengerMinimized ? "row" : "column")};
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.NEUTRAL_200};
  box-shadow: ${STYLES.SHADOW_C};
  border-radius: 12px 12px 0px 0px;
  width: ${({ isMessengerMinimized, isMessengerExpanded }) => {
    if (isMessengerMinimized) {
      return "216px";
    }
    if (isMessengerExpanded) {
      return "600px";
    }
    return "426px";
  }};
  height: ${({ isMessengerMinimized, isMessengerExpanded }) => {
    if (isMessengerMinimized) {
      return "52px";
    }
    if (isMessengerExpanded) {
      return "725px";
    }
    return "570px";
  }};
  position: fixed;
  max-height: calc(100% - 115px);
  right: calc(0% + ${({ messengerOffsetRight }) => messengerOffsetRight}px);
  bottom: 0px;
  z-index: 500;
  margin-left: 16px;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-duration: 167ms;
  transition-property: width, height;
  transition: right 0.25s ease-out;
`;
const ConversationContainer = styled.div`
  overflow: hidden;
  position: relative;
  flex: 1 0 0px;
  display: flex;
  height: 266px;
`;
