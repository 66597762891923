export enum CompanySize {
  UNDER_10 = "Under 10",
  TEN_TO_FIFTY = "10 - 50",
  FIFTY_TO_TWO_HUNDRED = "50 - 200",
  TWO_HUNDRED_TO_FIVE_HUNDRED = "200 - 500",
  FIVE_HUNDRED_TO_ONE_THOUSAND = "500 - 1,000",
  ONE_THOUSAND_TO_FIVE_THOUSAND = "1,000 - 5,000",
  FIVE_THOUSAND_TO_TEN_THOUSAND = "5,000 - 10,000",
  TEN_THOUSAND_PLUS = "10,000+",
}

export enum CompanySpend {
  UNDER_500K = "Under $500k",
  FIVE_HUNDRED_K_TO_ONE_MILLION = "$500k - $1M",
  ONE_MILLION_TO_TWO_AND_A_HALF_MILLION = "$1M - $2.5M",
  TWO_AND_A_HALF_MILLION_TO_FIVE_MILLION = "$2.5M - $5M",
  FIVE_MILLION_TO_TEN_MILLION = "$5M - $10M",
  TEN_MILLION_TO_ONE_HUNDRED_MILLION = "$10M - $100M",
  ONE_HUNDRED_MILLION_TO_FIVE_HUNDRED_MILLION = "$100M - $500M",
  FIVE_HUNDRED_MILLION_TO_ONE_BILLION = "$500M - $1B",
  ONE_BILLION_PLUS = "$1B+",
}

export enum CompanyType {
  RESEARCH_INSTITUTION = "Research Institution",
  GOVERNMENT_AGENCY = "Government Agency",
  NONPROFIT_FOUNDATION = "Nonprofit/Foundation",
  PRIVATELY_HELD = "Privately Held",
  PUBLIC_COMPANY = "Public Company",
}

export enum CompanyVerificationStatus {
  UNCLAIMED = "unclaimed",
  CLAIMED = "claimed",
  VERIFIED = "verified",
}
