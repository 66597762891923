import { TextLink } from "@components/library";
import { COLORS, COMPANIES, FONTS } from "@constants";
import { RfpPageRequest } from "@tsTypes/index";
import styled from "styled-components";
import {
  BASF_RFM_FAQS,
  BAYER_COLAB_CAMBRIDGE_RFS_FAQS,
  FAQS,
  FUEL_FAQS,
  TESTING4AG_FAQS,
} from "./faqs";

interface Props {
  request: RfpPageRequest;
}

const { FUEL_ID } = COMPANIES;

const RequestFAQs = ({ request }: Props) => {
  const defaultFaqs = [...FAQS];

  if (request.requestable.hubspot_faq_tag) {
    defaultFaqs.push({
      href: `https://knowledge.halo.science/rfps#${request.requestable.hubspot_faq_tag}`,
      text: "View questions specific to this opportunity",
    });
  }

  const isTesting4Ag = request.title.includes("Testing4Ag");
  const isBasfRfm = request.slug.includes(
    "free-in-vivo-testing-unleash-your-molecule-s-potential-as-a-crop-protector"
  );
  const isBayerColabCambridgeRfs = request.slug === "co-lab-cambridge";
  const isFuelRequest = request.company.id === FUEL_ID;

  let faqs = defaultFaqs;
  if (isTesting4Ag) {
    faqs = TESTING4AG_FAQS;
  } else if (isBasfRfm) {
    faqs = BASF_RFM_FAQS;
  } else if (isBayerColabCambridgeRfs) {
    faqs = BAYER_COLAB_CAMBRIDGE_RFS_FAQS;
  } else if (isFuelRequest) {
    faqs = FUEL_FAQS;
  }

  return (
    <Container>
      <Heading>Frequently Asked Questions</Heading>
      {faqs.map((faq) => (
        <div key={faq.href}>
          <TextLink to={{ pathname: faq.href }} font={FONTS.REGULAR_2} newTab>
            {faq.text}
          </TextLink>
        </div>
      ))}
    </Container>
  );
};

export default RequestFAQs;

const Container = styled.div`
  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 8px;
  padding: 24px;
  & ul {
    padding-left: 24px;
    margin-bottom: 0;
  }
`;
const Heading = styled.div`
  ${FONTS.SEMIBOLD_1};
  margin-bottom: 16px;
`;
