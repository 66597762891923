import { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Validator from "validator";

import { BlankModal, Icon, TextInput } from "@components/library";
import Button from "@components/library/Buttons/Button";
import { IconName } from "@components/library/Icon/Icon";
import { COLORS, FONTS } from "@constants";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { joinRfpWaitlist } from "@requests/rfps";
import { UserRole } from "@tsTypes/users";
import { usedPersonalEmail } from "@utils/appUtils";

const LINE_ITEMS = [
  "Submit a proposal in less than an hour.",
  "Receive feedback directly from the industry contact.",
  "Get discovered by industry partners looking for your expertise.",
];

const JoinWaitlistModal = ({ request, onClose, isOpen, handleShare }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSuccessfullySubmitted, setHasSuccessfullySubmitted] = useState(false);

  const isUniversityAdmin = currentUser?.role === UserRole.UNIVERSITY_ADMIN;
  let iconName: IconName = "History";
  let header = "Join Waitlist";
  let subheader = "Indicate your interest and get notified if the opportunity reopens.";
  let ctaText = "Join waitlist";
  if (isUniversityAdmin) {
    iconName = "Share";
    header = "Share Opportunity";
    subheader =
      "Scientists can join the waitlist to show their interest and get notified if this opportunity reopens.";
    ctaText = "Share";
  }

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();

      if (isUniversityAdmin) {
        handleShare();
        onClose();
        return;
      }

      setIsSubmitting(true);

      if (!currentUser.id && (!Validator.isEmail(email) || usedPersonalEmail(email))) {
        setError("Please use a valid company or university email.");
        return;
      }

      const payload = currentUser.id ? {} : { firstName, lastName, email };
      await joinRfpWaitlist(request.id, payload);

      setHasSuccessfullySubmitted(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (hasSuccessfullySubmitted) {
    return (
      <BlankModal isOpen={isOpen} onClose={onClose} width="540px">
        <ModalContent>
          <div>
            <Icon color={COLORS.GREEN} margin="0 auto" name="Success" size="lg" />
            <Header>Thank you!</Header>
          </div>
          <SubHeader>
            You&rsquo;ve been added to the waitlist for this opportunity.{" "}
            {!currentUser.id && "Don't have a Halo account? Create one now."}
          </SubHeader>
          {!currentUser.id && (
            <>
              <BulletList>
                {LINE_ITEMS.map((lineItem, i) => {
                  return (
                    <BulletPoint key={`lineItem-${i}`}>
                      <Icon name="Verified" color={COLORS.HALO_BLUE} />
                      <BulletText>{lineItem}</BulletText>
                    </BulletPoint>
                  );
                })}
              </BulletList>
              <div>
                <Button
                  margin="24px 0 0"
                  onClick={() => {
                    dispatch(toggleLoginModal({ open: true }));
                  }}
                >
                  Create Account
                </Button>
              </div>
            </>
          )}
        </ModalContent>
      </BlankModal>
    );
  }

  return (
    <BlankModal isOpen={isOpen} onClose={onClose} width="540px">
      <ModalContent>
        <IconContainer>
          {!isUniversityAdmin && <Icon margin="10px auto 0" name={iconName} size="lg" />}
        </IconContainer>
        <Header>{header}</Header>
        <SubHeader>{subheader}</SubHeader>
        <Form onSubmit={handleSubmit}>
          {!currentUser.id && (
            <>
              <DoubleInputContainer>
                <TextInput
                  hideClearButton
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="First Name"
                  value={firstName}
                />
                <TextInput
                  hideClearButton
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Last Name"
                  value={lastName}
                />
              </DoubleInputContainer>
              <TextInput
                errors={[
                  {
                    hasError: error.length > 0,
                    errorMessage: error,
                  },
                ]}
                hideClearButton
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                value={email}
              />
            </>
          )}
          <Button
            disabled={
              (!currentUser.id &&
                (firstName.length === 0 || lastName.length === 0 || email.length === 0)) ||
              isSubmitting
            }
            iconName={iconName}
            type="submit"
            width="100%"
          >
            {ctaText}
          </Button>
        </Form>
      </ModalContent>
    </BlankModal>
  );
};

export default JoinWaitlistModal;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 63px 92px 101px;

  width: 100%;
  height: 100%;

  text-align: center;
`;

const IconContainer = styled.div`
  min-height: 10px;
`;

const Header = styled.h3`
  margin: 0px;

  ${FONTS.HEADING_3_SEMIBOLD};
`;

const SubHeader = styled.h6`
  margin: 0 0 20px 0;

  max-width: 400px;

  color: ${COLORS.NEUTRAL_500};

  ${FONTS.MEDIUM_2};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
`;

const DoubleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const BulletList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  margin: 8px 0 0;
`;

const BulletPoint = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;

  color: ${COLORS.BLACK};

  ${FONTS.REGULAR_2};
`;

const BulletText = styled.p`
  margin: 0px;

  width: 250px;

  text-align: left;
`;
