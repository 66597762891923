import styled from "styled-components";

const List = styled.ul`
  margin: 0;
  padding-left: 20px;
`;

const TOOLTIP_CONTENT = {
  SCIENTIST_UPVOTE: <>Vote up</>,
  SCIENTIST_NEW_QUESTION: (
    <>
      <b>Pro Tips</b>
      <List>
        <li>
          Before submitting a new question, consider upvoting other questions that might address
          your inquiry
        </li>
        <li>Ask questions you think could be valuable to others</li>
        <li>Be succinct — aim for a single sentence question that addresses one core concept</li>
      </List>
    </>
  ),
  SPONSOR_NEW_ANSWER: (
    <>
      <b>Pro Tips</b>
      <List>
        <li>Answer the question as directly as possible</li>
        <li>Provide context and framing to help your audience understand your answer</li>
        <li>Keep your response to 5-8 sentences maximum</li>
      </List>
    </>
  ),
};

export default TOOLTIP_CONTENT;
