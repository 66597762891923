import { SET_IS_ABSOLUTE, SET_SHOULD_SHOW_PRODUCT_TOURS } from "@redux/actions/adminActions";
import { Reducer } from "redux";

interface AdminReducer {
  shouldShowProductTours: boolean;
  isAbsolute: boolean;
}

const initialState: AdminReducer = {
  shouldShowProductTours: false,
  isAbsolute: false,
};

const adminReducer: Reducer<AdminReducer> = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOULD_SHOW_PRODUCT_TOURS:
      return {
        ...state,
        shouldShowProductTours: action.payload,
      };
    case SET_IS_ABSOLUTE:
      return {
        ...state,
        isAbsolute: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
