import Fuse from "fuse.js";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { components } from "react-select";
import * as S from "./Dropdown.styles";

const TagOption = ({ children, ...props }) => {
  // This is the best way I found to add different styles to a "created" tag
  if (children.research_type?.includes("[New Tag]")) {
    return (
      <components.Option {...props}>
        <S.CreateLabel>
          {"Create "}
          <S.CreatedTagContainer>{children.research_type?.substring(9)}</S.CreatedTagContainer>
        </S.CreateLabel>
      </components.Option>
    );
  }
  return (
    <components.Option {...props}>
      <S.TagContainer>{children.research_type || children}</S.TagContainer>
    </components.Option>
  );
};

const fuzzySearch = (options, filter) => {
  if (!(options instanceof Array)) {
    options = [options];
  }
  if (filter.length === 0) {
    return true;
  }
  const fuseOptions = {
    threshold: 0.1,
    distance: 0,
    minMatchCharLength: 1,
    keys: ["label"],
  };
  const fuse = new Fuse(options, fuseOptions);
  return fuse.search(filter).length > 0;
};

const MenuList = ({ children, ...props }) => {
  const { creatable, isMulti } = { ...props };

  const menuHeaderText =
    props.menuHeaderText ??
    (creatable ? "Select an existing tag or create new" : "Select an existing tag");

  return <components.MenuList {...props}>{children}</components.MenuList>;
};

const CustomDropdownIndicator = (icon) => (props) => {
  return <components.DropdownIndicator {...props}>{icon}</components.DropdownIndicator>;
};

const CustomNoOptionsMessage = (customNoOptionMessage) => (props) => {
  return (
    <components.NoOptionsMessage {...props}>{customNoOptionMessage}</components.NoOptionsMessage>
  );
};

const Dropdown = (props) => {
  const {
    menuHeaderText,
    components,
    creatable,
    isMulti,
    fuzzy,
    options,
    maxOptions,
    onChange,
    value,
    icon,
    isLoading,
    styles,
    selectStyles,
    customNoOptionMessage,
    placeholder,
    menuIsOpen,
    closeMenuOnSelect,
  } = props;

  const [selectedValue, setSelectedValue] = useState(value ?? []);
  const [isMenuOpen, setIsMenuOpen] = useState(menuIsOpen);

  const selectedValueRef = useRef(selectedValue);

  const tagLimitReached = selectedValue?.length >= maxOptions;

  useEffect(() => {
    const val = value?.value || value;
    setSelectedValue(Array.isArray(val) ? val : [val]);
  }, [value]);

  useEffect(() => {
    if (isMulti && selectedValueRef.current?.length === maxOptions) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(menuIsOpen);
    }

    selectedValueRef.current = selectedValue;
  }, [selectedValue]);

  const handleChange = (onChange) => (e, sel) => {
    const val = e ? (Array.isArray(e) ? e.map((x) => x.value ?? x) : e ? [e] : []) : [];
    setSelectedValue(val);
    if (typeof onChange === "function") {
      onChange(e, sel);
    }
  };

  const placeholderText = placeholder
    ? placeholder
    : creatable
    ? "Select an existing tag or create new"
    : "Select an existing tag";

  return (
    <S.DropdownContainer {...props}>
      <S.DropdownStyles
        // note changing classNamePrefix will affect some of the default styles
        className={"Select"}
        classNamePrefix={"Select"}
        // filterOption={fuzzy ? fuzzySearch : undefined}
        placeholder={placeholderText}
        formatCreateLabel={
          isMulti
            ? (inputValue) => `[New Tag]${inputValue}`
            : (inputValue) => `Create: ${inputValue}`
        }
        closeMenuOnSelect={!isMulti ? true : closeMenuOnSelect}
        components={{
          IndicatorSeparator: () => null,
          ...(isMulti && { Option: TagOption }),
          ...(icon && { DropdownIndicator: CustomDropdownIndicator(icon) }),
          ...(isLoading && { DropdownIndicator: () => null }),
          ...(customNoOptionMessage && {
            NoOptionsMessage: CustomNoOptionsMessage(customNoOptionMessage),
          }),
          ...(tagLimitReached && {
            NoOptionsMessage: () => null,
          }),
          MenuList: (selectProps) => (
            <MenuList
              creatable={creatable}
              menuHeaderText={
                tagLimitReached ? "Max number of tags selected" : menuHeaderText ?? undefined
              }
              {...selectProps}
            />
          ),
          ...components,
        }}
        menuIsOpen={isMulti ? isMenuOpen : menuIsOpen}
        {...props}
        styles={{
          ...S.customStyles,
          ...styles,
          ...selectStyles,
        }}
        // the mapping changes 'text' to 'label' to avoid doing this everywhere the component is used
        options={tagLimitReached ? [] : options?.map((elem) => ({ label: elem.text, ...elem }))}
        onChange={handleChange(onChange)}
        value={
          value
            ? isMulti
              ? options
                  ?.map((elem) => ({ label: elem.text, ...elem }))
                  .filter((option) => selectedValue.includes(option?.value))
              : options
                  ?.map((elem) => ({ label: elem.text, ...elem }))
                  .filter((option) => String(selectedValue) === option?.value)?.[0]
            : undefined
        }
      />
    </S.DropdownContainer>
  );
};

Dropdown.propTypes = {
  /** Support multiple selected options */
  isMulti: PropTypes.bool,
  /** Support creation of new option. Use CreatableSelect instead of Select*/
  creatable: PropTypes.bool,
  /** Use AsyncSelect instead of Select. Note that you don't _have to_ use async component if you just update the options passed asyncronously.*/
  async: PropTypes.bool,
  /** Support the fuzzy search. You can customize this with the `filterOption` prop.*/
  fuzzy: PropTypes.bool,
  /** Close the select menu when the user selects an option. By default false for isMulti, and true otherwise.*/
  closeMenuOnSelect: PropTypes.bool,
  /** Array of options that populate the select menu e.g. `{ label: '', value: '' }` if you use `text` instead of `label` it is changed to `label` internally*/
  options: PropTypes.array,
  /** Placeholder for the select value */
  placeholder: PropTypes.string,
  /** Text that goes at the menu header, which is above all the options but inside the dropdown.*/
  menuHeaderText: PropTypes.string,
  /** Maximum number of options that can be selected (if it is a multi select)*/
  maxOptions: PropTypes.number,
  /** Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control. One of `"auto"`, `"bottom"`, `"top"`.*/
  menuPlacement: PropTypes.oneOf(["auto", "bottom", "top"]),
  /** Number of options to jump in menu when page{up|down} keys are used e.g. `number = 5` */
  pageSize: PropTypes.number,
  /** The value of the component. If multi, it should be an array. This value should correspond to one or more of the values of the options.*/
  value: PropTypes.any,
  /** Will cause the select to be displayed in the loading state, even if the Async select is not currently waiting for loadOptions to resolve*/
  isLoading: PropTypes.bool,
  /** Disables default icon styling*/
  disableIconColor: PropTypes.bool,
  /** Applies styles to the dropdown. This is the prefered way to style it. (see https://react-select.com/styles). Note that styles in DropdownStyles will have priority over these changes. */
  selectStyles: PropTypes.object,
  /** Whether the menu is open*/
  menuIsOpen: PropTypes.bool,
  /**
   * The `onChange` function will be passed an event or a value, action pair. e is an event when the user is typing into the Dropdown component. On actions such as clear, delete, or selection of a tag, e will be the value in the dropdown and action will be a string describing the action. For single selections, e is just the `value` of the corresponding `option` while in multi-select (with `isMulti`) dropdowns it will be an array of values.
   *
   * Example of an onChange function that handles asynchronous loading.
   * ```
   * (e, action) => {
   *   if (e.target) { // check if this is an event
   *    this.asyncAction(e.target.value) // async action you will do
   *   } else { // if it's not an event, e has the value of the dropdown
   *     this.setState({
   *      localValue: e.value,
   *     });
   *   }
   * }
   * ```
   */
  onChange: PropTypes.func,
  /** Applies error border style */
  errorColor: PropTypes.bool,
  /** Default halo styles */
  defaultShadow: PropTypes.bool,
  /** Instead of the default "No Options" have an alternative message for when no options are present */
  customNoOptionMessage: PropTypes.string,
  /** Set width. Can be any valid css value. */
  width: PropTypes.string,
  /** Styles to apply to the containing div. This is the best way to apply things like padding. */
  containerStyles: PropTypes.string,
};

Dropdown.defaultProps = {
  isMulti: false,
  creatable: false,
  async: false,
  fuzzy: true,
  menuPlacement: "auto",
  disableIconColor: false,
  errorColor: false,
};

export default Dropdown;
