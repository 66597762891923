import { ErrorBoundary as AppsignalErrorBoundary } from "@appsignal/react";
import { useSelector } from "react-redux";

import { RootState } from "@redux/store";
import appsignal from "@utils/appsignal";
import ErrorBoundaryFallback from "./ErrorBoundaryFallback";

const ErrorBoundary = ({ children }) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  return (
    <AppsignalErrorBoundary
      instance={appsignal}
      // eslint-disable-next-line react/no-unstable-nested-components
      fallback={(error) => <ErrorBoundaryFallback error={error} />}
      tags={{ userId: currentUser?.id, userEmail: currentUser?.email }}
    >
      {children}
    </AppsignalErrorBoundary>
  );
};

export default ErrorBoundary;
