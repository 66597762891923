import { AxiosResponse } from "axios";

import { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import ShareWithinCompanyModal from "@components/library/ModalBases/ShareWithinCompanyModal";
import { sponsorShareProposal } from "@requests/proposalDashboard";

export interface ProposalShareModalProps extends BlankModalProps {
  proposal: any; // pending types for all of our main objects
}

const ProposalShareModal = ({ isOpen, onClose, proposal }: ProposalShareModalProps) => {
  const handleShare = (email: string, message: string): Promise<AxiosResponse> => {
    const data = {
      proposal_id: proposal.id,
      email,
      message,
    };

    return sponsorShareProposal(data);
  };

  return (
    <ShareWithinCompanyModal
      isOpen={isOpen}
      onClose={onClose}
      headerText="Share Proposal"
      toastText="Proposal shared successfully"
      handleShare={handleShare}
    />
  );
};

export default ProposalShareModal;
