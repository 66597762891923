import { ProgressStep } from "@components/library";
import { COLORS, WINDOW_DIMENSIONS } from "@constants";
import { t } from "@utils/i18n";
import styled from "styled-components";

interface Props {
  completionState: {
    step1: boolean;
    step2: boolean;
    step3a: boolean;
    step3b: boolean;
  };
}

const i18nPrefix = "pages.onboarding_container.progress";

export function Progress({ completionState: { step1, step2, step3b } }: Props) {
  return (
    <Tabs>
      <Tab>
        <ProgressStep
          stepNumber={1}
          label={t([i18nPrefix, "step1"])}
          isCurrent={!step1}
          isComplete={step1}
          needsUpdate={false}
          formVariant="proposal"
        />
        <TabUnderline isCurrent={!step1} isComplete={step1} />
      </Tab>
      <Tab>
        <ProgressStep
          stepNumber={2}
          label={t([i18nPrefix, "step2"])}
          isCurrent={step1 && !step2}
          isComplete={step2}
          needsUpdate={false}
          formVariant="proposal"
        />
        <TabUnderline isCurrent={step1 && !step2} isComplete={step2} />
      </Tab>
      <Tab>
        <ProgressStep
          stepNumber={3}
          label={t([i18nPrefix, "step3"])}
          isCurrent={step1 && step2 && !step3b}
          isComplete={step3b}
          needsUpdate={false}
          formVariant="proposal"
        />
        <TabUnderline isCurrent={step1 && step2 && !step3b} isComplete={step3b} />
      </Tab>
    </Tabs>
  );
}
const Tabs = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin-bottom: 72px;
  padding: 0 50px;

  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    padding: 0 10px;
  }
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const TabUnderline = styled.div`
  width: 100%;
  height: 5px;
  background: ${({ isCurrent }) => {
    if (isCurrent) return COLORS.BLUE_LIGHT_500;
    return COLORS.NEUTRAL_200;
  }};
  border-radius: 4px;
  &:after {
    display: block;
    content: "";
    height: 5px;
    width: ${({ isCurrent }) => (isCurrent ? "50%" : "100%")};
    background: ${({ isCurrent, isComplete }) => {
      if (isCurrent) return COLORS.HALO_BLUE;
      if (isComplete) return COLORS.GREEN;
      return COLORS.NEUTRAL_200;
    }};
  }
`;
