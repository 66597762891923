import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getRfpForRfpPage } from "@requests/rfps";
import { css } from "aphrodite";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";

import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RfpPageRequest } from "@tsTypes/index";
import { track } from "@utils/appUtils";
import LoginModal from "./LoginModal";
import styles from "./LoginModal.styles";
import SignUpModal from "./SignUpModal";

export type ModalType = "signup" | "login";

function LoginSignUpModal() {
  const [modalType, setModalType] = useState<ModalType>("signup");
  const [currentRFP, setCurrentRFP] = useState<RfpPageRequest>();

  const loginModalOpen = useSelector((state: RootState) => state.profiles.loginModalOpen);
  const previousAction = useSelector((state: RootState) => state.profiles.previousAction);
  const loginModalLanding = useSelector((state: RootState) => state.profiles.loginModalLanding);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const isRfpPage =
        window.location.pathname.includes("/research/") &&
        window.location.pathname.split("/").length > 3;

      if (isRfpPage) {
        const identifier = window.location.pathname.split("/").at(-1);
        try {
          const { request } = await getRfpForRfpPage(identifier!);
          setCurrentRFP(request);
        } catch (e) {
          if (e.response.status === 401) {
            console.error("Waiting for access code");
            return;
          }
          throw e;
        }
      }
    })();
  }, []);

  useEffect(() => {
    setModalType(loginModalLanding as ModalType);
  }, [loginModalLanding]);

  const modalWidth = () => {
    if (modalType === "signup") {
      return "720px";
    }
    return "450px";
  };

  const closeLoginModal = () => {
    dispatch(toggleLoginModal({ open: false }));
  };

  if (loginModalOpen) {
    track(SegmentEventName.View, {
      ui_component: "Login Modal",
    });
  }

  return (
    <Modal
      open={loginModalOpen}
      onClose={closeLoginModal}
      size="small"
      className={css(styles.LoginModal_modal)}
      style={{ width: modalWidth(), maxWidth: "95%" }}
    >
      <div data-testid="login-modal">
        {modalType === "signup" && (
          <SignUpModal
            onClose={closeLoginModal}
            currentRFP={currentRFP}
            previousAction={previousAction}
            setModalType={setModalType}
          />
        )}
        {modalType === "login" && <LoginModal setModalType={setModalType} compat />}
      </div>
    </Modal>
  );
}

export default LoginSignUpModal;
