export const FAQS = [
  {
    href: "https://knowledge.halo.science/what-is-the-proposal-process",
    text: "What are the proposal questions?",
  },
  {
    href: "https://knowledge.halo.science/where-do-i-submit-a-proposal",
    text: "Where do I submit a proposal?",
  },
  {
    href: "https://knowledge.halo.science/who-is-eligible-to-apply",
    text: "Who is eligible to submit a proposal?",
  },
  {
    href: "https://knowledge.halo.science/do-proposals-require-confidential-information-faq",
    text: "Do proposals require confidential information?",
  },
  {
    href: "https://knowledge.halo.science/can-university-administrators-join-halo",
    text: "Can university administrators join Halo?",
  },
  {
    href: "https://knowledge.halo.science/are-indirect-costs-included-in-the-funding-range-provided-by-sponsors",
    text: "Are indirect costs included in the funding provided?",
  },
  {
    href: "https://knowledge.halo.science/does-halo-charge-universities-or-scientists",
    text: "Does Halo charge universities?",
  },
];

export const TESTING4AG_FAQS = [
  {
    href: "https://knowledge.halo.science/what-are-the-proposal-questions-for-testing4ag",
    text: "What are the proposal questions for Testing4Ag?",
  },
  {
    href: "https://knowledge.halo.science/what-happens-after-i-submit-a-proposal",
    text: "What happens after I submit a proposal for Testing4Ag?",
  },
  {
    href: "https://knowledge.halo.science/bayer-testing4ag-shared-results",
    text: "How will the results be shared with researchers?",
  },
  {
    href: "https://knowledge.halo.science/why-is-an-mta-required-for-testing4ag",
    text: "Why is an MTA required?",
  },
  {
    href: "https://knowledge.halo.science/what-are-the-terms-of-the-mta-for-bayers-testing4ag-program",
    text: "What are the terms of the MTA?",
  },
  {
    href: "https://knowledge.halo.science/what-if-i-have-questions-about-the-mta-for-bayers-testing4ag-program",
    text: "What if I have questions about the MTA?",
  },
];

export const BASF_RFM_FAQS = [
  {
    href: "https://knowledge.halo.science/information-basf-request-for-materials",
    text: "What information will I need to share to start the Request for Materials process with BASF?",
  },
  {
    href: "https://knowledge.halo.science/what-does-the-request-for-materials-process-with-basf-look-like",
    text: "What does the Request for Materials process with BASF look like?",
  },
  {
    href: "https://knowledge.halo.science/what-biological-results-will-i-get-from-basf",
    text: "What biological results will I get from BASF?",
  },
  {
    href: "https://knowledge.halo.science/do-i-still-own-the-rights-to-my-molecules",
    text: "Do I still own the rights to my molecules?",
  },
  {
    href: "https://knowledge.halo.science/what-if-i-have-questions-about-the-basf-mta",
    text: "What if I have questions about the BASF MTA?",
  },
];

export const BAYER_COLAB_CAMBRIDGE_RFS_FAQS = [
  {
    text: "Are selected startups for Co.Lab Cambridge expected to relocate?",
    href: "https://knowledge.halo.science/are-selected-startups-expected-to-relocate",
  },
  {
    text: "How long is the Co.Lab Cambridge incubator program?",
    href: "https://knowledge.halo.science/how-long-is-the-co.lab-cambridge-incubator-program",
  },
  {
    text: "Is there a virtual option for the Co.Lab incubator program?",
    href: "https://knowledge.halo.science/is-there-a-virtual-option-for-the-co.lab-cambridge-incubator-program",
  },
  {
    text: "If my startup is already based in Cambridge, am I still eligible to apply to Co.Lab Cambridge?",
    href: "https://knowledge.halo.science/if-my-startup-is-already-based-in-cambridge-am-i-still-eligible-to-apply-to-co.lab-cambridge",
  },
];

export const FUEL_FAQS = [
  {
    href: "https://knowledge.halo.science/fuel-eligibility",
    text: "What are the eligibility requirements for FUEL requests?",
  },
  {
    href: "https://knowledge.halo.science/find-more-info-about-fuel",
    text: "Where can I find more information about FUEL, its opportunities, proposal budgets, eligibility, or similar topics?",
  },
  {
    href: "https://knowledge.halo.science/fuel-budget-constraints",
    text: "What are the budget requirements and constraints for FUEL requests?",
  },
  {
    href: "https://knowledge.halo.science/can-the-same-proposal-submitted-to-fuel-be-submitted-to-other-agencies-or-sponsors-for-simultaneous-review",
    text: "Can the same proposal submitted to FUEL be submitted to other agencies or sponsors for simultaneous review?",
  },
];
