import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { useState } from "react";
import styled from "styled-components";

const PreviewRfpPageBanner = () => {
  const [buttonText, setButtonText] = useState("Copy Link");

  const handleCopyLink = async () => {
    const url = window.location.href;
    await navigator.clipboard.writeText(url);

    setButtonText("Copied!");
    setTimeout(() => setButtonText("Copy Link"), 2000);
  };

  return (
    <Container>
      This is a preview and not live on Halo
      <Button type="button" onClick={handleCopyLink} width="125px">
        {buttonText}
      </Button>
    </Container>
  );
};

export default PreviewRfpPageBanner;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 73px;
  background-color: ${COLORS.BLACK};
  ${FONTS.MEDIUM_1};
  font-weight: 450; // Normally we don't need font-weight with the FONTS constants, but this is a special case
  color: ${COLORS.WHITE};
  padding: 0 24px 0 32px;
`;
