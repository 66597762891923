import { useEffect, useRef } from "react";

// Utility hook to indicate whether the current render is the first render
// Used for skipping first render for certain useEffect hooks
export const useIsMount = (): boolean => {
  const isMountRef = useRef(true);

  useEffect(() => {
    isMountRef.current = false;
  }, []);

  return isMountRef.current;
};
