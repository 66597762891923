import NavbarLink from "@components/Navbar/NavbarLink";
import { InfoTag } from "@components/library";
import { COLORS } from "@constants";
import { RootState } from "@redux/store";
import { marketplacePath } from "@routes/utils";
import { t } from "@utils/i18n";
import { useSelector } from "react-redux";
import styled from "styled-components";

const ScientistNavbarLinks = () => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const unreadCount = useSelector((state: RootState) => state.profiles.unreadCount);

  return (
    <UserLinks>
      <NavbarLink to={marketplacePath()}>{t("components.navbar.links.marketplace")}</NavbarLink>
      <NavbarLink to="/my_proposals" withNewTag>
        {t("components.navbar.links.my_proposals")}
      </NavbarLink>
      {currentUser.profile_info.is_expert && (
        <NavbarLink to="/review_system">{t("components.navbar.links.expert_dashboard")}</NavbarLink>
      )}
      <NavbarLink to="/inbox">
        {t("components.navbar.links.inbox")}
        {unreadCount > 0 && (
          <InfoTag marginLeft="4px" backgroundColor={COLORS.RED_700}>
            {unreadCount}
          </InfoTag>
        )}
      </NavbarLink>
    </UserLinks>
  );
};

export default ScientistNavbarLinks;

// For use in LoggedOutNavbarLinks, ScientistNavbarLinks, SponsorNavbarLinks, and UniversityAdminNavbarLinks
// Note: This needs to be exported from one of the above components and will not work if exported from Navbar.tsx
export const UserLinks = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  margin-left: auto;
`;
