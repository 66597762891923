import { CompanyAvatar, MessageAttachment, ProfilePicture } from "@components/library";
import { COLORS, FONTS } from "@constants";
import {
  InboxMessageThread,
  MessageAttachmentPartial,
  MessagePartial,
  MessageRecipient,
} from "@tsTypes/index";
import { User } from "@tsTypes/schema";
import { UserRole } from "@tsTypes/users";
import { renderNewlines } from "@utils/textUtils";
import { AxiosError } from "axios";
import { formatDistance } from "date-fns";
import { useEffect, useRef } from "react";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  messages: MessagePartial[];
  messageError: AxiosError | undefined;
  recipient?: MessageRecipient | Pick<User, "role">;
  currentThread?: InboxMessageThread | null;
  isInbox: boolean;
  isLoading: boolean;
  openGallery: (attachments: MessageAttachmentPartial[], attachmentId: number) => void;
}

function MessageList({
  messages,
  messageError,
  isInbox = false,
  recipient,
  isLoading,
  currentThread,
  openGallery,
}: Props) {
  const endOfMessagesRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      endOfMessagesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 50);
  }, [messages]);

  if (isLoading && messages.length === 0) {
    return (
      <Container>
        <Loader active inline="centered" style={{ margin: "auto" }} />
      </Container>
    );
  }

  return (
    <Container data-testid="messages-list">
      <Messages>
        {messages.length && currentThread ? (
          messages.map((message, index: number) => {
            const isFromExternalLead =
              message.user.role === UserRole.SCIENTIST && !("id" in message.user);
            const isUserHidden = Boolean(
              currentThread &&
                "company" in currentThread &&
                "private_alias" in currentThread.company
            );

            return (
              <Message
                key={`inbox-message-${message.id}`}
                ref={index === messages.length - 1 ? endOfMessagesRef : null}
                isInbox={isInbox}
                data-testid="message"
              >
                <MessageTime>
                  {formatDistance(new Date(), new Date(message?.created_at ?? ""))} ago
                </MessageTime>
                <MessageContainer>
                  <ProfilePictureContainer>
                    {isFromExternalLead && !isUserHidden ? (
                      <CompanyAvatar
                        isPrivate={isUserHidden}
                        imageUrl={
                          "company" in currentThread && "avatar" in currentThread.company
                            ? currentThread.company.avatar.url
                            : undefined
                        }
                        size="sm"
                      />
                    ) : (
                      <ProfilePicture
                        size="sm"
                        user={message.user as MessageRecipient}
                        isUserHidden={isUserHidden}
                      />
                    )}
                  </ProfilePictureContainer>
                  <MessageContent data-testid="message-content">
                    {message.message_attachments.length > 0 && (
                      <MessageAttachmentContainer>
                        {message.message_attachments.map((attachment) => (
                          <MessageAttachment
                            key={`sent-attachment-${attachment.id}`}
                            attachment={attachment}
                            isInbox={isInbox}
                            onClick={() => openGallery(message.message_attachments, attachment.id)}
                          />
                        ))}
                      </MessageAttachmentContainer>
                    )}
                    {renderNewlines(message.content)}
                  </MessageContent>
                </MessageContainer>
              </Message>
            );
          })
        ) : (
          <MessageContent>
            {messageError ? (
              <ZeroMessagesState>
                Something went wrong loading your messages. Please try again later
              </ZeroMessagesState>
            ) : (
              <ZeroMessagesState>
                {`This is the beginning of your conversation with ${
                  (recipient as MessageRecipient | undefined)?.name
                }`}
              </ZeroMessagesState>
            )}
          </MessageContent>
        )}
      </Messages>
    </Container>
  );
}

export default MessageList;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.NEUTRAL_300};
  }
`;

const MessageTime = styled.div`
  ${FONTS.MEDIUM_3}
  color: ${COLORS.NEUTRAL_400};
  margin-bottom: 16px;
`;

const Message = styled.div`
  margin-bottom: 28px;
  padding: ${(props) => (props.isInbox ? "0 30px 0 0" : "0 19px")};

  &:last-child {
    margin-bottom: ${(props) => (props.isInbox ? "24px" : "18px")};
  }
  ${({ isInbox }) =>
    !isInbox &&
    `
      &:first-child {
        margin-top: 18px;
      }
    `}
`;

const MessageContainer = styled.div`
  display: flex;
  overflow-y: hidden;
  flex: 1 0 0px;
  word-break: break-word;
`;

const ProfilePictureContainer = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 12px;
`;

const MessageContent = styled.div`
  ${FONTS.REGULAR_2}
`;

const MessageAttachmentContainer = styled.div`
  & > div {
    margin-bottom: 8px;
  }
`;

const Messages = styled.div`
  margin-top: auto;
`;
const ZeroMessagesState = styled.div`
  ${FONTS.MEDIUM_2};
  color: ${COLORS.NEUTRAL_500};
  max-width: calc(100% - 94px);
  padding-left: 12px;
`;
