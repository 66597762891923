import toast from "@components/Toast/Toast";
import { ShowSubscription } from "@tsTypes/subscriptions/show";
import appsignal from "@utils/appsignal";
import api from "./request";

export const getMostRecentSubscription = async (
  companyIdentifier: string
): Promise<ShowSubscription | null> => {
  try {
    const response = await api.get(`/subscriptions/${companyIdentifier}`);
    return response.data.subscription;
  } catch (error) {
    switch (error.response.status) {
      case 404:
        return null;
      default:
        toast.error("Could not fetch company subscription data.");
        appsignal.sendError(error, (span) => {
          span.setAction("getCurrentSubscription");
          span.setTags({
            companyIdentifier,
          });
        });
        return null;
    }
  }
};
