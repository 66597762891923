import { Icon, MultiSelectDropdown } from "@components/library";
import type { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { useDynamicOptions } from "./searchOptions";

interface Props {
  isOpen: boolean;
  nameFilter: DropdownOption[];
  setNameFilter: Dispatch<SetStateAction<DropdownOption[]>>;
  titleFilter: DropdownOption[];
  setTitleFilter: Dispatch<SetStateAction<DropdownOption[]>>;
  keywordFilter: DropdownOption[];
  setKeywordFilter: Dispatch<SetStateAction<DropdownOption[]>>;
}

const UniversityTeamSearch = ({
  isOpen,
  nameFilter,
  setNameFilter,
  titleFilter,
  setTitleFilter,
  keywordFilter,
  setKeywordFilter,
}: Props) => {
  const {
    isLoading: isOptionsLoading,
    nameOptions,
    titleOptions,
    keywordOptions,
  } = useDynamicOptions();

  const handleClearFilter = () => {
    setNameFilter([]);
    setTitleFilter([]);
    setKeywordFilter([]);
  };

  const filtersCount = nameFilter.length + titleFilter.length + keywordFilter.length;

  if (!isOpen) return null;

  return (
    <Container>
      <TopRow>
        <MultiSelectDropdown
          label="Faculty member"
          onChange={(e) => setNameFilter(e)}
          value={nameFilter}
          options={nameOptions}
        />
        <MultiSelectDropdown
          label="Title"
          onChange={(e) => setTitleFilter(e)}
          value={titleFilter}
          options={titleOptions}
        />
        <MultiSelectDropdown
          label="Keywords"
          onChange={(e) => setKeywordFilter(e)}
          value={keywordFilter}
          options={keywordOptions}
        />
      </TopRow>
      <FooterButtons>
        <ClearFilterButton type="button" onClick={handleClearFilter}>
          {filtersCount > 0 && (
            <>
              <Icon name="Refresh" margin="0 4px 2px 0" color={COLORS.HALO_BLUE} />
              Clear all filters
            </>
          )}
        </ClearFilterButton>
        <MissingAFilter>
          Missing a filter?&nbsp;
          <ContactLink type="button" onClick={() => window.open("/contact", "_blank")}>
            Let us know
          </ContactLink>
        </MissingAFilter>
      </FooterButtons>
    </Container>
  );
};

export default UniversityTeamSearch;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  padding: 24px;
  background-color: ${COLORS.NEUTRAL_50};
  border-radius: 8px;
`;

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 0 16px;
  margin: 0 0 24px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    grid-template-columns: 1fr;
    grid-gap: 24px 0;
  }
`;
const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
// Used in other filtering components
export const ClearFilterButton = styled.button`
  display: flex;
  align-items: center;
  color: ${COLORS.HALO_BLUE};
  border: none;
  background-color: transparent;
  ${FONTS.MEDIUM_2};
`;
export const MissingAFilter = styled.div`
  margin-left: auto;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_2}
`;
export const ContactLink = styled.button`
  color: ${COLORS.NEUTRAL_500};
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;
`;
