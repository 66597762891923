import NavbarLink from "@components/Navbar/NavbarLink";
import { InfoTag, TextButton } from "@components/library";
import { COLORS } from "@constants";
import { RootState } from "@redux/store";
import { scientistNetworkName, t } from "@utils/i18n";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { UserLinks } from "./ScientistNavbarLinks";

const SponsorNavbarLinks = () => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const unreadCount = useSelector((state: RootState) => state.profiles.unreadCount);
  const subscription = useSelector((state: RootState) => state.company.subscription);
  const lastViewedRfpIdentifier = useSelector(
    (state: RootState) => state.defaultReducer.lastViewedRfpIdentifier
  );

  if (!currentUser.profile_info.verified && !currentUser.profile_info.is_admin)
    return <Container />;

  const showBackToProposalReviewDashboard =
    currentUser.profile_info.verified &&
    currentUser.profile_info.commenter &&
    !location.pathname.startsWith("/review_system");

  return (
    <Container showBackToProposalReviewDashboard={showBackToProposalReviewDashboard}>
      {showBackToProposalReviewDashboard && (
        <ButtonLink to={`/review_system?request=${lastViewedRfpIdentifier}`}>
          <TextButton text="Back to proposal review" iconName="Arrow Left" iconPosition="left" />
        </ButtonLink>
      )}
      {!currentUser.profile_info.commenter && (
        <NavbarLink to="/reviewer_dashboard">
          {t("components.navbar.links.reviewer_dashboard")}
        </NavbarLink>
      )}
      {subscription?.features.proposal_library && (
        <NavbarLink to="/proposal_library">
          {t("components.navbar.links.proposal_library")}
        </NavbarLink>
      )}
      {subscription?.features.company_scientist_network && (
        <NavbarLink to="/network">{scientistNetworkName(subscription)}</NavbarLink>
      )}
      {currentUser.profile_info.verified && (
        <NavbarLink to="/reviewer_dashboard/teams">
          {t("components.navbar.links.my_team")}
        </NavbarLink>
      )}
      {currentUser.profile_info.verified && !currentUser.profile_info.commenter && (
        <NavbarLink to="/inbox" data-testid="navbar-inbox-link">
          {t("components.navbar.links.inbox")}
          {unreadCount > 0 && (
            <InfoTag marginLeft="4px" backgroundColor={COLORS.RED_700}>
              {unreadCount}
            </InfoTag>
          )}
        </NavbarLink>
      )}
    </Container>
  );
};

export default SponsorNavbarLinks;

const Container = styled(UserLinks)`
  ${({ showBackToProposalReviewDashboard }) => showBackToProposalReviewDashboard && `width: 100%;`}
`;
const ButtonLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: auto;
  &:hover {
    text-decoration: none;
  }
`;
