import {
  CompanyAvatar,
  Icon,
  IconButton,
  MoreActionsDropdown,
  UserPreview,
} from "@components/library";
import type { ExtendedDropdownOption } from "@components/library/Dropdowns/DropdownOption";
import { COLORS, FONTS, STYLES } from "@constants";
import type { RootState } from "@redux/store";
import { InboxMessageThread, MessageRecipient } from "@tsTypes/index";
import { UserRole } from "@tsTypes/users";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  currentThread: InboxMessageThread;
  isModal?: boolean;
}

const Participants = ({ currentThread, isModal }: Props) => {
  if ("proposal" in currentThread) {
    const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

    const { company, scientist_user } = currentThread;

    const isCompanyHidden = "private_alias" in company;

    const moreActionsOptions: ExtendedDropdownOption[] = [
      {
        value: "VIEW_PROFILE",
        label: `View Profile`,
        onSelect: () => window.open(`/profile/${scientist_user.profile_id}`, "_blank"),
        iconName: "Profile",
      },
    ];

    return (
      <>
        <div data-testid="scientist-participant">
          <SectionTitle>Scientist</SectionTitle>
          <UserPreviewContainer>
            <UserPreview size="sm" user={scientist_user} />
            {isModal && (
              <MoreActionsDropdown options={moreActionsOptions} menuHorizontalPosition="right">
                <IconButton
                  size="sm"
                  variant="fourth"
                  iconName="More Actions"
                  tooltipWidth="100px"
                />
              </MoreActionsDropdown>
            )}
          </UserPreviewContainer>
        </div>
        <CompanySectionTitle data-testid="company-participant">
          <UnstyledLink
            as={isCompanyHidden ? "div" : Link}
            to={!isCompanyHidden ? `/company/${company.identifier}` : undefined}
            target="_blank"
          >
            {isCompanyHidden ? company.private_alias ?? "Private Company" : company.company_name}
          </UnstyledLink>
        </CompanySectionTitle>
        <CompanyMembers isModal={isModal} data-testid="company-members">
          {("company_members" in currentThread && !isCompanyHidden
            ? currentThread.company_members
            : [{}]
          ).map((member) => {
            const isScientistUser = currentUser.id === currentThread.scientist_user.id;
            const shouldDisplayAsExternalLead =
              isScientistUser && member.role === UserRole.SCIENTIST && !("id" in member);

            return shouldDisplayAsExternalLead && !isCompanyHidden ? (
              <ExternalLead>
                <CompanyAvatar
                  isPrivate={isCompanyHidden}
                  imageUrl={company.avatar.url}
                  size="sm"
                />
                <ExternalLeadName>{company.company_name} team</ExternalLeadName>
              </ExternalLead>
            ) : (
              <UserPreviewContainer key={(member as MessageRecipient).id}>
                <UserPreview
                  size="sm"
                  user={member as MessageRecipient}
                  userRole={isScientistUser ? UserRole.SPONSOR : member.role}
                  isUserHidden={isCompanyHidden}
                  isExternalLead={member.role === UserRole.SCIENTIST}
                />
              </UserPreviewContainer>
            );
          })}
        </CompanyMembers>
        {currentUser.role === UserRole.SPONSOR && (
          <Warning isModal={isModal}>
            <Icon name="Proposal Privacy" color={COLORS.ORANGE} size="lg" margin="0 0 12px 0" />
            This conversation is stored with the proposal so you and your team can reference it in
            the future.
          </Warning>
        )}
      </>
    );
  }

  // Create map of participants grouped by location
  const dmParticipants = new Map();
  [...(currentThread.recipients ?? [])].forEach((participant) => {
    if (!participant) return;
    if (!dmParticipants.has(participant.location)) {
      dmParticipants.set(participant.location, [participant]);
      return;
    }
    dmParticipants.get(participant.location).push(participant);
  });

  return (
    <DMParticipants>
      {Array.from(dmParticipants.keys()).map((location) => (
        <div key={location}>
          <DMSectionTitle>{location}</DMSectionTitle>
          {dmParticipants.get(location).map((participant) => (
            <UserPreviewContainer key={`user-preview-${participant.id}`}>
              <UserPreview size="sm" user={participant} userRole={participant.role} />
            </UserPreviewContainer>
          ))}
        </div>
      ))}
    </DMParticipants>
  );
};

export default Participants;

const UnstyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
`;

const SectionTitle = styled.div`
  ${FONTS.SEMIBOLD_2}
  color: ${COLORS.NEUTRAL_500};
  margin-bottom: 12px;
`;

const CompanySectionTitle = styled(SectionTitle)`
  margin-top: 32px;
`;

const DMParticipants = styled.div`
  margin-top: -32px;
`;

const DMSectionTitle = styled(SectionTitle)`
  margin-top: 32px;
`;

const CompanyMembers = styled.div`
  ${({ isModal }) => isModal && "min-height: 158px"};
  ${({ isModal }) => !isModal && "margin-bottom: 16px"};
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.NEUTRAL_300};
  }
`;

const ExternalLead = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 6px;
  margin-bottom: 10px;
  margin-left: 14px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const ExternalLeadName = styled.div`
  height: fit-content;
  margin-top: 2px;
  ${STYLES.TWO_LINES};
  ${FONTS.TAG_SEMIBOLD_1};
`;

const Warning = styled.div`
  ${FONTS.MEDIUM_3};
  background-color: ${COLORS.ORANGE_100};
  color: ${COLORS.ORANGE};
  border-radius: 6px;
  padding: 20px;
  height: 142px;
  ${({ isModal }) =>
    isModal &&
    `
      height: 88px;
      display: flex;
      align-items: center;
      ${FONTS.MEDIUM_2};
      gap: 10px;
    `}
`;

const UserPreviewContainer = styled.div`
  margin-bottom: 10px;
  margin-left: 16px;
  display: flex;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;
