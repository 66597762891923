import { request_for_proposals_notification_path } from "@routes/routes";
import {
  NotificationRfpsResponse,
  NotificationSettingsShowResponse,
  NotificationUserInfoResponse,
} from "@tsTypes/index";
import api from "./request";

export const fetchNotificationSettings = async (
  userId: number
): Promise<NotificationSettingsShowResponse> => {
  const responseJson = await api.get(`/notification_settings/${userId}`);
  return responseJson.data;
};

export const updateNotificationSetting = async (userId: number, key: string, enabled: boolean) => {
  await api.patch(`/notification_settings/${userId}`, {
    key: key,
    enabled: enabled,
  });
};

export const fetchUserInfoFromNotification = async (
  notificationId: string
): Promise<NotificationUserInfoResponse> => {
  const responseJson = await api.get(`/notifications/${notificationId}/user_info`);
  return responseJson.data;
};

export const fetchRequestForProposalsFromNotification = async (
  notificationId: string
): Promise<NotificationRfpsResponse> => {
  const responseJson = await api.get(request_for_proposals_notification_path(notificationId));
  return responseJson.data;
};
