import { getSSOEmailDomains } from "@requests/sso";
import { UserRole } from "@tsTypes/users";
import { getPrevUrlCookie } from "@utils/authUtils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const useSSOSensitiveEmail = (
  initialValue = ""
): {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  isEmailSSOEnabled: boolean;
  redirectToSSO: () => void;
} => {
  const [email, setEmail] = useState(initialValue);
  const [domains, setDomains] = useState<string[]>([]);
  const [excludedEmails, setExcludedEmails] = useState<string[]>([]);
  const [isEmailSSOEnabled, setIsEmailSSOEnabled] = useState(false);

  useEffect(() => {
    let isAborted = false;
    const abortController = new AbortController();

    (async () => {
      const data = await getSSOEmailDomains(abortController.signal);
      if (!isAborted) {
        setDomains(data.email_domains);
        setExcludedEmails(data.excluded_emails);
      }
    })();

    return () => {
      isAborted = true;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const emailParts = email.split("@");
    setIsEmailSSOEnabled(
      emailParts.length > 1 &&
        domains.includes(emailParts[emailParts.length - 1]) &&
        !excludedEmails.includes(email.toLowerCase())
    );
  }, [email, domains]);

  const redirectToSSO = () => {
    const prevUrl = getPrevUrlCookie("/", true, UserRole.SPONSOR, false);
    window.location.href =
      `/sso/redirect?email=${email}` + (prevUrl ? `&prev_url=${encodeURIComponent(prevUrl)}` : "");
  };

  return {
    email,
    setEmail,
    isEmailSSOEnabled,
    redirectToSSO,
  };
};

export { useSSOSensitiveEmail };
