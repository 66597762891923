import { useState } from "react";

// This is a custom hook that is used to force refresh a component.
// Put refreshKey in the deps array
// TODO: (justinpchang) Remove once redux dependencies are removed and replace with setState
export function useEffectRefresh(): { refreshKey: unknown; refresh: () => void } {
  const [refreshKey, setRefreshKey] = useState({});
  const refresh = () => setRefreshKey({});
  return { refreshKey, refresh };
}
