import { BlankModal, Checkbox, TextButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { setIsAbsolute, setShouldShowProductTours } from "@redux/actions/adminActions";
import { RootState } from "@redux/store";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

export default function MasqueradeBar() {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [requiresRefresh, setRequiresRefresh] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const shouldShowProductTours = useSelector(
    (state: RootState) => state.admin.shouldShowProductTours
  );
  const isAbsolute = useSelector((state: RootState) => state.admin.isAbsolute);

  return (
    <>
      <BlankModal
        isOpen={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
        width="400px"
        headerText="Masquerade options"
        subHeaderText="Changes are applied immediately."
      >
        <ModalContent>
          <Checkbox
            label="Absolute positioning"
            size="sm"
            isChecked={isAbsolute}
            onChange={(e) => {
              dispatch(setIsAbsolute(e.target.checked));
            }}
          />
          <Checkbox
            label="Show product tours"
            size="sm"
            isChecked={shouldShowProductTours}
            onChange={(e) => {
              dispatch(setShouldShowProductTours(e.target.checked));
              setRequiresRefresh(true);
            }}
          />
          {requiresRefresh && (
            <TextButton
              size="sm"
              text="Requires refresh. Click here to refresh."
              iconName="Refresh"
              color={COLORS.HALO_BLUE}
              onClick={() => window.location.reload()}
            />
          )}
        </ModalContent>
      </BlankModal>
      <Container
        position={isAbsolute ? "absolute" : ""}
        background={gon.global?.env === "production" ? COLORS.RED : COLORS.HALO_BLUE}
      >
        Masquerading as {currentUser.first_name} {currentUser.last_name} (ID: {currentUser.id})
        <div>|</div>
        <a href={gon.back_masquerade_path}>
          <TextButton
            size="sm"
            text="Return to your main user"
            color={COLORS.WHITE}
            iconName="Open in a new tab"
          />
        </a>
        <div>|</div>
        <TextButton
          size="sm"
          text="Open options"
          color={COLORS.WHITE}
          iconName={null}
          onClick={() => setIsOptionsOpen(true)}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  width: 100%;
  z-index: 100000;
  text-align: center;

  background: ${({ background }) => background};
  color: white;

  position: ${({ position }) => position};
  top: 0;

  ${FONTS.SEMIBOLD_3};
`;

const ModalContent = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
