import toast from "@components/Toast";
import { Button, TextInput, Checkbox } from "@components/library";
import { FONTS } from "@constants";
import { editRequestComment } from "@requests/requestComments";
import { RequestCommentPartial } from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { Cancel, CheckboxContainer, Submit } from "./RequestDiscussionSubmitForm";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";

interface Props {
  comment: RequestCommentPartial;
  onSuccess: () => Promise<void>;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
}

const RequestDiscussionEditForm = ({ comment, onSuccess, setIsEditing }: Props) => {
  const [text, setText] = useState(comment.text ?? "");
  const [isOpen, setIsOpen] = useState(comment.open ?? true);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const handleSubmitComment = async () => {
    if (text?.length === 0) return;

    try {
      await editRequestComment(comment.id, text, isOpen);
      setText("");
      onSuccess();
      toast.success("Response edited!");
    } catch (e) {
      toast.error("Something went wrong. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RfpDiscussionEditForm#handleSubmitComment");
        span.setTags({
          commentId: String(comment.id),
        });
      });
    }
  };

  const answering = currentUser.role === 1;

  return (
    <EditContainer>
      <TextInput
        value={text}
        labelFont={FONTS.HEADING_5_SEMIBOLD}
        placeholder="Write your answer"
        onChange={(e) => setText(e.target.value)}
        textarea
        startingHeight="150px"
        resize="none"
      />
      <CheckboxContainer>
        {answering && (
          <Checkbox
            label="Allow researchers to respond to your comment"
            isChecked={isOpen}
            onChange={(e) => setIsOpen(e.target.checked)}
            size="sm"
          />
        )}
      </CheckboxContainer>
      <Submit isEditing>
        <Button size="sm" margin="0 0 0 16px" onClick={handleSubmitComment}>
          Submit
        </Button>
        <Cancel onClick={() => setIsEditing(false)}>Cancel</Cancel>
      </Submit>
    </EditContainer>
  );
};

export default RequestDiscussionEditForm;

const EditContainer = styled.div`
  width: 100%;
`;
