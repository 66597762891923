import { COLORS, FONTS, JS_FONTS, WINDOW_DIMENSIONS } from "@constants";
import { StyleSheet } from "aphrodite";
import { ReactNode } from "react";
import ReactCodeInput from "react-verification-code-input";
import { Dropdown as DropdownOld, Icon, Loader as Loader_ } from "semantic-ui-react";
import styled from "styled-components";
import Dropdown from "../../components/libraryOld/SelectionMenus/Dropdown";

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export const StepContainer = styled.div`
  padding: 0;
  width: ${({ width }) => width || "500px"};
  ${FONTS.REGULAR_2};

  ${({ step, center }) =>
    step === 1 || center
      ? `
        display: flex;
        flex-direction: column;
        align-items: center;
      `
      : ""}

  ${TABLET_MEDIA_QUERY} {
    padding: 0 30px;
    width: 100%;
  }
`;

interface StepHeaderProps {
  heading: string | ReactNode;
  subheading: string | ReactNode;
}
export function StepHeader({ heading, subheading }: StepHeaderProps) {
  return (
    <StepHeaderContainer>
      <StepSubheading>{subheading}</StepSubheading>
      <StepHeading>{heading}</StepHeading>
    </StepHeaderContainer>
  );
}
const StepHeaderContainer = styled.div`
  width: 100%;
  margin-bottom: 56px;
`;
const StepSubheading = styled.h2`
  margin: 0;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.SEMIBOLD_1};
`;
const StepHeading = styled.h1`
  margin: 0 0 56px;
  color: ${COLORS.BLACK};
  ${FONTS.HEADING_3_REGULAR};
`;

export const StepTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ center }) => center && `justify-content: center;`}
  color: ${COLORS.BLACK};
  ${({ large }) => (large ? FONTS.HEADING_3_SEMIBOLD : FONTS.HEADING_4_SEMIBOLD)};
  margin-bottom: 30px;
`;

export const HelpAction = styled.div`
  position: relative;
  color: ${COLORS.HALO_BLUE};
  ${FONTS.MEDIUM_2};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }

  margin-bottom: 60px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 324px;
`;

export const HelpIcon = styled(Icon)`
  position: relative;
  color: ${COLORS.HALO_BLUE} !important;
  font-size: 16px !important;
  top: 1px;
  margin-bottom: 1px;
  text-decoration: none !important;
`;

export const DropdownRow = styled.div`
  position: relative;
  margin-bottom: 40px;

  &:last-of-type {
    margin-bottom: 35px;
  }
`;
export const DropdownTitle = styled.div`
  ${FONTS.SEMIBOLD_1};
  width: 100%;
  padding-bottom: 7px;
  color: ${COLORS.BLACK};
`;
export const DropdownSubtitle = styled(DropdownTitle)`
  ${FONTS.REGULAR_3};
  margin-top: -4px;
  color: ${COLORS.NEUTRAL_500};
`;

export const Hairline = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.NEUTRAL_200};
  margin-bottom: 40px;
`;

export const CompanyDropdown = styled(DropdownOld)`
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin: 0;
  ${FONTS.REGULAR_2};
  width: 350px !important;
  min-height: 38px !important;
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  ${TABLET_MEDIA_QUERY} {
    width: 100% !important;
  }

  border-color: ${COLORS.NEUTRAL_200};
`;

export const Input = styled.input`
  width: ${(p) => (p.half && "49%") || "100%"};
  height: 38px;
  margin: 0 auto;
  padding: 11px 28px 11px 10px;
  ${FONTS.REGULAR_2};
  color: ${COLORS.BLACK};
  border: 1px solid ${(props) => (props.error ? COLORS.RED : COLORS.NEUTRAL_400)};
  border-radius: 3px;

  ${({ fixed }) =>
    fixed &&
    `
      color: ${COLORS.NEUTRAL_500};
      border: none;
      background-color: ${COLORS.NEUTRAL_200};
      pointer-events: none;
    `}

  &::placeholder {
    opacity: 1;
    color: ${COLORS.NEUTRAL_500};
  }
`;

// Styles below overwrite library's default styles to match our design system
export const StyledCodeInput = styled(ReactCodeInput)`
  margin-bottom: 33px;
  width: unset !important;
  > div {
    > input {
      background: ${COLORS.NEUTRAL_100};
      border: 0.5px solid ${COLORS.HALO_BLUE} !important;
      border-radius: 4px;
      color: ${COLORS.BLACK};
      font-size: 32px;
      caret-color: ${COLORS.BLACK} !important;
    }
    > input:focus {
      border: 2px solid ${COLORS.HALO_BLUE} !important;
    }
    > input:not(:last-child) {
      margin-right: 8px;
    }
    > input:focus + input {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:focus + input:not(:placeholder-shown) {
      border-left: 0.5px solid ${COLORS.HALO_BLUE};
    }
    > input:last-child:focus,
    input:not(:placeholder-shown) {
      border-color: ${COLORS.HALO_BLUE};
    }
    > input {
      border-color: ${(props) => props.error && COLORS.RED} !important;
    }
  }
`;

export const ContactLink = styled.div`
  ${FONTS.REGULAR_3};
  text-align: left;
  width: 90%;
  margin-top: 10px;
  color: ${COLORS.NEUTRAL_500};
`;

export const ResendEmailLink = styled.span`
  ${FONTS.REGULAR_2};
  text-align: left;
  white-space: no-wrap;
  color: ${COLORS.HALO_BLUE};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const Padding = styled.div`
  height: ${(props) => (props.height ? `${props.height}px` : "10px")};
  display: block;
`;

export const Loader = styled(Loader_)`
  margin-top: 50px !important;
`;

export const styles = StyleSheet.create({
  Dropdown_blueLabel: {
    border: `1px solid ${COLORS.HALO_BLUE}`,
    color: COLORS.HALO_BLUE,
    boxShadow: "none",
    padding: "2px 6px",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    margin: "2px 4px",
    backgroundColor: `${COLORS.WHITE} !important`,
  },
  toast_body: {
    color: COLORS.WHITE,
    marginRight: "10px",
    ...JS_FONTS.MEDIUM_3,
  },
  toast_progress: {
    background: COLORS.HALO_BLUE,
  },
  toast_inner: {
    padding: "15px",
    paddingBottom: "20px",
    borderRadius: "4px",
  },
  toast_container: {
    zIndex: 1000000,
  },
  toast_close: {
    position: "initial",
  },
});

export const CompanyDropdownNew = styled(Dropdown)`
  ${TABLET_MEDIA_QUERY} {
    width: 100% !important;
  }
`;

export const EmailSection = styled.div`
  margin-top: 25px;
  margin-bottom: 30px;
`;

export const OpenEmail = styled.a`
  img {
    margin-right: 6px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  & > button:first-of-type {
    margin-right: 16px;
  }
`;

export const StepDescription = styled.div`
  ${FONTS.REGULAR_1}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}px`}
`;

export const NextStepsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 60px;
  }

  & > img {
    align-self: flex-start;
    margin-right: 10px;
    margin-top: 3px;
  }
`;

export const NextStepHeader = styled.div`
  ${FONTS.HEADING_5_SEMIBOLD}
`;

export const NextStepDescription = styled.div`
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_1}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
`;

export const CheckboxItem = styled.div`
  flex: 1 0 50%;
`;
