import { Dispatch, MouseEvent, SetStateAction, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import { Icon, IconButton, Tooltip } from "@components/library";
import { AttachmentForDisplay } from "@components/library/Attachments/MessageAttachment";
import { COLORS, FONTS, STYLES } from "@constants";
import { RequestAttachmentPartial } from "@tsTypes/request_attachments/_request_attachment";
import { stopPropagation } from "@utils/appUtils";
import { formatBytes, formatMIMEContentType } from "@utils/textUtils";
import MessageAttachmentGalleryPreview from "./MessageAttachmentGalleryPreview";

interface Props {
  attachments: (AttachmentForDisplay | RequestAttachmentPartial)[];
  focusedAttachmentIndex: number | undefined;
  setFocusedAttachmentIndex: Dispatch<SetStateAction<number | undefined>>;
  closeGallery: () => void;
}

const MessageAttachmentGalleryModal = ({
  attachments,
  focusedAttachmentIndex,
  setFocusedAttachmentIndex,
  closeGallery,
}: Props) => {
  const isOpen = attachments.length > 0 && typeof focusedAttachmentIndex !== "undefined";

  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case "Escape":
        closeGallery();
        break;
      case "ArrowLeft":
        setFocusedAttachmentIndex((oldIndex) => Math.max(0, oldIndex! - 1));
        break;
      case "ArrowRight":
        setFocusedAttachmentIndex((oldIndex) => Math.min(attachments.length - 1, oldIndex! + 1));
        break;
    }
  };

  useEffect(() => {
    if (isOpen) {
      // Close on Escape
      document.addEventListener("keydown", onKeyDown, false);
      // Disable background scroll
      document.querySelector("html")!.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
      document.querySelector("html")!.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const focusedAttachment = attachments[focusedAttachmentIndex];

  const handleArrowClick = (event: MouseEvent, increment: -1 | 1) => {
    event.stopPropagation();
    setFocusedAttachmentIndex(focusedAttachmentIndex + increment);
  };

  const handleThumbnailClick = (event: MouseEvent, newFocusedIndex: number) => {
    event.stopPropagation();
    setFocusedAttachmentIndex(newFocusedIndex);
  };

  const { mime_content_type, size_in_bytes, download_url } = focusedAttachment;
  const name = "title" in focusedAttachment ? focusedAttachment.title : focusedAttachment.filename;
  const isImage = mime_content_type?.startsWith("image/");

  const modal = (
    <>
      <Background
        aria-modal
        aria-labelledby="Message Attachment Gallery"
        tabIndex={-1}
        role="dialog"
      />
      <Wrapper>
        <Content onClick={closeGallery} data-testid="message-attachment-gallery">
          <Header.Background onClick={stopPropagation}>
            <Header.Container>
              <Header.Metadata.Container>
                <Header.Metadata.Name>{name}</Header.Metadata.Name>
                <Header.Metadata.Info>
                  {formatMIMEContentType(mime_content_type)} &bull; {formatBytes(size_in_bytes)}
                </Header.Metadata.Info>
              </Header.Metadata.Container>
              <Header.Buttons>
                <a href={download_url} download>
                  <IconButton
                    iconName="Download"
                    variant="ghost-white"
                    size="md"
                    tooltipPosition="bottom"
                  />
                </a>
                <IconButton
                  iconName="Close"
                  variant="ghost-white"
                  size="md"
                  tooltipPosition="bottom"
                  onClick={closeGallery}
                  data-testid="close-button"
                />
              </Header.Buttons>
            </Header.Container>
          </Header.Background>
          <Arrow
            position="left"
            disabled={focusedAttachmentIndex === 0}
            onClick={(event: MouseEvent) => handleArrowClick(event, -1)}
          >
            <Icon name="Chevron Left" size="lg" />
          </Arrow>
          <Arrow
            position="right"
            disabled={focusedAttachmentIndex === attachments.length - 1}
            onClick={(event: MouseEvent) => handleArrowClick(event, 1)}
          >
            <Icon name="Chevron Right" size="lg" />
          </Arrow>
          <MessageAttachmentGalleryPreview
            attachments={attachments}
            focusedAttachmentId={focusedAttachment.id}
          />
          {attachments.length > 1 && (
            <Thumbnail.Island isImage={isImage} onClick={stopPropagation}>
              {attachments.map((attachment, index) => (
                <Tooltip
                  key={`gallery-attachment-${attachment.id}-thumbnail`}
                  content={"title" in attachment ? attachment.title : attachment.filename}
                  position="top"
                  remainOnHover={false}
                  shouldWrap={false}
                >
                  <Thumbnail.Img
                    src={attachment.thumbnail_url}
                    focused={index === focusedAttachmentIndex}
                    onClick={(event: MouseEvent) => handleThumbnailClick(event, index)}
                  />
                </Tooltip>
              ))}
            </Thumbnail.Island>
          )}
        </Content>
      </Wrapper>
    </>
  );

  return isOpen ? ReactDOM.createPortal(modal, document.body) : null;
};

export default MessageAttachmentGalleryModal;

const Background = styled.div`
  z-index: 501;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background: linear-gradient(180deg, ${COLORS.NEUTRAL_900} 0%, ${COLORS.BLACK} 34.91%);
  opacity: 0.9;
`;

const Wrapper = styled.div`
  z-index: 502;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  display: flex;
  overflow: auto;
`;

const Content = styled.div`
  width: 100%;
  padding-top: 42px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const Header = {
  Background: styled.div`
    z-index: 503;

    position: fixed;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 62px;
    padding: 10px 34px;

    // Set "opacity" to 0.9 without affecting child
    background: linear-gradient(180deg, ${COLORS.NEUTRAL_900}E6 0%, transparent 100%);
  `,
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  `,
  Metadata: {
    Container: styled.div`
      max-width: 75%;

      display: flex;
      align-items: center;
      gap: 23px;
      color: ${COLORS.WHITE};
    `,
    Name: styled.div`
      ${FONTS.REGULAR_2}
      ${STYLES.ONE_LINE}
    `,
    Info: styled.div`
      flex: none;
      ${FONTS.REGULAR_3}
    `,
  },
  Buttons: styled.div`
    display: flex;
    gap: 1px;
  `,
};

const Arrow = styled.button`
  position: fixed;
  top: 44%;
  ${({ position }) => `${position}: 0; margin-${position}: 34px;`}
  width: 48px;
  height: 48px;

  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
  border-radius: 10px;
  border: none;
  box-shadow: ${STYLES.SHADOW_D};
  opacity: 0.3;
  transition: opacity 0.1s;

  &:hover {
    opacity: 1;
    box-shadow: 0px 6px 24px -4px ${COLORS.BLACK};
  }

  ${({ disabled }) => disabled && "opacity: 0; pointer-events: none;"}
`;

const Thumbnail = {
  Island: styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    border-radius: 8px;

    ${({ isImage }) =>
      !isImage &&
      `
    background: ${COLORS.BLACK};
    opacity: 0.4;
    transition: opacity 0.1s;

    &:hover {
      opacity: 1;
    }
  `}
  `,
  Img: styled.img`
    width: 40px;
    height: 40px;

    background: ${COLORS.WHITE};
    border-radius: 4px;
    object-fit: cover;
    opacity: 0.5;

    ${({ focused }) =>
      focused &&
      `
        opacity: 1;
        box-shadow: 0 0 0 2px ${COLORS.HALO_BLUE};
      `}

    &:hover {
      opacity: 1;
      cursor: pointer;
    }
  `,
};
