import { Icon, Tooltip } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";
import { TECHNOLOGY_READINESS_LEVELS } from "@tsTypes/proposals";
import Slider from "rc-slider";
import styled from "styled-components";

interface Props {
  trlRange: [number, number];
  asInput?: boolean;
  onChange?: (values: [number, number]) => void;
}

export const TOOLTIP_EXAMPLES = {
  1: "Literature review to validate initial idea.",
  2: "Hypothesis created and validated using basic science principles in the lab.",
  3: "Ideas validated using cell models, computational modeling or in-silico experiments.",
  4: "Drug validated in a mouse model or genetically-modified plants grown in a greenhouse.",
  5: "Hypothesis evaluated and refined in a small-scale experimental test in a relevant environment.",
  6: "Product tested in clinical trials, factories, or field trials with no further requirement to make changes to the product.",
  7: "Product received FDA approval.",
  8: "Product can be manufactured at scale.",
  9: "Product is accessible for consumers.",
};

export const DEFAULT_TRL_RANGE = [1, 9] as [number, number];

const SliderHandle = (handle) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...handle.props} className="rc-slider-handle" data-testid="trl-handle">
    {sliderHandleIcon}
  </div>
);

const TRLs = ({ trlRange, asInput = false, onChange }: Props) => {
  const handleLabelClick = (level) => {
    if (!onChange) return;

    const halfwayPoint = (trlRange[0] + trlRange[1]) / 2;
    if (level < halfwayPoint) {
      onChange([level, trlRange[1]]);
    } else {
      onChange([trlRange[0], level]);
    }
  };

  return (
    <Container>
      <SliderContainer asInput={asInput}>
        <SliderTrackBackground />
        {asInput ? (
          <Slider
            range
            vertical
            reverse
            value={trlRange}
            min={1}
            max={Object.keys(TECHNOLOGY_READINESS_LEVELS).length}
            dots
            allowCross={false}
            handleRender={SliderHandle}
            onChange={
              onChange && ((newRange: number | number[]) => onChange(newRange as [number, number]))
            }
          />
        ) : (
          <>
            <DotContainer>
              {Object.keys(TECHNOLOGY_READINESS_LEVELS).map((level) => (
                <Dot
                  key={level}
                  isActive={Number(level) >= trlRange[0] && Number(level) <= trlRange[1]}
                />
              ))}
            </DotContainer>
            <SelectedBar trlRange={trlRange} />
          </>
        )}
      </SliderContainer>
      <Levels>
        {Object.values(TECHNOLOGY_READINESS_LEVELS).map((value, i) => {
          const isSelected = trlRange[0] <= i + 1 && trlRange[1] >= i + 1;
          return (
            <Level
              key={value}
              isSelected={isSelected}
              data-testid={`trl-${i + 1}`}
              data-selected={isSelected}
            >
              <Label type="button" onClick={() => handleLabelClick(i + 1)} asInput={asInput}>
                {i + 1}. {value}
              </Label>
              <Tooltip
                content={
                  <>
                    <b>Example: </b>
                    <br />
                    {TOOLTIP_EXAMPLES[i + 1]}
                  </>
                }
                position="right"
                tooltipWidth="320px"
                arrowOffsetTop="calc(50% - 8px)"
              >
                <Icon name="Info" size="xs" margin="0 0 0 6px" color={COLORS.NEUTRAL_400} />
              </Tooltip>
            </Level>
          );
        })}
      </Levels>
    </Container>
  );
};

export default TRLs;

const Container = styled.div`
  display: flex;
  gap: 16px;
  min-width: 410px;
`;
const SliderContainer = styled.div`
  display: flex;
  align-items: ${({ asInput }) => (asInput ? "center" : "flex-start")};
  position: relative;
  height: 408px;
  width: 24px;
  .rc-slider {
    height: 384px;
    width: 6px;
    padding: 0;
    left: 9px;
    cursor: pointer;
    .rc-slider-rail {
      width: 6px;
      left: 0;
      background-color: ${COLORS.NEUTRAL_200};
    }
    .rc-slider-track {
      background-color: ${COLORS.HALO_BLUE};
      width: 6px;
      left: 0;
    }
    .rc-slider-step {
      width: 6px;
      .rc-slider-dot {
        width: 6px;
        height: 1px;
        margin: 0;
        border-radius: 0;
        border: none;
        background-color: ${COLORS.NEUTRAL_400};
      }
      .rc-slider-dot-active {
        background-color: ${COLORS.NEUTRAL_200};
      }
    }
    .rc-slider-handle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-left: -9px;
      border: none;
      box-shadow: ${STYLES.SHADOW_A};
      background-color: ${COLORS.WHITE};
      opacity: 1;
    }
  }
`;
const SliderTrackBackground = styled.div`
  position: absolute;
  left: 9px;
  width: 6px;
  height: 100%;
  background-color: ${COLORS.NEUTRAL_200};
  border-radius: 3px;
`;
const DotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  top: 12px;
  left: 9px;
  height: calc(100% - 24px);
  width: 9px;
`;
const Dot = styled.div`
  width: 6px;
  height: 1px;
  background-color: ${({ isActive }) => (isActive ? COLORS.NEUTRAL_200 : COLORS.NEUTRAL_400)};
  z-index: 1;
`;
const SelectedBar = styled.div`
  position: relative;
  top: ${({ trlRange }) => 48 * (trlRange[0] - 1)}px;
  height: ${({ trlRange }) => 48 * (trlRange[1] - trlRange[0]) + 24}px;
  width: 6px;
  background-color: ${COLORS.HALO_BLUE};
  border-radius: 3px;
`;
const Levels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Level = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.MEDIUM_2};
  color: ${({ isSelected }) => (isSelected ? COLORS.BLACK : COLORS.NEUTRAL_400)};
`;
const Label = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  ${({ asInput }) => !asInput && "pointer-events: none;"}
  :focus {
    outline: none;
  }
`;
const sliderHandleIcon = (
  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 1L-4.17233e-07 1" stroke={COLORS.HALO_BLUE} strokeWidth="1.5" />
    <path d="M10 4L-4.17233e-07 4" stroke={COLORS.HALO_BLUE} strokeWidth="1.5" />
    <path d="M10 7L-4.17233e-07 7" stroke={COLORS.HALO_BLUE} strokeWidth="1.5" />
  </svg>
);
