/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import { Fragment, ReactNode, useState } from "react";
import styled from "styled-components";

import { MessageAttachment, Tabs, Tag } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { t } from "@utils/i18n";

import ScientistContribution from "@components/ScientistContribution";
import { FundingPartial } from "@tsTypes/fundings/_funding";
import { ProposalsShowResponseWithProposal } from "@tsTypes/index";
import { PatentPartial } from "@tsTypes/patents/_patent";
import { TECHNOLOGY_READINESS_LEVELS } from "@tsTypes/proposals";
import { ProposalyType } from "@tsTypes/proposaly";
import { PublicationPartial } from "@tsTypes/publications/_publication";
import { RequestForExpertProposalShowResponse } from "@tsTypes/request_for_expert_proposals/show";
import { RequestForStartupProposalShowResponse } from "@tsTypes/request_for_startup_proposals/show";
import { emptyFunction } from "@utils/appUtils";
import { checkIsChemicalCompoundProposal } from "@utils/proposalUtils";

export type ProposalDetailProposal =
  | ProposalsShowResponseWithProposal["proposal"]
  | RequestForStartupProposalShowResponse["proposal"]
  | RequestForExpertProposalShowResponse["proposal"]
  | Record<string, never>;

interface Props {
  proposal: ProposalDetailProposal;
  publications: PublicationPartial[];
  fundings: FundingPartial[];
  patents: PatentPartial[];
}

const useProposalDetailTabs = ({ proposal, publications, fundings, patents }: Props) => {
  const visibleTabs = [
    {
      label: "Description",
    },
    {
      label: "Publications",
      data: publications,
    },
    {
      label: "Funding",
      data: fundings,
    },
    {
      label: "Patents",
      data: patents,
    },
  ].filter((tab) => !tab.data || tab.data.length > 0);

  const [activeTab, setActiveTab] = useState(visibleTabs[0].label);

  const tabs = visibleTabs.map((tab) => ({
    key: tab.label,
    label: tab.label,
    count: tab.data?.length,
  }));

  const hasContributions = Boolean((publications?.length ?? 0) + fundings.length + patents.length);
  const isChemicalCompoundProposal = checkIsChemicalCompoundProposal(proposal);

  let description: ReactNode = null;
  switch (proposal.proposaly_type) {
    case ProposalyType.REQUEST_FOR_EXPERT_PROPOSAL:
      description = (
        <div>
          {proposal.request_solution_names?.length > 0 && (
            <>
              <Header>
                {t(["pages.request_for_expert_proposal_form.fields.solutions.header"])}
              </Header>
              <List>
                {proposal.request_solution_names.map((solution: string) => (
                  <li key={`rfep-soi-${solution}`}>{solution}</li>
                ))}
              </List>
            </>
          )}
          {proposal.proposal_request_requirements?.length > 0 && (
            <>
              <Header>
                {t(["pages.request_for_expert_proposal_form.fields.requirements.header"])}
              </Header>
              {proposal.request.request_requirements?.map((request_requirement) => {
                const proposal_request_requirement = proposal?.proposal_request_requirements?.find(
                  (prr) => prr.request_requirement_id === request_requirement.id
                );
                if (!proposal_request_requirement) return null;
                return (
                  <div key={request_requirement.id}>
                    <Subheader>{request_requirement.description}</Subheader>
                    <div>{proposal_request_requirement.description}</div>
                    {proposal_request_requirement.proposal_request_requirement_attachments?.length >
                      0 && (
                      <AttachmentThumbnailContainer>
                        {proposal_request_requirement.proposal_request_requirement_attachments.map(
                          (attachment) => (
                            <UnstyledLink
                              key={`requirement-${request_requirement.id}-attachment-${attachment.id}`}
                              href={attachment.url}
                              download
                            >
                              <MessageAttachment
                                isInbox
                                attachment={attachment}
                                onClick={emptyFunction}
                              />
                            </UnstyledLink>
                          )
                        )}
                      </AttachmentThumbnailContainer>
                    )}
                  </div>
                );
              })}
            </>
          )}
          {proposal.proposal_request_questions?.length > 0 && (
            <>
              <hr />
              {proposal.request.request_questions.map((question) => (
                <div key={`rfep-question-${question.id}`}>
                  <Subheader>{question.question}</Subheader>
                  <div>
                    {proposal.proposal_request_questions.find(
                      (q) => q.request_question_id === question.id
                    )?.answer ?? "N/A"}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      );
      break;
    case ProposalyType.REQUEST_FOR_STARTUP_PROPOSAL:
      description = (
        <div>
          {[
            "name",
            "url",
            "location",
            "solutions_of_interest",
            "funding_stage",
            "description",
            "problem",
            "solution",
            "business_model",
            "team_members",
            "team_qualification",
            "trl",
            "validation",
            "milestones",
            "how_can_program_help",
          ].map(
            (field) =>
              proposal[field] && (
                <Fragment key={`rfsp-${field}`}>
                  <Header key={`rfsp-${field}-header`}>
                    {t(["pages.rfs_proposal_form.fields", field, "header"])}
                  </Header>
                  {(() => {
                    switch (field) {
                      case "solutions_of_interest":
                        return (
                          <List>
                            {proposal[field].map((solution: string, index: number) => (
                              <li key={`rfsp-${field}-${index}`}>{solution}</li>
                            ))}
                          </List>
                        );
                      case "team_members":
                        return proposal[field]?.length ? (
                          <List>
                            {proposal[field]?.map((member: any, index: number) => (
                              <li key={`rfsp-${field}-${index}`}>
                                {member.name} ({member.linkedin_url})
                              </li>
                            ))}
                          </List>
                        ) : (
                          "N/A"
                        );
                      case "trl":
                        return proposal.trl ? (
                          <Tag
                            margin="16px 0 0 0"
                            size="sm"
                            content={
                              <div>
                                {proposal.trl}. {TECHNOLOGY_READINESS_LEVELS[proposal.trl]}
                              </div>
                            }
                          />
                        ) : null;
                      default:
                        return (
                          <div key={`rfsp-${field}-content`}>
                            {proposal[field].length > 0 ? proposal?.[field] : "N/A"}
                          </div>
                        );
                    }
                  })()}
                </Fragment>
              )
          )}
        </div>
      );
      break;
    case ProposalyType.PROPOSAL:
      description = (
        <div data-testid="tab-contents">
          {isChemicalCompoundProposal && (
            <>
              <Header>Compound Description</Header>
              <div>{proposal.title}</div>
            </>
          )}
          {proposal.request_solution_names?.length > 0 && (
            <>
              <Header>Relevant Solutions of Interest</Header>
              <List>
                {proposal.request_solution_names.map((solution: string) => (
                  <li key={`rfpp-soi-${solution}`}>{solution}</li>
                ))}
              </List>
            </>
          )}
          {!isChemicalCompoundProposal && proposal.technology_readiness_level && (
            <>
              <Header>{t("models.proposal.technology_readiness_level")}</Header>
              <div>{t("pages.proposals.trl_description")}</div>
              <Tag
                margin="16px 0 0 0"
                size="sm"
                content={
                  <div>
                    {proposal.technology_readiness_level}.{" "}
                    {TECHNOLOGY_READINESS_LEVELS[proposal.technology_readiness_level]}
                  </div>
                }
              />
            </>
          )}
          <Header>{isChemicalCompoundProposal ? "Design Principle" : "Hypothesis"}</Header>
          <div>{proposal.research_hypothesis}</div>
          {/* NOTE: Rationale/hypothesis_basis is required for RFPs, but not currently for RFMs */}
          {Number(proposal.hypothesis_basis?.length) > 0 && (
            <>
              <Header>Rationale</Header>
              <div>{proposal.hypothesis_basis}</div>
            </>
          )}
          {!isChemicalCompoundProposal && Number(proposal.validation_procedure?.length) > 0 && (
            <>
              <Header>Preliminary Data</Header>
              <div>{proposal.validation_procedure}</div>
            </>
          )}
          {!isChemicalCompoundProposal && (
            <>
              <Header>Research Plan</Header>
              <div>{proposal.future_validation}</div>
            </>
          )}

          {proposal.proposal_request_requirements?.length > 0 && (
            <>
              <Header>Elaboration on the must-have requirements</Header>
              {proposal.request.request_requirements?.map((request_requirement, i) => {
                const proposal_request_requirement = proposal?.proposal_request_requirements?.find(
                  (prr) => prr.request_requirement_id === request_requirement.id
                );
                if (!proposal_request_requirement) return null;
                return (
                  <div key={request_requirement.id}>
                    <Subheader>
                      {i + 1}. {request_requirement.description}
                    </Subheader>
                    <div>{proposal_request_requirement.description}</div>
                    {proposal_request_requirement.proposal_request_requirement_attachments?.length >
                      0 && (
                      <AttachmentThumbnailContainer>
                        {proposal_request_requirement.proposal_request_requirement_attachments.map(
                          (attachment) => (
                            <UnstyledLink
                              key={`requirement-${request_requirement.id}-attachment-${attachment.id}`}
                              href={attachment.url}
                              download
                            >
                              <MessageAttachment
                                isInbox
                                attachment={attachment}
                                onClick={emptyFunction}
                              />
                            </UnstyledLink>
                          )
                        )}
                      </AttachmentThumbnailContainer>
                    )}
                  </div>
                );
              })}
            </>
          )}

          {proposal.proposal_request_questions?.length > 0 && (
            <>
              <Header>Additional Questions</Header>
              {proposal.request.request_questions.map((question) => {
                const proposal_request_question = proposal?.proposal_request_questions?.find(
                  (prq) => prq.request_question_id === question.id
                );
                if (!proposal_request_question) return null;
                return (
                  <div key={`proposal-question-${question.id}`}>
                    <Subheader>{question.question}</Subheader>
                    <div>{proposal_request_question?.answer ?? "N/A"}</div>
                    {proposal_request_question?.proposal_request_question_attachments.length >
                      0 && (
                      <AttachmentThumbnailContainer>
                        {proposal_request_question.proposal_request_question_attachments.map(
                          (attachment) => (
                            <UnstyledLink
                              key={`requirement-${question.id}-attachment-${attachment.id}`}
                              href={attachment.file_url}
                              download
                            >
                              <MessageAttachment
                                isInbox
                                attachment={attachment}
                                onClick={emptyFunction}
                              />
                            </UnstyledLink>
                          )
                        )}
                      </AttachmentThumbnailContainer>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      );
      break;
  }

  const content = (
    <>
      {activeTab === "Description" && description}
      <TabPanel>
        {(() => {
          if (activeTab === "Publications") {
            return publications.map((publication) => (
              <ScientistContribution key={publication.id} contribution={publication} />
            ));
          }
          if (activeTab === "Funding") {
            return fundings.map((funding) => (
              <ScientistContribution key={funding.id} contribution={funding} />
            ));
          }
          if (activeTab === "Patents") {
            return patents.map((patent) => (
              <ScientistContribution key={patent.id} contribution={patent} />
            ));
          }
        })()}
      </TabPanel>
    </>
  );

  return {
    ProposalDetailTabs: hasContributions ? (
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} margin="0 0 24px" />
    ) : null,
    ProposalDetailTabsContent: content,
  };
};

export default useProposalDetailTabs;

const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${FONTS.REGULAR_2};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.NEUTRAL_300};
  }
  /* Aphrodite uses !important, so this is needed to override it until a new materials row component can be created */
  & > div {
    margin-top: 0 !important;
    & > div {
      padding-top: 0 !important;
    }
  }
`;

const Header = styled.div`
  ${FONTS.SEMIBOLD_1};
  &:not(:first-of-type) {
    margin-top: 24px;
  }
`;

const Subheader = styled.div`
  ${FONTS.SEMIBOLD_2}
  margin: 16px 0 8px;
`;

const List = styled.ul`
  padding-left: 22px;
`;

const AttachmentThumbnailContainer = styled.div`
  display: flex;
  gap: 8px 8px;
  margin: 16px 0 32px;
`;

const UnstyledLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: inherit;
  }
`;
