import { COLORS, FONTS, JS_FONTS, WINDOW_DIMENSIONS } from "@constants";
import { StyleSheet } from "aphrodite";
import styled from "styled-components";

const { MOBILE_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export const styles = StyleSheet.create({
  InviteModal_modal: {
    maxWidth: "654px",
    width: "100% !important",
    padding: "40px 38px !important",
    overflow: "visible",
    [MOBILE_MEDIA_QUERY]: {
      width: "350px",
      padding: "4.8em 2em",
    },
  },
  InviteModal_content: {
    padding: "0px",
    maxHeight: "none",
  },
  InviteModal_modalTextArea: {
    width: "100%",
    maxWidth: "574px",
    height: "190px",
    margin: "auto",
    padding: "10px",
    ...JS_FONTS.REGULAR_2,
    color: COLORS.BLACK,
    resize: "none",
    borderRadius: "3px",
    border: `1px solid ${COLORS.NEUTRAL_400}`,
    ":focus": {
      outline: "none",
      borderColor: COLORS.HALO_BLUE,
      boxShadow: "none !important",
    },
    "::placeholder": {
      color: COLORS.NEUTRAL_500,
      ...JS_FONTS.REGULAR_2,
    },
  },
  InviteModal_modalInput: {
    margin: ".3rem 0 .3rem .3rem",
    ...JS_FONTS.REGULAR_2,
    border: "none",
    outline: "none",
    background: "transparent",
    ":focus": {
      outline: "none",
      border: "none",
    },
    "::placeholder": {
      color: COLORS.NEUTRAL_500,
    },
  },
  InviteModal_modalInputZero: {
    marginLeft: "10px",
  },
  InviteModal_modalButtonHolder: {
    width: "100%",
    maxWidth: "357px",
    display: "flex",
    textAlign: "right",
    marginTop: "16px",
  },
  InviteModal_header: {
    display: "flex",
    justifyContent: "space-between",
    ...JS_FONTS.HEADING_4_SEMIBOLD,
    color: COLORS.BLACK,
    textAlign: "left",
    margin: "0 auto 15px",
  },
  InviteModal_closeModalButton: {
    width: "12px",
    height: "12px",
    cursor: "pointer",
    marginLeft: "6px",
  },
  InviteModal_subheader: {
    color: COLORS.BLACK,
    ...JS_FONTS.REGULAR_2,
    margin: "0px auto 21px",
    textAlign: "left",
  },
  ConfirmationInviteModal_subheader: {
    margin: "0px auto 10px",
  },
  ConfirmationInviteModal_footer: {
    margin: "30px auto",
  },
  InviteModal_deleteIcon: {
    width: "12px",
    height: "12px",
    border: "none",
    ...JS_FONTS.MEDIUM_1,
    margin: "6px",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  InviteModal_errorMessage: {
    marginTop: "5px",
    color: COLORS.RED,
    ...JS_FONTS.REGULAR_2,
    textAlign: "left",
    marginBottom: "5px",
  },
  InviteModal_errorField: {
    border: `solid 1px ${COLORS.RED}`,
  },
  InviteModal_emailChip: {
    backgroundColor: COLORS.NEUTRAL_200,
    ...JS_FONTS.REGULAR_2,
    borderRadius: "3px",
    height: "24px",
    color: COLORS.BLACK,
    padding: "0 4px 0 1rem",
    display: "inline-flex",
    alignItems: "center",
    margin: "0.3rem",
    position: "relative",
  },
  InviteModal_invalidEmailChip: {
    backgroundColor: "#FFEDED",
  },
  ConfirmationInviteModal_emailChip: {
    padding: "4px 6px",
  },
  InviteModal_modalInputDiv: {
    width: "574px",
    display: "flex",
    flexWrap: "wrap",
    minHeight: "38px",
    margin: "0px auto 0px",
    ...JS_FONTS.REGULAR_2,
    border: `1px solid ${COLORS.NEUTRAL_400}`,
    borderRadius: "3px",
  },
  InviteModal_smallerInputDiv: {
    width: "429px",
  },
  InviteModal_modalInputDivFocus: {
    outline: "none",
    borderColor: COLORS.HALO_BLUE,
  },
  InviteModal_inputLabel: {
    ...JS_FONTS.REGULAR_2,
    color: COLORS.BLACK,
    marginBottom: "5px",
  },
  InviteModal_inputLabelDropdown: {
    marginLeft: "15px",
  },
  InviteModal_emailDropdown: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    marginBottom: "12px",
    paddingLeft: "10px",
    minHeight: "36px",
    width: "100%",
    color: COLORS.HALO_BLUE,
    backgroundColor: COLORS.WHITE,
    ...JS_FONTS.REGULAR_2,
    border: `0.5px solid ${COLORS.NEUTRAL_200}`,
    boxSizing: "border-box",
    borderRadius: "3px",
    ":hover": {
      opacity: 1,
      cursor: "pointer",
    },
  },
  InviteModal_modalInputDivFilled: {
    padding: "0.6rem 0px 0.6rem 0.6rem ",
  },
});

export const InviteModalDropdown = styled.div`
  margin-left: 14px;
  width: 130px !important;
  height: 38px !important;
  ${FONTS.REGULAR_2};
  padding: 10px 11px !important;
  border-radius: 3px !important;
  border: ${(props) =>
    props.dropdownActive
      ? `1px solid ${COLORS.HALO_BLUE}`
      : `1px solid ${COLORS.NEUTRAL_400}`} !important;
  &&&&&& i {
    ${FONTS.REGULAR_3};
    color: ${(props) => (props.dropdownActive ? COLORS.HALO_BLUE : COLORS.NEUTRAL_400)};
    margin-right: 0px;
    position: relative;
    right: 0px;
    padding-left: ${(props) => (props.admin ? "6px" : "46px")};
  }
`;
export const DropdownMenu = styled.div`
  &&&& {
    max-height: unset;
    width: 350px;
    box-sizing: border-box;
    border: 0.5px solid ${COLORS.NEUTRAL_200};
    border-radius: 0px;
    overflow: auto;
    margin-top: 1px;
  }
`;
export const DropdownItem = styled.div`
  border: 2px solid ${COLORS.BLUE_LIGHT_300};
  box-sizing: border-box;
  box-shadow: 0px 14px 24px rgba(151, 158, 170, 0.25);
  border-radius: 3px;
  &&&& {
    ${FONTS.MEDIUM_2};
    color: ${(props) => (props.red ? COLORS.RED : COLORS.BLACK)} !important;
    border-bottom: 1px solid ${COLORS.NEUTRAL_200};
    height: 83px;
  }
`;
export const DropdownSubHeader = styled.div`
  ${FONTS.REGULAR_3};
  color: ${COLORS.NEUTRAL_500};
  margin-top: 5px;
`;
export default styles;
