import { Button, Icon, TextInput } from "@components/library";
import { COLORS, FONTS, STYLES } from "@constants";
import { useState } from "react";
import styled from "styled-components";

interface Props {
  onSubmit: (code: string) => Promise<void>;
  error: string;
  requestData?: {
    company_name: string;
    request_title: string;
  };
}

const AccessCode = ({ onSubmit, error, requestData }: Props) => {
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await onSubmit(code);
        setIsLoading(false);
      }}
    >
      {requestData && (
        <Heading>
          {requestData.company_name} invited you to apply to {requestData.request_title}.
        </Heading>
      )}
      <TextInput
        value={code}
        onChange={(e) => setCode(e.target.value)}
        label={
          <>
            <Icon name="Lock" size="sm" color={COLORS.BLACK} margin="1px 4px 0 0" />
            This opportunity is password-protected.
          </>
        }
        labelFont={FONTS.SEMIBOLD_2}
        placeholder="Enter password"
        errors={[{ hasError: Boolean(error), errorMessage: error }]}
      />
      <Button type="submit" disabled={isLoading}>
        Submit
      </Button>
    </Container>
  );
};

export default AccessCode;

const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 500px;
  margin: 40px auto;
  padding: 40px;
  border: 1px solid ${COLORS.NEUTRAL_100};
  border-radius: 12px;
  box-shadow: ${STYLES.SHADOW_CARD};
`;

const Heading = styled.h1`
  margin: 0;
  ${FONTS.REGULAR_1}
`;
