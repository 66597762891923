import { Button, Checkbox, ChipsInput, IconButton, SlideModalBase } from "@components/library";
import { SearchBarInput } from "@components/library/Inputs/SearchBarInput";
import { COLORS, FEATURE_FLAGS } from "@constants";
import { useSearchParams } from "@hooks/router";
import { useIsMount } from "@hooks/useIsMount";
import { RootState } from "@redux/store";
import { RequestPartnerTypes, RequestableType } from "@tsTypes/requests";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { SORT_BY_OPTIONS } from "./RespondToRequests";
import {
  CheckboxContainer,
  FilterContainer,
  FilterSection,
  ResetFiltersButton,
} from "./sharedFilterStyles";

interface Props {
  searchFilter: string[];
  setSearchFilter: Dispatch<SetStateAction<string[]>>;
  requestTypesFilter: RequestableType[];
  setRequestTypesFilter: Dispatch<SetStateAction<RequestableType[]>>;
  partnerTypesFilter: RequestPartnerTypes[];
  setPartnerTypesFilter: Dispatch<SetStateAction<RequestPartnerTypes[]>>;
  sortBy: typeof SORT_BY_OPTIONS[number];
  setSortBy: Dispatch<SetStateAction<typeof SORT_BY_OPTIONS[number]>>;
  totalCount: number;
}

const SEARCH_FILTER_PARAM = "search";
const REQUEST_TYPES_FILTER_PARAM = "request_types";
const PARTNER_TYPES_FILTER_PARAM = "partner_types";

export default function RespondToRequestsFilters({
  searchFilter,
  setSearchFilter,
  requestTypesFilter,
  setRequestTypesFilter,
  partnerTypesFilter,
  setPartnerTypesFilter,
  sortBy,
  setSortBy,
  totalCount,
}: Props) {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const isTablet = useSelector((state: RootState) => state.window.isTablet);

  const [searchParams] = useSearchParams();
  const history = useHistory();
  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      if (searchParams.has(SEARCH_FILTER_PARAM)) {
        setSearchFilter(searchParams.get(SEARCH_FILTER_PARAM)!.split(";"));
      }
      if (searchParams.has(REQUEST_TYPES_FILTER_PARAM)) {
        const requestTypes = searchParams.get(REQUEST_TYPES_FILTER_PARAM)!.split(";");
        setRequestTypesFilter(requestTypes as RequestableType[]);
      }
      if (searchParams.has(PARTNER_TYPES_FILTER_PARAM)) {
        const partnerTypes = searchParams.get(PARTNER_TYPES_FILTER_PARAM)!.split(";");
        setPartnerTypesFilter(partnerTypes as RequestPartnerTypes[]);
      }
      return;
    }

    if (searchFilter.length) searchParams.set(SEARCH_FILTER_PARAM, searchFilter.join(";"));
    else searchParams.delete(SEARCH_FILTER_PARAM);
    if (requestTypesFilter.length)
      searchParams.set(REQUEST_TYPES_FILTER_PARAM, requestTypesFilter.join(";"));
    else searchParams.delete(REQUEST_TYPES_FILTER_PARAM);
    if (partnerTypesFilter.length)
      searchParams.set(PARTNER_TYPES_FILTER_PARAM, partnerTypesFilter.join(";"));
    else searchParams.delete(PARTNER_TYPES_FILTER_PARAM);

    history.replace({ search: searchParams.toString() });
  }, [searchFilter, setSearchFilter, requestTypesFilter, setRequestTypesFilter]);

  const handleClearFilters = () => {
    setSearchInput("");
    setSearchFilter([]);
    setRequestTypesFilter([]);
    setPartnerTypesFilter([]);
  };

  const searchFilterAsChips = searchFilter.map((value) => ({ value, label: value }));

  const searchBarEl = (
    <SearchBarInput
      value={searchInput}
      onChange={setSearchInput}
      onSubmit={(value) => {
        if (!value) return;
        setSearchFilter([...searchFilter, value]);
        setSearchInput("");
      }}
      placeholder="Expertise, request, etc."
      buttonText={null}
    />
  );

  const searchFilterEl =
    searchFilter.length > 0 ? (
      <div style={{ marginTop: 16 }}>
        <ChipsInput
          options={searchFilterAsChips}
          selectedValues={searchFilter}
          onChange={(values) => setSearchFilter(values ?? [])}
          gap="8px 8px"
          hideAll
        />
      </div>
    ) : null;

  const handleRequestTypeChange =
    (requestType: RequestableType) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setRequestTypesFilter((prev) => [...prev, requestType]);
      } else {
        setRequestTypesFilter((prev) => prev.filter((type) => type !== requestType));
      }
    };

  const handlePartnerTypeChange =
    (partnerType: RequestPartnerTypes) => (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setPartnerTypesFilter((prev) => [...prev, partnerType]);
      } else {
        setPartnerTypesFilter((prev) => prev.filter((type) => type !== partnerType));
      }
    };

  const commonFiltersEl = (
    <>
      {/*
      <FilterSection label="Companies seeking">
        <CheckboxContainer data-testid="request-type-checkboxes">
          <Checkbox
            isReversed={isTablet}
            label={
              <CheckboxLabel withMargin={isTablet}>
                <Icon name="RFP" size="sm" color={COLORS.BLACK} margin="0 -4px 0 0" />
                Solutions
              </CheckboxLabel>
            }
            isChecked={requestTypesFilter.includes(RequestableType.RFP)}
            onChange={handleRequestTypeChange(RequestableType.RFP)}
            size="sm"
          />
          <Checkbox
            isReversed={isTablet}
            label={
              <CheckboxLabel withMargin={isTablet}>
                <Icon
                  name="Request for experts"
                  size="xs"
                  color={COLORS.BLACK}
                  margin="0 -1px 0 2px"
                />
                Experts
                <InfoTag accent marginLeft="2px">
                  NEW
                </InfoTag>
              </CheckboxLabel>
            }
            isChecked={requestTypesFilter.includes(RequestableType.REQUEST_FOR_EXPERT)}
            onChange={handleRequestTypeChange(RequestableType.REQUEST_FOR_EXPERT)}
            size="sm"
          />
        </CheckboxContainer>
      </FilterSection>
          */}
      {FEATURE_FLAGS.MARKETPLACE_PARTNER_TYPE_FILTER && (
        <FilterSection label="Preferred partner">
          <CheckboxContainer data-testid="partner-type-checkboxes">
            <Checkbox
              isReversed={isTablet}
              label={<CheckboxLabel withMargin={isTablet}>Startups</CheckboxLabel>}
              isChecked={partnerTypesFilter.includes(RequestPartnerTypes.STARTUPS)}
              onChange={handlePartnerTypeChange(RequestPartnerTypes.STARTUPS)}
              size="sm"
            />
            <Checkbox
              isReversed={isTablet}
              label={<CheckboxLabel withMargin={isTablet}>Academic researchers</CheckboxLabel>}
              isChecked={partnerTypesFilter.includes(RequestPartnerTypes.ACADEMIC_RESEARCHERS)}
              onChange={handlePartnerTypeChange(RequestPartnerTypes.ACADEMIC_RESEARCHERS)}
              size="sm"
            />
            <Checkbox
              isReversed={isTablet}
              label={<CheckboxLabel withMargin={isTablet}>Suppliers</CheckboxLabel>}
              isChecked={partnerTypesFilter.includes(RequestPartnerTypes.SUPPLIERS)}
              onChange={handlePartnerTypeChange(RequestPartnerTypes.SUPPLIERS)}
              size="sm"
            />
          </CheckboxContainer>
        </FilterSection>
      )}
    </>
  );

  if (isTablet)
    return (
      <>
        <SlideModalBase
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
          closeOnDimmerClick
          direction="bottom"
          customCloseButton={
            <IconButton
              iconName="Close"
              size="sm"
              variant="fourth"
              onClick={() => setIsPanelOpen(false)}
              tooltipPosition="bottom"
              data-testid="close-button"
              margin="0 -6px 0 0"
            />
          }
        >
          <Panel>
            <FilterContainer>
              {commonFiltersEl}
              <FilterSection label="Sort by">
                <CheckboxContainer data-testid="sort-radio">
                  {SORT_BY_OPTIONS.map((option) => (
                    <Checkbox
                      key={`sort-${option.value}`}
                      isReversed={isTablet}
                      label={<CheckboxLabel withMargin={isTablet}>{option.label}</CheckboxLabel>}
                      isChecked={sortBy.value === option.value}
                      onChange={(e) => e.target.checked && setSortBy(option)}
                      size="sm"
                      type="radio"
                    />
                  ))}
                </CheckboxContainer>
              </FilterSection>
              <ResetFiltersButton onClick={handleClearFilters} />
            </FilterContainer>
            <PanelFooter>
              <Button variant="ghost" onClick={() => setIsPanelOpen(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => setIsPanelOpen(false)}>
                View {totalCount} opportunities
              </Button>
            </PanelFooter>
          </Panel>
        </SlideModalBase>
        <div style={{ marginBottom: 22 }}>
          <SearchAndFilter>
            <div style={{ width: "100%" }}>{searchBarEl}</div>
            <IconButton
              iconName="Filter & sort"
              variant="fourth"
              tooltipWidth="90px"
              onClick={() => setIsPanelOpen(true)}
            />
          </SearchAndFilter>
          {searchFilterEl}
        </div>
      </>
    );

  return (
    <FilterContainer>
      <FilterSection label="Search" labelMargin="0 0 23px">
        {searchBarEl}
        {searchFilterEl}
      </FilterSection>
      {commonFiltersEl}
      <ResetFiltersButton onClick={handleClearFilters} />
    </FilterContainer>
  );
}

const SearchAndFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
`;

const Panel = styled.div`
  height: calc(100vh - 80px);
  width: 100%;

  ${FilterContainer} {
    padding: 45px 24px;
    overflow-y: auto;
    max-height: calc(100vh - 84px);
  }
`;

const PanelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border-top: 1px solid ${COLORS.NEUTRAL_250};
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  ${({ withMargin }) =>
    withMargin &&
    `
    margin: 4px;
  `}
`;
