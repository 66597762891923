import PrivateSponsorRoute from "@components/PrivateSponsorRoute";
import { FEATURE_FLAGS } from "@constants";
import { lazyWithRetry } from "@utils/appUtils";
import { RequestType } from "src/App";
import { edit_opportunity_path, new_opportunity_path } from "./routes";

const RfpRequestForm = lazyWithRetry(() => import("../views/RfpRequestForm"));

const renderForm = (props) => {
  if (!FEATURE_FLAGS.RFP_FORM_V2) {
    throw new Error("RFP_FORM_V2 is not enabled.");
  }
  const searchParams = new URLSearchParams(props.location.search);
  const request_type = searchParams.get("type");
  switch (request_type) {
    case RequestType.REQUEST_FOR_PROPOSAL:
      return <RfpRequestForm />;
    default:
      console.error("Unexpected request_type: %s.", request_type);
  }
};

const routes = [
  <PrivateSponsorRoute key="opportunity-new" exact path={new_opportunity_path()}>
    {renderForm}
  </PrivateSponsorRoute>,
  <PrivateSponsorRoute key="opportunity-edit" exact path={edit_opportunity_path(":slug")}>
    {renderForm}
  </PrivateSponsorRoute>,
];

export default routes;
