import toast from "@components/Toast/Toast";
import { Icon, InfoBanner, TextButton } from "@components/library";
import { COLORS } from "@constants";
import styled from "styled-components";

interface Props {
  accessCode: string;
}

const AccessCodeBanner = ({ accessCode }: Props) => {
  const handleCopyCode = async () => {
    await navigator.clipboard.writeText(accessCode);
    toast.success("Password copied to clipboard");
  };

  return (
    <div>
      <InfoBanner
        shouldDisplay
        hideIcon
        width="calc(100% - 96px)"
        type="attention-orange"
        padding="18px 24px"
        margin="28px auto"
        text={
          <Container>
            <Icon name="Lock" color={COLORS.ORANGE_900} margin="0 8px 0 0" />
            External partners need a password to access this opportunity.
            <TextButton
              text="Copy password"
              iconName="Copy"
              iconPosition="left"
              onClick={handleCopyCode}
              color={COLORS.ORANGE_900}
              margin="0 24px 0 auto"
            />
          </Container>
        }
      />
    </div>
  );
};

export default AccessCodeBanner;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
