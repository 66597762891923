import { useEffect, useMemo, useState } from "react";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

import { Button, Tabs, Tag, UserPreview } from "@components/library";
import SlideModalBase, { SlideModalBaseProps } from "@components/library/ModalBases/SlideModalBase";
import { COLORS, FONTS } from "@constants";
import SponsorShareScientistModal from "../SponsorShareScientistModal";
import {
  PositionInfo,
  useFundings,
  usePatents,
  useProposals,
  usePublications,
  useScientist,
} from "./hooks";

import ScientistContribution from "@components/ScientistContribution";
import { RootState } from "@redux/store";
import { t } from "@utils/i18n";
import { useSelector } from "react-redux";
import { NetworkScientist } from "src/views/ScientistNetwork/ScientistNetwork";

interface Props extends SlideModalBaseProps {
  scientistUserId: number | undefined;
  sendMessage?: (scientist: NetworkScientist) => void;
  addToNetwork?: (scientist: NetworkScientist) => void;
  forUniversityProposalLibrary?: boolean;
}

const ScientistQuickViewModal = ({
  isOpen,
  onClose,
  scientistUserId,
  sendMessage,
  addToNetwork,
  forUniversityProposalLibrary = false,
}: Props) => {
  if (!isOpen || !scientistUserId) return null;

  const [activeTab, setActiveTab] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { scientist, about, positions, isScientistLoading } = useScientist(scientistUserId);
  const publications = usePublications(scientist?.profile_info.id).publications;
  const fundings = useFundings(scientist?.profile_info.id).fundings;
  const patents = usePatents(scientist?.profile_info.id).patents;
  const proposals = useProposals(scientist?.profile_info.id, forUniversityProposalLibrary);

  const windowHeight = useSelector((state: RootState) => state.window.height);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const visibleTabs = useMemo(
    () =>
      [
        {
          label: "Publications",
          data: publications,
        },
        {
          label: "Funding",
          data: fundings,
        },
        {
          label: "Patents",
          data: patents,
        },
        {
          label: "Proposals",
          data: proposals,
        },
      ].filter((tab) => tab.data.length > 0),
    [publications, fundings, patents, proposals]
  );

  useEffect(() => {
    setActiveTab(visibleTabs[0]?.label);
  }, [visibleTabs]);

  const tabs = visibleTabs.map((tab) => ({
    key: tab.label,
    label: tab.label,
    count: tab.data.length,
  }));

  if (!scientist) return null;

  return (
    <>
      <SponsorShareScientistModal
        scientist={scientist}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
      <SlideModalBase isOpen={isOpen} onClose={onClose}>
        <Container data-testid="scientist-quick-view-modal">
          {isScientistLoading ? (
            <Loader active style={{ top: "30%" }} />
          ) : (
            <>
              <TopContent>
                {scientist && (
                  <UserPreviewContainer>
                    <UserPreview size="xl" user={scientist} />
                  </UserPreviewContainer>
                )}
                {!forUniversityProposalLibrary && (
                  <Buttons>
                    {!scientist.is_in_network && (
                      <Button
                        type="button"
                        size="sm"
                        iconName="Invite"
                        disabled={isScientistLoading}
                        margin="0 10px 0 0"
                        onClick={() => addToNetwork?.(scientist)}
                      >
                        {t("components.buttons.add_to_network")}
                      </Button>
                    )}
                    {!currentUser.profile_info.commenter && (
                      <Button
                        type="button"
                        size="sm"
                        iconName="Message"
                        margin="0 10px 0 0"
                        onClick={() => sendMessage?.(scientist)}
                        data-testid="sci-net-slideout-message-button"
                      >
                        Message
                      </Button>
                    )}
                    <Button
                      type="button"
                      size="sm"
                      variant="secondary"
                      iconName="Share"
                      margin="0 10px 0 0"
                      onClick={() => setIsShareModalOpen(true)}
                      data-testid="sci-net-slideout-share-button"
                    >
                      Share Profile
                    </Button>
                  </Buttons>
                )}
              </TopContent>
              <ScrollContainer>
                <About data-testid="about">{about}</About>
                <Keywords>
                  {scientist.keywords.map((keyword) => (
                    <Tag
                      key={`keyword-${keyword}`}
                      content={keyword}
                      size="sm"
                      theme="neutral"
                      data-testid="keyword-tag"
                    />
                  ))}
                </Keywords>
                {positions.length > 0 && (
                  <Position.Container>
                    Positions:
                    {positions.map(
                      (position: PositionInfo) =>
                        position.location && (
                          <Position.IndividualContainer key={`position-${position.id}`}>
                            <Position.Title>{position.location}</Position.Title>
                            <div>
                              {position.title ?? <Position.Note>(No title provided)</Position.Note>}
                            </div>
                            {position.duration ?? <Position.Note>(No date provided)</Position.Note>}
                          </Position.IndividualContainer>
                        )
                    )}
                  </Position.Container>
                )}
                <Tabs
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  tabs={tabs}
                  margin="0 0 24px"
                />
                {visibleTabs.map((tab) => (
                  <TabPanel
                    isActive={activeTab === tab.label}
                    key={`tabpanel-${tab.label}`}
                    windowHeight={windowHeight}
                  >
                    {tab.data.map(
                      (attachment) =>
                        activeTab === tab.label && (
                          <ScientistContribution key={attachment.id} contribution={attachment} />
                        )
                    )}
                  </TabPanel>
                ))}
              </ScrollContainer>
            </>
          )}
        </Container>
      </SlideModalBase>
    </>
  );
};

export default ScientistQuickViewModal;

const Container = styled.div`
  ${FONTS.REGULAR_2};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 622px;
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 50px 24px 24px 24px;
`;

const UserPreviewContainer = styled.div`
  margin-right: 48px;
`;

const Buttons = styled.div`
  display: flex;
  margin: 6px 0;
`;

const ScrollContainer = styled.div`
  height: 100%;
  padding-left: 24px;
  padding-right: 24px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const About = styled.div`
  margin-bottom: 20px;
`;

const Keywords = styled.div`
  margin-bottom: 40px;
  line-height: 30px;
`;

const Position = {
  Container: styled.div`
    margin-bottom: 40px;
  `,
  IndividualContainer: styled.div`
    margin-top: 14px;
  `,
  Title: styled.div`
    ${FONTS.SEMIBOLD_2};
  `,
  Note: styled.span`
    color: ${COLORS.NEUTRAL_400};
  `,
};

const TabPanel = styled.div`
  display: ${({ isActive }) => (isActive ? "flex" : " none")};
  flex-direction: column;
  gap: 8px;
  // 242px is the height of the top non-scrolling section and 53px is the height of the tabs
  min-height: ${({ windowHeight }) => windowHeight - 242 - 53}px;
  ${FONTS.REGULAR_2};
  & > div {
    margin-top: 0 !important;
    & > div {
      padding-top: 0 !important;
    }
  }
`;
