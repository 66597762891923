import toast from "@components/Toast";
import { Button, InfoTag } from "@components/library";
import { SearchBarInput } from "@components/library/Inputs/SearchBarInput";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { getGoogleScholarAuthor } from "@requests/googleScholarAuthors";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { GoogleScholarAuthor } from "@tsTypes/google_scholar_authors";
import { track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import Fuse from "fuse.js";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";
import { UserInfo } from "../OnboardingContainer";
import { StepHeader } from "../StepStyles";
import { AuthorCard } from "./AuthorCard";

interface Props {
  userInfo: UserInfo;
  onContinue: (step: string) => void;
  onBack: (step: string) => void;
  authors: GoogleScholarAuthor[];
  selectedAuthorId: number | null;
  setSelectedAuthorId: Dispatch<SetStateAction<number | null>>;
  numDisplayed: number;
  setNumDisplayed: Dispatch<SetStateAction<number>>;
  filteredAuthorIds: number[];
  setFilteredAuthorIds: Dispatch<SetStateAction<number[]>>;
  isFetching: boolean;
}

export const DISPLAY_INCREMENT = 3;

const getAuthorIdFromUrl = (url: string): string | null => {
  try {
    const urlObj = new URL(url);
    if (urlObj.host.includes("scholar.google")) {
      const authorId = urlObj.searchParams.get("user");
      if (authorId) {
        return authorId;
      }
    }
  } catch (e) {
    // Not a valid URL
  }

  return null;
};

export function Step3aGoogleScholar({
  userInfo,
  onContinue,
  onBack,
  authors,
  selectedAuthorId,
  setSelectedAuthorId,
  numDisplayed,
  setNumDisplayed,
  filteredAuthorIds,
  setFilteredAuthorIds,
  isFetching,
}: Props) {
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setNumDisplayed(DISPLAY_INCREMENT);
    setFilteredAuthorIds(authors.map((author) => author.id));
  }, []);

  useEffect(() => {
    if (filteredAuthorIds.length === 0) {
      setFilteredAuthorIds(authors.map((author) => author.id));
    }
  }, [authors]);

  useEffect(() => {
    if (!searchText && filteredAuthorIds.length === 0) {
      setFilteredAuthorIds(authors.map((author) => author.id));
      setNumDisplayed(DISPLAY_INCREMENT);
    }
  }, [searchText]);

  const { mutate: getAuthor } = useMutation({
    mutationFn: getGoogleScholarAuthor,
    onSuccess: ({ user_id }) => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ["getGoogleScholarAuthors"] });
        setSelectedAuthorId(user_id);
        setFilteredAuthorIds([user_id]);
        setIsLoading(false);
      }, 1000);
    },
    onError: (err) => {
      toast.error(
        "Something went wrong while fetching your Google Scholar profile. Please try again."
      );
      appsignal.sendError(err as any, (span) => {
        span.setAction("Step3aGoogleScholar#getAuthor");
      });
      setIsLoading(false);
    },
  });

  const fuse = useMemo(
    () => new Fuse(authors, { keys: ["name", "location", "publications.title"] }),
    [authors]
  );

  const filter = (input: string): void => {
    setNumDisplayed(DISPLAY_INCREMENT);

    if (!input) {
      setFilteredAuthorIds(authors.map((author) => author.id));
      return;
    }

    // Check if input is a Google Scholar URL
    const authorId = getAuthorIdFromUrl(input);

    track(SegmentEventName.Search, {
      react_component: "Step3aGoogleScholar",
      search_query: input,
      has_author_id: Boolean(authorId),
    });

    if (authorId) {
      setIsLoading(true);
      getAuthor(authorId);
      return;
    }

    const result = fuse.search(input);
    setFilteredAuthorIds(result.map((r) => r.item.id));
  };

  const isResearcher =
    userInfo.role === "research-scientist" || userInfo.role === "company-scientist";
  const hasResults = authors.length > 0;

  // Skip if not researcher or no results (after final refetch)
  if (!isResearcher || (!isFetching && !hasResults)) {
    onContinue("step3a");
    return null;
  }

  // Show loader if final refetch is underway
  if (!hasResults) {
    return <Loader active inline="centered" />;
  }

  return (
    <Container>
      <StepHeader
        subheading="Import profile information"
        heading={
          <>
            Is this you? You can select a <b>Google Scholar</b> account to save time building your
            Halo profile, or skip to the next step.
          </>
        }
      />
      <FilterContainer>
        <SearchBarInput
          value={searchText}
          onChange={setSearchText}
          onSubmit={filter}
          placeholder="Filter by institution, publication, Google Scholar URL, etc."
        />
        <div>
          <InfoTag backgroundColor={COLORS.NEUTRAL_400}>{filteredAuthorIds.length}</InfoTag>
          Google Scholar accounts found
        </div>
      </FilterContainer>
      <AuthorsContainer>
        {isLoading ? (
          <>
            Searching for provided Google Scholar URL...
            <Loader active inline="centered" />
          </>
        ) : (
          <>
            {filteredAuthorIds.slice(0, numDisplayed).map((authorId) => {
              const author = authors.find(({ id }) => id === authorId);
              if (!author) return null;
              return (
                <AuthorCard
                  key={`author-${author.id}`}
                  author={author}
                  isSelected={selectedAuthorId === author.id}
                  onSelect={(id) => {
                    setSelectedAuthorId(id);
                    track(SegmentEventName.Click, {
                      react_component: "Step3aGoogleScholar",
                      ui_component: "AuthorCard",
                      author_id: id,
                    });
                  }}
                />
              );
            })}
            {numDisplayed < filteredAuthorIds.length && (
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={() => {
                  setNumDisplayed(numDisplayed + DISPLAY_INCREMENT);
                  track(SegmentEventName.Click, {
                    react_component: "Step3aGoogleScholar",
                    ui_component: "Load More Button",
                    num_displayed: numDisplayed + DISPLAY_INCREMENT,
                    total: filteredAuthorIds.length,
                  });
                }}
              >
                Load more
              </Button>
            )}
          </>
        )}
      </AuthorsContainer>
      <ButtonsContainer canLoadMore={numDisplayed < authors.length}>
        <Button
          type="button"
          variant="ghost"
          size="sm"
          onClick={() => {
            onBack("step2");
            track(SegmentEventName.Click, {
              react_component: "Step3aGoogleScholar",
              ui_component: "Back Button",
              total: filteredAuthorIds.length,
              author_id: selectedAuthorId,
            });
          }}
        >
          Back
        </Button>
        <Button
          type="button"
          variant="primary"
          size="sm"
          margin="0 24px"
          onClick={() => {
            onContinue("step3a");
            track(SegmentEventName.Click, {
              react_component: "Step3aGoogleScholar",
              ui_component: "Next Button (with selection)",
              total: filteredAuthorIds.length,
              author_id: selectedAuthorId,
            });
          }}
          disabled={!selectedAuthorId}
        >
          Continue
        </Button>
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={() => {
            setSelectedAuthorId(null);
            onContinue("step3a");
            track(SegmentEventName.Click, {
              react_component: "Step3aGoogleScholar",
              ui_component: "Skip Button",
              total: filteredAuthorIds.length,
            });
          }}
        >
          Skip
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

export const Container = styled.div`
  text-align: left;
  padding: 0;
  width: 800px;
  margin-bottom: 200px;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 0 30px;
    width: 100%;
  }
`;

export const StepTitle = styled.h1`
  margin: 0 0 8px;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.SEMIBOLD_1}
`;

export const StepInfo = styled.h2`
  margin: 0;
  ${FONTS.HEADING_3_REGULAR}
`;

const GSIconFrame = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 4px;
  vertical-align: top;
`;

const GSIcon = styled.img`
  width: 27px;
  height: 27px;
  flex-shrink: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  margin: 32px 0;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_2}

  & > form {
    width: min(100%, 515px);
  }

  & > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const AuthorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  ${FONTS.SEMIBOLD_2}
`;

const ButtonsContainer = styled.div`
  width: 100%;
  margin-top: ${({ canLoadMore }) => (canLoadMore ? "28px" : "40px")};
  padding-top: 44px;
  border-top: 1px solid ${COLORS.NEUTRAL_200};
  line-height: 50px;
`;
