import { Icon } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import { ClearFilterButton } from "../UniversityTeam/UniversityTeamSearch";

/**
 * Page layout
 */

export const Columns = styled.div`
  display: flex;
  gap: 36px;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FilterColumn = styled.div`
  flex: 0 0 280px;
  max-width: 280px;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: none;
  }
`;

export const ContentColumn = styled.div`
  flex: 1;
`;

/**
 * Filters
 */

export const FilterContainer = styled.div`
  position: sticky;
  top: 50px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 0 0 32px 0;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    position: relative;
    top: 0;
  }
`;

export const FilterSection = ({
  label,
  labelMargin = "0 0 16px",
  collapsible,
  children,
}: {
  label: string;
  labelMargin?: string;
  collapsible?: boolean;
  children: ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <FilterSectionContainer isExpanded={isExpanded}>
      <Label
        margin={labelMargin}
        collapsible={collapsible}
        onClick={() => collapsible && setIsExpanded(!isExpanded)}
      >
        {label}
        {collapsible && <Icon name={isExpanded ? "Chevron Up" : "Chevron Down"} />}
      </Label>
      <FilterContentsContainer isExpanded={isExpanded}>{children}</FilterContentsContainer>
    </FilterSectionContainer>
  );
};

const FilterSectionContainer = styled.div`
  padding-bottom: ${({ isExpanded }) => (isExpanded ? "24px" : "0")};
  border-bottom: 1px solid ${COLORS.NEUTRAL_200};
`;

const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin ?? "0 0 16px"};
  ${FONTS.SEMIBOLD_1}

  &:hover {
    ${({ collapsible }) => collapsible && `cursor: pointer;`}
  }
`;

const FilterContentsContainer = styled.div`
  height: ${({ isExpanded }) => (isExpanded ? "auto" : "0")};
  overflow: hidden;
`;

/**
 * Inputs
 */

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ResetFiltersButton = ({ onClick }: { onClick: () => void }) => (
  <ClearFilterButton type="button" onClick={onClick}>
    <Icon name="Refresh" margin="0 4px 2px -4px" color={COLORS.HALO_BLUE} />
    Reset filters
  </ClearFilterButton>
);
