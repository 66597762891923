import api from "./request";
import * as Routes from "../routes/routes.js";

export const updateInterest = async (
  requestId: number,
  signedNotificationId: string,
  isInterested: boolean
) => {
  const responseJson = await api.post(
    Routes.update_request_interests_path({
      id: requestId,
    }),
    {
      is_interested: isInterested,
      signed_notification_id: signedNotificationId,
    }
  );

  return responseJson.data;
};
