import { RootState } from "@redux/store";
import { setPrevUrlCookie } from "@utils/authUtils";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

const PrivateRoute = ({ ...props }) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  if (!currentUser.id) {
    setPrevUrlCookie(window.location.href);
    window.location.href = "/login";
  }

  return currentUser.id ? <Route {...props} /> : null;
};

export default PrivateRoute;
