import toast from "@components/Toast";
import {
  company_network_scientists_path,
  search_suggest_company_network_scientists_path,
} from "@routes/routes";
import { ConnectionType } from "@tsTypes/__generated__/enums/connection_type";
import { CompanyNetworkScientistsIndexResponse } from "@tsTypes/company_network_scientists";
import {
  CompanyMember,
  SponsorProfilesIndexResponse,
  SponsorTeamMembersResponse,
  UpdatePendingReviewTeamMembershipStatusResponse,
  UpgradeToSuperAdminResponse,
} from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { AxiosResponse } from "axios";
import request from "./request";

export const getCompanyMembers = async (
  companyId: number
): Promise<SponsorProfilesIndexResponse> => {
  const response = await request.get("/sponsor_profiles", {
    params: {
      company_id: companyId,
    },
  });

  return response.data;
};

export const getFilteredCompanyMembers = async (
  options: {
    keywordFilter: string[];
    companyRoleFilter: string[];
    requestRoleFilter: string[];
  },
  abortSignal: AbortSignal
): Promise<SponsorTeamMembersResponse> => {
  try {
    const response = await request.get("/sponsor_profiles/sponsor_team_members", {
      signal: abortSignal,
      params: {
        options: {
          keyword_filter: options.keywordFilter,
          company_role_filter: options.companyRoleFilter,
          request_role_filter: options.requestRoleFilter,
        },
      },
    });

    return response.data;
  } catch (error) {
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#getFilteredCompanyMembers");
      span.setTags({
        options: JSON.stringify(options),
      });
    });
    return {
      members: [],
      count: 0,
    };
  }
};

export const getSponsorTeamKeywordOptions = async (): Promise<{
  areas_of_expertise: string[];
}> => {
  try {
    const response = await request.get("/sponsor_profiles/sponsor_team_keyword_options");
    return response.data.keyword_options;
  } catch (error) {
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#getSponsorTeamKeywordOptions");
    });
    return {
      areas_of_expertise: [],
    };
  }
};

export const upgradeToPremium = async (userId: number): Promise<CompanyMember | null> => {
  try {
    const response = await request.post("/company_roles/upgrade_to_premium", {
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.error);
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#upgradeToPremium");
      span.setTags({
        userId: userId.toString(),
      });
    });
    return null;
  }
};

export const upgradeToStandardLicense = async (userId: number): Promise<CompanyMember | null> => {
  try {
    const response = await request.post("/company_roles/upgrade_to_standard_license", {
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.error);
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#upgradeToStandardLicense");
      span.setTags({
        userId: userId.toString(),
      });
    });
    return null;
  }
};

export const upgradeToAdmin = async (userId: number): Promise<CompanyMember | null> => {
  try {
    const response = await request.post("/company_roles/upgrade_to_admin", {
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.error);
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#upgradeToAdmin");
      span.setTags({
        userId: userId.toString(),
      });
    });
    return null;
  }
};

export const downgradeFromAdmin = async (userId: number): Promise<CompanyMember | null> => {
  try {
    const response = await request.post("/company_roles/downgrade_from_admin", {
      user_id: userId,
    });
    return response.data;
  } catch (error) {
    toast.error(error.response.data.error);
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#downgradeFromAdmin");
      span.setTags({
        userId: userId.toString(),
      });
    });
    return null;
  }
};

export const upgradeToSuperAdmin = async (
  userId: number
): Promise<UpgradeToSuperAdminResponse["users"] | null> => {
  try {
    const response = await request.post("/company_roles/upgrade_to_super_admin", {
      user_id: userId,
    });
    return response.data.users;
  } catch (error) {
    toast.error(error.response.data.error);
    appsignal.sendError(error, (span) => {
      span.setAction("sponsors#upgradeToSuperAdmin");
      span.setTags({
        userId: userId.toString(),
      });
    });
    return null;
  }
};

export const updatePendingReviewTeamMembership = async (
  sid: string,
  isApprovedForLicense: string | null
): Promise<UpdatePendingReviewTeamMembershipStatusResponse | null> => {
  try {
    const response = await request.post("/pending_review_team_memberships/update_status", {
      sid,
      is_approved_for_license: isApprovedForLicense,
    });
    return response.data;
  } catch (e) {
    toast.error(e.response.data.error);
    if (e.response.status !== 401) {
      appsignal.sendError(e, (span) => {
        span.setAction("sponsors#updatePendingReviewTeamMembership");
        span.setTags({
          sid,
        });
      });
    }
    return null;
  }
};

export const getNetworkScientists = async (options: {
  pageNumber: number;
  sortBy?: "RECENT" | "ALPHABETICAL" | "DEFAULT";
  searchFilter?: string[];
  countryFilter?: string[];
  institutionTypeFilter?: string[];
  institutionNameFilter?: string[];
  requestIdFilter?: string[];
  statusFilter?: string | null;
  keywordFilter?: string[];
  submittersOnlyFilter?: boolean;
  connectionFilter?: ConnectionType;
  accessToken?: string | null;
}): Promise<CompanyNetworkScientistsIndexResponse> => {
  const params: Record<string, any> = {
    for_scientist_network: true,
    options: {
      order_by: options.sortBy ?? "DEFAULT",
      page_number: options.pageNumber,
      search_filter: options.searchFilter,
      country_filter: options.countryFilter,
      institution_type_filter: options.institutionTypeFilter,
      institution_name_filter: options.institutionNameFilter,
      request_id_filter: options.requestIdFilter,
      status_filter: options.statusFilter,
      keyword_filter: options.keywordFilter,
      submitters_only_filter: options.submittersOnlyFilter,
      connection_filter: options.connectionFilter,
    },
  };
  if (options.accessToken) {
    params.access_token = options.accessToken;
  }
  const response = await request.get<CompanyNetworkScientistsIndexResponse>(
    company_network_scientists_path(),
    {
      params,
    }
  );
  return response.data;
};

export const getScientistNetworkOptions = async (): Promise<{
  keywords: {
    disciplines: string[];
    areas_of_expertise: string[];
  };
  institution_names: {
    all: string[];
    universities: string[];
    startups: string[];
  };
}> => {
  try {
    const response = await request.get(search_suggest_company_network_scientists_path());
    return response.data;
  } catch (e) {
    appsignal.sendError(e, (span) => {
      span.setAction("sponsors#getNetworkScientistOptions");
    });
    return {
      keywords: {
        disciplines: [],
        areas_of_expertise: [],
      },
      institution_names: {
        all: [],
        universities: [],
        startups: [],
      },
    };
  }
};

export const sponsorShareScientist = async (data: {
  scientist_id: number;
  email: string;
  message: string;
}): Promise<AxiosResponse> => request.post("/notifications/sponsor_share_scientist", data);
