import { InfoTag } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { setShowErrorBoundary } from "@redux/actions/defaultActions";
import { RootState } from "@redux/store";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props {
  to: string;
  children: ReactNode;
  asAnchor?: boolean;
  withNewTag?: boolean;
  "data-testid"?: string;
  activeStylesMatchType?: "startsWith" | "exact";
}

function NavbarLink({
  to,
  children,
  asAnchor = false,
  withNewTag = false,
  "data-testid": dataTestId,
  activeStylesMatchType = "exact",
}: Props) {
  const showErrorBoundary = useSelector(
    (state: RootState) => state.defaultReducer.showErrorBoundary
  );

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleClick = () => {
    if (showErrorBoundary) {
      dispatch(setShowErrorBoundary(false));
      window.location.href = to;
    }
  };

  let isActive = false;
  if (activeStylesMatchType === "startsWith") {
    isActive = String(pathname).startsWith(to) && !showErrorBoundary;
  } else if (activeStylesMatchType === "exact") {
    isActive = String(pathname) === to && !showErrorBoundary;
  }

  return (
    <Container isActive={isActive}>
      <Link
        to={to}
        as={asAnchor ? "a" : NavLink}
        href={asAnchor && to}
        onClick={handleClick}
        $isActive={isActive}
        data-testid={dataTestId}
      >
        {children}
        {withNewTag && (
          <InfoTag accent marginLeft="8px">
            NEW
          </InfoTag>
        )}
      </Link>
    </Container>
  );
}

export default NavbarLink;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 6px 16px 0;
  border-bottom: 3px solid ${({ isActive }) => (isActive ? COLORS.HALO_BLUE : "transparent")};
`;
const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  ${FONTS.MEDIUM_2};
  color: ${COLORS.BLACK};
  &:hover,
  &:focus {
    color: ${COLORS.HALO_BLUE};
    text-decoration: none;
  }
  ${({ $isActive }) =>
    $isActive &&
    `
      color: ${COLORS.HALO_BLUE};
      text-decoration: none;
    `}
`;
