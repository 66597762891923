import { Button, PageContainer } from "@components/library";
import { COLORS, FONTS } from "@constants";
import styled from "styled-components";

const Forbidden403 = () => {
  return (
    <PageContainer hasTopDivider={true}>
      <BlueBox>
        <Title>403.</Title>
        <Subtitle>Sorry, you don&rsquo;t have access to this page.</Subtitle>
        <Text>
          You can check the URL for typos or errors, or try logging in with a different account.
        </Text>
        <Button onClick={() => history.go(-1)}>Go back</Button>
      </BlueBox>
    </PageContainer>
  );
};

const BlueBox = styled.div`
  padding: 64px;
  background-color: ${COLORS.BLUE_LIGHT_100};
  border-radius: 8px;
`;

const Title = styled.div`
  ${FONTS.HEADING_JUMBO}
  color: ${COLORS.BLACK}
`;

const Subtitle = styled.div`
  ${FONTS.HEADING_3_REGULAR}
  color: ${COLORS.BLACK}
`;

const Text = styled.div`
  ${FONTS.REGULAR_1}
  color: ${COLORS.BLACK}
  margin-top: 8px;
  margin-bottom: 56px;
`;

export default Forbidden403;
