import { PROPOSAL_STATUSES, PROPOSAL_TYPES } from "@constants";
import { ProposalReviewStatus } from "@tsTypes/__generated__/enums/proposal_review_status";
import { ProposalStageNotStatus, ProposalStatus } from "@tsTypes/proposals";
import { ProposalyType } from "@tsTypes/proposaly";
import { ProposalStatusOrProposalReviewStatus } from "../tsTypes/proposals";

export const isFinalistOrDeclined = (status: ProposalStatusOrProposalReviewStatus) =>
  [
    ProposalStatus.FINALIST,
    ProposalStatus.DECLINED,
    ProposalReviewStatus.DECLINED,
    ProposalReviewStatus.MEET,
  ].includes(status);

export const isQualified = (status: ProposalStatusOrProposalReviewStatus) =>
  [ProposalStatus.QUALIFIED, ProposalReviewStatus.ASSESS].includes(status);

export const ProposalStageFromStatus = (proposal: {
  status: PROPOSAL_STATUSES;
  furthest_status_reached: PROPOSAL_STATUSES;
  first_completed_at: string | null;
}): ProposalStageNotStatus => {
  switch (proposal.status) {
    case PROPOSAL_STATUSES.OPEN:
      return proposal.first_completed_at
        ? ProposalStageNotStatus.SUBMITTED
        : ProposalStageNotStatus.DRAFT;
    case PROPOSAL_STATUSES.PENDING:
      return ProposalStageNotStatus.SUBMITTED;
    case PROPOSAL_STATUSES.QUALIFIED:
      return ProposalStageNotStatus.QUALIFIED;
    case PROPOSAL_STATUSES.SHORTLISTED:
      return ProposalStageNotStatus.SHORTLISTED;
    case PROPOSAL_STATUSES.FINALIST:
      return ProposalStageNotStatus.FINALIST;
    case PROPOSAL_STATUSES.DECLINED:
      switch (proposal.furthest_status_reached) {
        case PROPOSAL_STATUSES.FINALIST:
          return ProposalStageNotStatus.DECLINED_FINALIST;
        case PROPOSAL_STATUSES.SHORTLISTED:
          return ProposalStageNotStatus.DECLINED_SHORTLISTED;
        case PROPOSAL_STATUSES.QUALIFIED:
          return ProposalStageNotStatus.DECLINED_QUALIFIED;
        default:
          return ProposalStageNotStatus.DECLINED;
      }
    default:
      return ProposalStageNotStatus.DECLINED;
  }
};

export const checkIsChemicalCompoundProposal = (proposal: Record<string, any>) => {
  return (
    proposal.proposaly_type === ProposalyType.PROPOSAL && proposal.type === PROPOSAL_TYPES.MATERIALS
  );
};
