/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
export enum ProposalReviewStatus {
  PENDING = "pending",
  SCREEN = "screen",
  ASSESS = "assess",
  MEET = "meet",
  DECLINED = "declined",
}
