import { lazyWithRetry } from "@utils/appUtils";
import { Route } from "react-router-dom";
import {
  notification_campaigns_unsubscribe_path,
  resume_outreach_notification_campaigns_path,
} from "./routes.js";
import { formatPathForRouter } from "./utils";

const UnsubscribePage = lazyWithRetry(
  () => import("../views/NotificationCampaigns/Unsubscribe/UnsubscribePage")
);
const ResumeOutreachPage = lazyWithRetry(
  () => import("../views/NotificationCampaigns/ResumeOutreach/ResumeOutreachPage")
);

const routes = [
  <Route
    exact
    key="notification_campaign_routes/1"
    path={formatPathForRouter(notification_campaigns_unsubscribe_path())}
    component={UnsubscribePage}
  />,
  <Route
    exact
    key="notification_campaign_routes/2"
    path={formatPathForRouter(resume_outreach_notification_campaigns_path())}
    component={ResumeOutreachPage}
  />,
];

export default routes;
