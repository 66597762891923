import { Button } from "@components/library";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS } from "@constants";
import { RootState } from "@redux/store";
import { UserRole } from "@tsTypes/users";
import { t } from "@utils/i18n";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props extends BlankModalProps {
  setIsSubscriptionEndedModalOpen: Dispatch<SetStateAction<boolean>>;
  bannerMessage: string;
}

const BLOCKED_PATHS = ["/proposal_library"];
const EXTENDED_ACCESS_PATHS = ["/review_system", "/proposal"];

const SubscriptionEndedModal = ({
  isOpen,
  onClose,
  setIsSubscriptionEndedModalOpen,
  bannerMessage,
}: Props) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const subscription = useSelector((state: RootState) => state.company.subscription);

  const { pathname } = useLocation();
  const history = useHistory();

  const isBlockedPage =
    BLOCKED_PATHS.includes(pathname) ||
    (!subscription?.has_extension &&
      EXTENDED_ACCESS_PATHS.some((path) => pathname.startsWith(path)));

  useEffect(() => {
    setIsSubscriptionEndedModalOpen(isBlockedPage);
  }, [isBlockedPage]);

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={isBlockedPage}
      closeOnDimmerClick={!isBlockedPage}
      backgroundMode="blur"
    >
      <Container>
        <Title>{bannerMessage}</Title>
        {t([
          "components.subscription_warning.modal",
          currentUser.role === UserRole.SPONSOR ? "sponsor" : "expert",
        ])}
        <Buttons>
          {currentUser.profile_info.is_admin && (
            <Link to={"/contact"} target="_blank">
              <Button type="button">{t("components.subscription_warning.cta")}</Button>
            </Link>
          )}
          {isBlockedPage && (
            <Button
              variant={currentUser.profile_info.is_admin ? "secondary" : "primary"}
              onClick={() => history.push("/reviewer_dashboard")}
            >
              Go to dashboard
            </Button>
          )}
        </Buttons>
      </Container>
    </BlankModal>
  );
};

export default SubscriptionEndedModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.BLACK};
  padding: 60px 60px 48px;
  ${FONTS.REGULAR_1};
`;
const Title = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 20px;
`;
const Buttons = styled.div`
  display: flex;
  gap: 32px;
  margin: 32px auto 0;
`;
