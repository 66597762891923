import { CREDENTIALS } from "@constants";
import Ably from "ably";

// Do not auto-connect in order to "save" connections only when we need it (free plan is up to 200)
// If you require realtime functionality, we should purchase a full plan.
const ably = new Ably.Realtime({
  key: CREDENTIALS.ABLY_FRONTEND_API_KEY,
  autoConnect: false,
});

export default ably;
