import { TextLink, UserPreview } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { Company } from "@tsTypes/schema";
import { UserRole } from "@tsTypes/users";
import { reviewTeamMembersForRfpPage } from "@utils/requestUtils";
import { renderMarkdownText } from "@utils/textUtils";
import styled from "styled-components";

interface Props {
  request: {
    id: number;
    review_team_members?: any[];
    company:
      | Pick<
          Company,
          "company_name" | "avatar" | "verification_status" | "description" | "identifier"
        >
      | (Pick<Company, "private_alias" | "verification_status"> & {
          is_partner: boolean;
        });
  };
  isResponsive?: boolean;
  withMarginTop?: boolean;
  customText?: string; // Override the default company "Who we are" text
  lineHeight?: string;
}

export default function AboutCompany({
  request,
  isResponsive = true,
  withMarginTop = true,
  customText,
  lineHeight,
}: Props) {
  const company = request.company;

  if ("private_alias" in company) return null;

  const reviewTeamMembersForDisplay = reviewTeamMembersForRfpPage(request as any);
  const text = customText ?? company.description;

  return (
    <Container data-testid="rfp-about-company" lineHeight={lineHeight}>
      {Number(text?.length) > 0 && (
        <>
          <Heading id="who-we-are" withMarginTop={withMarginTop}>
            Who we are
          </Heading>
          <div>
            {customText ? (
              renderMarkdownText(text)
            ) : (
              <>
                {renderMarkdownText(text)}
                &nbsp;
                <TextLink
                  to={`/company/${company.identifier}`}
                  color={COLORS.HALO_BLUE}
                  font={FONTS.MEDIUM_1}
                  underline
                  newTab
                >
                  Learn more
                </TextLink>
              </>
            )}
          </div>
        </>
      )}
      {reviewTeamMembersForDisplay.length > 0 && (
        <>
          <Heading withMarginTop>Reviewers</Heading>
          <Reviewers isResponsive={isResponsive}>
            {reviewTeamMembersForDisplay.map((member) => (
              <UserPreview
                key={"reviewer-" + member.id}
                user={member as any}
                size="xl"
                userRole={UserRole.SPONSOR}
              />
            ))}
          </Reviewers>
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  ${FONTS.REGULAR_1}
  line-height: ${({ lineHeight }) => lineHeight ?? "2"};
  & p {
    margin-bottom: 16px !important;
  }
`;
const Heading = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  ${({ withMarginTop }) => withMarginTop && `margin-top: 64px;`}
  margin-bottom: 24px;
`;
const Reviewers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 64px 48px;
  ${({ isResponsive }) =>
    isResponsive &&
    `
      ${WINDOW_DIMENSIONS.EXTRA_WIDE_MEDIA_QUERY} {
        grid-template-columns: 1fr 1fr;
      }
  `}
`;
