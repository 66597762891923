import { BlankModal, Icon, TextButton } from "@components/library";
import Button from "@components/library/Buttons/Button";
import { COLORS, FONTS } from "@constants";
import { useEffect, useState } from "react";
import styled from "styled-components";

const RfpQuestionSubmittedModal = ({ onClose, isOpen, handleOpenShareModal }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  useEffect(() => {
    if (isOpen) {
      setIsCopied(false);
    }
  }, [isOpen]);

  return (
    <BlankModal onClose={onClose} isOpen={isOpen} width="548px" height="350px">
      <ModalContent>
        <Icon name="Success" color={COLORS.GREEN} size="xxl" />
        <div>
          <Top>
            <Header>Your question was submitted!</Header>
            <SubHeader>
              Your question is currently under review by the team at Halo. We&rsquo;ll let you know
              when it&rsquo;s published.
            </SubHeader>
          </Top>
          <SubHeader>Have a colleague who might be interested?</SubHeader>
          <Button iconName="Share" margin="0 0 16px 0" onClick={handleOpenShareModal}>
            Share this opportunity
          </Button>
          <Or>
            or{" "}
            <TextButton
              onClick={() => {
                navigator.clipboard.writeText(window.location.href);
                setIsCopied(true);
              }}
              text={isCopied ? "Copied!" : "Copy link to clipboard"}
              color={isCopied ? COLORS.GREEN : COLORS.HALO_BLUE}
              iconName="Copy"
              iconPosition="left"
              size="sm"
            />
          </Or>
        </div>
      </ModalContent>
    </BlankModal>
  );
};

export default RfpQuestionSubmittedModal;

const ModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding: 48px 32px;
`;
const Header = styled.div`
  color: ${COLORS.BLACK};
  ${FONTS.HEADING_4_SEMIBOLD};
  margin: 4px 0px 0px 0px;
`;
const SubHeader = styled.div`
  ${FONTS.REGULAR_2};
  margin: 6px auto 26px auto;
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Or = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${FONTS.REGULAR_3};
`;
