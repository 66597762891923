import MessageAttachmentGalleryModal from "@components/modals/MessageAttachmentGalleryModal/MessageAttachmentGalleryModal";
import { RequestAttachmentPartial } from "@tsTypes/request_attachments/_request_attachment";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import RequestAttachment from "./RequestAttachment";

interface Props {
  heading: ReactNode;
  attachments: RequestAttachmentPartial[];
}

export default function RequestAttachmentsContainer({ heading, attachments = [] }: Props) {
  const [focusedGalleryAttachmentIndex, setFocusedGalleryAttachmentIndex] = useState<number>();

  const openGallery = (attachmentId: number) => {
    const index = attachments.findIndex((attachment) => attachment.id === attachmentId);
    setFocusedGalleryAttachmentIndex(index);
  };

  const closeGallery = () => {
    setFocusedGalleryAttachmentIndex(undefined);
  };

  if (attachments.length === 0) return null;

  return (
    <>
      <MessageAttachmentGalleryModal
        attachments={focusedGalleryAttachmentIndex !== null ? attachments : []}
        focusedAttachmentIndex={focusedGalleryAttachmentIndex}
        setFocusedAttachmentIndex={setFocusedGalleryAttachmentIndex}
        closeGallery={closeGallery}
      />
      {heading}
      <AttachmentsContainer>
        {attachments.map((attachment) => (
          <RequestAttachment
            key={attachment.id}
            attachment={attachment}
            onClick={() => openGallery(attachment.id)}
          />
        ))}
      </AttachmentsContainer>
    </>
  );
}

const AttachmentsContainer = styled.div`
  display: grid;
  grid-template-columns: 280px 280px;
  gap: 16px;
`;
