import { AxiosResponse } from "axios";

import { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import ShareWithinCompanyModal from "@components/library/ModalBases/ShareWithinCompanyModal";
import { sponsorShareScientist } from "@requests/sponsors";

export interface SponsorShareScientistModalProps extends BlankModalProps {
  scientist: any;
}

const SponsorShareScientistModal = ({
  isOpen,
  onClose,
  scientist,
}: SponsorShareScientistModalProps) => {
  const handleShare = (email: string, message: string): Promise<AxiosResponse> => {
    const data = {
      scientist_id: scientist.id,
      email,
      message,
    };

    return sponsorShareScientist(data);
  };

  return (
    <ShareWithinCompanyModal
      isOpen={isOpen}
      onClose={onClose}
      headerText="Share Scientist Profile"
      toastText="Scientist profile shared successfully"
      handleShare={handleShare}
    />
  );
};

export default SponsorShareScientistModal;
