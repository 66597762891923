import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RfpRequestForCard } from "@tsTypes/index";
import { track } from "@utils/appUtils";
import styled from "styled-components";

interface Props {
  request: RfpRequestForCard;
  toggleUnsubscribeFromRfp: (
    e: MouseEvent,
    request: RfpRequestForCard,
    isInterested: boolean
  ) => void;
}

const UnsubscribedRfpCard = ({ request, toggleUnsubscribeFromRfp }: Props) => {
  return (
    <Container>
      <Heading>We won&apos;t contact you with further updates about this opportunity.</Heading>
      <div>
        <Button
          size="sm"
          type="button"
          onClick={(e) => {
            track(SegmentEventName.Click, {
              react_component: "UnsubscribedRfpCard",
              ui_component: "Undo Button",
              request_slug: request.slug,
            });
            toggleUnsubscribeFromRfp(e, request, true);
          }}
        >
          Undo
        </Button>
      </div>
    </Container>
  );
};

export default UnsubscribedRfpCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 240px;
  background-color: ${COLORS.NEUTRAL_50};
  padding: 24px 24px 16px;
  border-radius: 4px;
`;
const Heading = styled.div`
  ${FONTS.MEDIUM_1}
  margin: 0 0 20px;
`;
