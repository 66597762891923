import api from "@requests/request";
import { email_response_templates_path } from "@routes/routes";

export async function fetchEmailResponseTemplates(company_id: string | number, request) {
  const response = await api.get(email_response_templates_path(), {
    params: {
      request_id: request?.id,
      company_id: company_id,
    },
  });
  return response.data.email_response_templates;
}

export async function sponsorShareProposal({ message, proposal_id, email }) {
  const data = {
    message,
    proposal_id,
    email,
  };
  const responseJson = await api.post("/notifications/sponsor_share_proposal", data);

  return responseJson;
}

export async function fetchProposalsSortedByScore({ rfp_id, status, type }) {
  const responseJson = await api.get("/proposals/sort_proposals_by_score", {
    params: {
      rfp_id,
      status,
      is_user_score: type === "My Score",
    },
  });
  return responseJson.data.proposals;
}
