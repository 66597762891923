import toast from "@components/Toast";
import {
  Button,
  Checkbox,
  InfoBanner,
  InputTooltip,
  ProfilePicture,
  TextInput,
  TextLink,
} from "@components/library";
import { COLORS, FONTS } from "@constants";
import { RootState } from "@redux/store";
import { createRequestComment } from "@requests/requestComments";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RequestCommentPartial, RfpPageRequest } from "@tsTypes/index";
import { RequestForStartupsShowResponse } from "@tsTypes/request_for_startups/show";
import { track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { USER_ROLES } from "src/constants/users";
import styled from "styled-components";
import line from "./line.svg";
import TOOLTIP_CONTENT from "./tooltipContent";

interface Props {
  companyName: string;
  request: RfpPageRequest | RequestForStartupsShowResponse;
  replyTo?: RequestCommentPartial;
  commentTreeLevel?: number;
  onSuccess: (requestCommentId: number) => void;
  setIsCommenting: Dispatch<SetStateAction<boolean>>;
}

const RequestDiscussionSubmitForm = ({
  companyName,
  request,
  replyTo,
  commentTreeLevel = 0,
  onSuccess,
  setIsCommenting,
}: Props) => {
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const startedWritingRef = useRef(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const handleSubmitComment = async () => {
    if (text.length === 0) return;

    try {
      const response = await createRequestComment({
        text,
        requestId: request.id,
        replyToId: replyTo?.id ?? undefined,
        open: isOpen,
      });

      const { id } = response.data;

      setText("");

      onSuccess(id);
    } catch (e) {
      toast.error("Something went wrong. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RfpDiscussionSubmitForm#handleSubmitComment");
        span.setTags({
          rfpId: String(request.id),
          replyToId: String(replyTo?.id),
        });
      });
    }
  };

  useEffect(() => {
    if (text.length > 0) startedWritingRef.current = true;
  }, [text]);

  useEffect(() => {
    return () => {
      if (startedWritingRef.current && text.length > 0)
        track(SegmentEventName.Abandon, {
          ui_component: "Abandoned Comment",
          react_component: "RequestDiscussionSubmitForm",
          request_slug: request.slug,
        });
    };
  }, []);

  const answering = replyTo?.id;

  return (
    <Container answering={answering}>
      {answering && <Line src={line} />}
      <Context>
        <ProfilePicture user={currentUser as any} size="xs" />{" "}
        {answering ? <>Respond to {replyTo?.user?.name ?? companyName}</> : <>Ask {companyName}</>}
      </Context>
      <InputTooltip
        content={
          answering ? TOOLTIP_CONTENT.SPONSOR_NEW_ANSWER : TOOLTIP_CONTENT.SCIENTIST_NEW_QUESTION
        }
        width="316px"
        arrowOffsetTop={answering ? "90px" : "125px"}
        tooltipOffsetTop={answering ? "-12px" : "-100px"}
      >
        <TextInput
          value={text}
          labelFont={FONTS.HEADING_5_SEMIBOLD}
          onChange={(e) => setText(e.target.value)}
          textarea
          startingHeight={answering ? "175px" : "64px"}
          resize="none"
          characterLimit={answering ? undefined : 220}
        />
      </InputTooltip>
      {request.confidential && answering && currentUser.role === USER_ROLES.sponsor && (
        <InfoBanner
          type="attention-orange"
          text="You are posting publicly on an opportunity where your company's name is anonymized. Do not reveal information about your company."
          margin="16px 0 0"
          font={FONTS.REGULAR_3}
        />
      )}
      <CheckboxContainer>
        {answering && currentUser.role === USER_ROLES.sponsor && (
          <Checkbox
            label={
              <span>
                Allow {replyTo?.user?.name} to respond with a follow-up comment or question.
                {commentTreeLevel > 1 && replyTo?.user?.role === USER_ROLES.scientist ? (
                  <span>
                    {" "}
                    You can also{" "}
                    <TextLink to={`/profile/${replyTo?.user?.profile_id}`} color={COLORS.HALO_BLUE}>
                      message them directly
                    </TextLink>
                    .
                  </span>
                ) : null}
              </span>
            }
            isChecked={isOpen}
            onChange={(e) => setIsOpen(e.target.checked)}
            size="sm"
          />
        )}
      </CheckboxContainer>
      <Submit>
        <Button size="sm" width="160px" onClick={handleSubmitComment}>
          Submit
        </Button>
        <Cancel onClick={() => setIsCommenting(false)}>Cancel</Cancel>
      </Submit>
    </Container>
  );
};

export default RequestDiscussionSubmitForm;

export const Submit = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  ${FONTS.REGULAR_2}
  ${({ isEditing }) => isEditing && "float: right; margin: 16px 0 0;"}
`;
const Context = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  ${FONTS.MEDIUM_2}
  color: ${COLORS.NEUTRAL_500};
  margin-bottom: 8px;
`;
const Container = styled.div`
  position: relative;
  margin: ${({ answering }) => (answering ? "0 0 32px 24px" : "32px 0 56px 0")};
`;
export const CheckboxContainer = styled.div`
  margin: 16px 0;
`;

export const Cancel = styled.div`
  display: block;
  text-decoration: none;
  cursor: pointer;
  ${FONTS.SEMIBOLD_2}
  color: ${COLORS.HALO_BLUE};
`;

const Line = styled.img`
  position: absolute;
  left: -18px;
  top: -100px;
  z-index: -1;
`;
