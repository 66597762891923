import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

/**
 * Creates a URLSearchParams object using the given initializer.
 *
 * This is identical to `new URLSearchParams(init)` except it also
 * supports arrays as values in the object form of the initializer
 * instead of just strings. This is convenient when you need multiple
 * values for a given key, but don't want to use an array initializer.
 *
 * For example, instead of:
 *
 *   let searchParams = new URLSearchParams([
 *     ['sort', 'name'],
 *     ['sort', 'price']
 *   ]);
 *
 * you can do:
 *
 *   let searchParams = createSearchParams({
 *     sort: ['name', 'price']
 *   });
 *
 * Adapted from react-router-dom v6
 */
const createSearchParams = (init) => {
  return new URLSearchParams(
    typeof init === "string" || Array.isArray(init) || init instanceof URLSearchParams
      ? init
      : Object.keys(init).reduce((memo: any, key) => {
          const value = init[key];
          return memo.concat(Array.isArray(value) ? value.map((v) => [key, v]) : [[key, value]]);
        }, [])
  );
};

// Wrapper for reading and writing search parameters
// Setter can either take an key-value object or a URLSearchParams instance
// Will be replaced by react-router v6 eventually
// Adapted from react-router-dom v6
export const useSearchParams = (): [URLSearchParams, (nextInit: object) => void] => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const setSearchParams = useCallback(
    (nextInit) => {
      const newSearchParams = createSearchParams(nextInit);
      history.push({ search: newSearchParams.toString() });
    },
    [history]
  );

  return [searchParams, setSearchParams];
};

// Custom hook to parse query string of current page
// Usage: const queryParams = useQueryParams(); query.get("param_name");
/**
 * @deprecated Use `useSearchParams` instead
 */
export const useQueryParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
