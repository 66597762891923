import { Icon, ProfilePicture } from "@components/library";
import { COLORS, FONTS, STYLES, WINDOW_DIMENSIONS } from "@constants";
import { GoogleScholarAuthor } from "@tsTypes/google_scholar_authors";
import styled from "styled-components";

interface Props {
  author: GoogleScholarAuthor;
  isSelected: boolean;
  onSelect: (id: number) => void;
}

const VISIBLE_PUBLICATION_COUNT = 3;

export function AuthorCard({ author, isSelected, onSelect }: Props) {
  return (
    <Card isSelected={isSelected} onClick={() => onSelect(author.id)}>
      <ProfilePicture size="md" user={author} />
      <Info>
        <h3 title={author.name}>{author.name}</h3>
        <h4 title={author.title}>{author.title}</h4>
        <h5 title={author.location}>{author.location}</h5>
      </Info>
      <Publications>
        {author.publications.slice(0, VISIBLE_PUBLICATION_COUNT).map((publication) => (
          <Publication key={`publication-${publication.id}`}>
            <Icon name="Publication" size="md" color={COLORS.NEUTRAL_500} />
            <span title={publication.title!}>{publication.title}</span>
          </Publication>
        ))}
      </Publications>
    </Card>
  );
}

const Info = styled.div`
  * {
    margin: 0;
    color: inherit;
    ${STYLES.ONE_LINE_ALT}
  }
  h3 {
    ${FONTS.SEMIBOLD_1}
  }
  h4 {
    ${FONTS.MEDIUM_2}
  }
  h5 {
    ${FONTS.REGULAR_2}
    color: ${COLORS.NEUTRAL_500};
  }
`;

const Publications = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

const Card = styled.div`
  display: grid;
  grid-template-columns: 50px 200px 1fr;
  grid-gap: 16px;

  width: 100%;
  height: 137px;
  padding: 24px;
  border-radius: 4px;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected
      ? `
    background: ${COLORS.BLUE_LIGHT_50};
    border: 1px solid ${COLORS.HALO_BLUE};
    color: ${COLORS.HALO_BLUE};
  `
      : `
    background: ${COLORS.WHITE};
    border: 1px solid ${COLORS.NEUTRAL_250};
    color: ${COLORS.BLACK};
  `}

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;

    & > ${ProfilePicture} {
      flex-basis: 50px;
    }
    & > ${Info} {
      flex-basis: calc(100% - 50px - 16px);
    }
    & > ${Publications} {
      flex-basis: 100%;
    }
  }
`;

const Publication = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 11px;
  max-width: 100%;

  color: ${COLORS.NEUTRAL_500};
  ${FONTS.MEDIUM_2}

  & > span {
    ${STYLES.ONE_LINE_ALT}
  }
`;
