import { DropdownOption, DropdownOptionGroup } from "@components/library/Dropdowns/DropdownOption";
import { getUniversityMembersOptions } from "@requests/universities";
import { getOptionFromValue } from "@utils/dropdownUtils";
import { useEffect, useState } from "react";

export const SORT_BY_OPTIONS = [
  {
    value: "NAME",
    label: "Name",
  },
  {
    value: "PROPOSAL_COUNT",
    label: "Active proposals",
  },
] as const;

export const useDynamicOptions = (): {
  isLoading: boolean;
  nameOptions: DropdownOption[];
  titleOptions: DropdownOption[];
  keywordOptions: DropdownOptionGroup[];
} => {
  const [isLoading, setIsLoading] = useState(true);
  const [nameOptions, setNameOptions] = useState<DropdownOption[]>([]);
  const [titleOptions, setTitleOptions] = useState<DropdownOption[]>([]);
  const [keywordOptions, setKeywordOptions] = useState<DropdownOptionGroup[]>([]);

  useEffect(() => {
    (async () => {
      const data = await getUniversityMembersOptions();
      setKeywordOptions([
        {
          label: "Disciplines",
          options: data.keyword_options.disciplines.map(getOptionFromValue) as DropdownOption[],
        },
        {
          label: "Expertise",
          options: data.keyword_options.areas_of_expertise.map(
            getOptionFromValue
          ) as DropdownOption[],
        },
      ]);

      const { name_options, title_options } = data;
      setNameOptions(name_options.map(getOptionFromValue) as DropdownOption[]);
      setTitleOptions(title_options.map(getOptionFromValue) as DropdownOption[]);

      setIsLoading(false);
    })();
  }, []);

  return {
    isLoading,
    nameOptions,
    titleOptions,
    keywordOptions,
  };
};
