import { Button, Icon, RequestStatusIcon, Tag } from "@components/library";
import { IconName } from "@components/library/Icon/Icon";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { RootState } from "@redux/store";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RequestForCard } from "@tsTypes/index";
import { RequestableType } from "@tsTypes/requests";
import { RfpStatus } from "@tsTypes/rfps";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import { getRfpStatus, getRfpStatusText } from "@utils/rfpUtils";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface Props {
  request: RequestForCard;
  isUserFollowing: boolean;
  isCardHovered: boolean;
  shouldShowUnsubscribeButton?: boolean;
}

const RfpCardFooter = ({
  request,
  isUserFollowing,
  isCardHovered,
  shouldShowUnsubscribeButton = false,
}: Props) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const statusElementOptions = {
    comingSoon: <Status color={COLORS.ORANGE}>Coming soon!</Status>,
    active: (
      <Status color={COLORS.GREEN_800}>
        Apply by {format(new Date(request.requestable.deadline ?? 0), "MMM d")}
      </Status>
    ),
    inExtension: <Status color={COLORS.NEUTRAL_600}>Complete</Status>,
    pastDeadline: <Status color={COLORS.NEUTRAL_600}>Complete</Status>,
  };

  if (request.requestable_type === RequestableType.RFP && request.requestable.is_trial) {
    const waitListStatus = (
      <Status color={COLORS.GREEN_800}>
        <RequestStatusIcon status="trial" />
        Accepting first 5 proposals
      </Status>
    );

    statusElementOptions.active = waitListStatus;
    statusElementOptions.inExtension = waitListStatus;
  }

  let statusTagContent = "Solutions";
  let statusTagIconName: IconName = "RFP";
  switch (request.requestable_type) {
    case RequestableType.REQUEST_FOR_EXPERT:
      statusTagContent = "Experts";
      statusTagIconName = "Request for experts";
      break;
  }

  const shouldShowNotifyButton =
    getRfpStatus(request) === RfpStatus.COMING_SOON && currentUser.role !== UserRole.SPONSOR;

  return (
    <Container data-testid="footer">
      {!shouldShowUnsubscribeButton && !shouldShowNotifyButton && (
        <Tag
          size="sm"
          backgroundColor={COLORS.BLUE_LIGHT_300}
          color={COLORS.BLUE_600}
          font={FONTS.SEMIBOLD_3}
          content={statusTagContent}
          iconName={statusTagIconName}
        />
      )}
      {getRfpStatusText(request, statusElementOptions)}
      {shouldShowUnsubscribeButton ? (
        <Button
          variant="destructive-secondary"
          size="sm"
          onClick={() => {
            track(SegmentEventName.Click, {
              react_component: "RfpCardFooter",
              ui_component: "Decline Updates Button",
              request_slug: request.slug,
            });
          }}
        >
          Stop notifications
        </Button>
      ) : (
        shouldShowNotifyButton &&
        (isUserFollowing ? (
          <OptedIn>
            <Icon name="Success" color={COLORS.GREEN} size="sm" />
            You&apos;ll be notified
          </OptedIn>
        ) : (
          <NotifyMe>
            <Icon name="Bell" color={COLORS.HALO_BLUE} />
            <NotifyMeText isCardHovered={isCardHovered}>Notify me</NotifyMeText>
          </NotifyMe>
        ))
      )}
    </Container>
  );
};

export default RfpCardFooter;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border-top: 1px solid ${COLORS.NEUTRAL_200};
  ${FONTS.SEMIBOLD_3};
`;
const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ color }) => color};
`;
const OptedIn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${COLORS.GREEN};
`;
const NotifyMe = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  color: ${COLORS.HALO_BLUE};
  pointer-events: fill;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
`;
const NotifyMeText = styled.span`
  display: inline-block;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  ${({ isCardHovered }) =>
    isCardHovered &&
    `
      max-width: 67px;
      margin-left: 4px;
  `}
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    max-width: 67px;
    margin-left: 4px;
  }
`;
