import { Button, Icon, IconButton } from "@components/library";
import { COLORS } from "@constants";
import { fetchUniversityMembers as fetchUniversityMembersAction } from "@redux/actions/defaultActions";
import { SegmentEventName, SegmentEventObject } from "@tsTypes/__generated__/enums";
import { UserRole } from "@tsTypes/users";
import { track, usedPersonalEmail } from "@utils/appUtils";
import { css } from "aphrodite";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { Dropdown, Modal } from "semantic-ui-react";
import api from "src/requests/request";
import Validator from "validator";
import {
  DropdownItem,
  DropdownMenu,
  DropdownSubHeader,
  InviteModalDropdown,
  styles,
} from "./InviteModal.styles";

const ROLES = {
  0: "Faculty",
  2: "Administrator",
};

class InviteModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      contactModalLoading: false,
      message: props.inviteMessage,
      toEmails: "",
      email: "",
      isExpanded: true,
      step: 0,
      emailList: [],
      error: "",
      focused: true,
      dropdownActive: false,
      role: props.role,
    };
  }

  initialState = () => {
    const { inviteMessage, role } = this.props;

    return {
      contactModalLoading: false,
      message: inviteMessage,
      toEmails: "",
      email: "",
      isExpanded: true,
      error: "",
      step: 0,
      emailList: [],
      focused: true,
      role: role,
    };
  };

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState(this.initialState());
    }
  }

  handleSubmitMessage = async (e) => {
    const {
      closeModal,
      currentUser,
      typeOfInvite,
      inviteUrl,
      organization,
      trackAndToastHandler,
      requestId,
      fetchUniversityMembers,
    } = this.props;

    const { role } = this.state;

    e.preventDefault();

    const { message, isExpanded, emailList } = this.state;

    this.setState({
      contactModalLoading: true,
    });

    const emails = emailList
      .map((email) => {
        return email.value;
      })
      .join(",");

    const data = {
      ...(isExpanded && message && message.length > 0 && { message }),
      to_emails: emails,
      organization,
      role,
      invited_by: currentUser.id,
    };

    if (requestId) data.request_id = requestId;

    const responseJson = await api.post(inviteUrl, data);

    if (!responseJson.data.success) {
      this.setState({
        contactModalLoading: false,
      });
    } else {
      if (typeOfInvite === "shareRFP") {
        track(SegmentEventName.Share, {
          request_id: requestId,
          object: SegmentEventObject.Request,
        });

        // Don't display confirmation modal for sharing an RFP
        closeModal();
      }

      this.setState({
        message: "",
        contactModalLoading: false,
        toEmails: "",
        step: 1,
      });
      if (currentUser.role === UserRole.UNIVERSITY_ADMIN)
        fetchUniversityMembers(currentUser.profile_info.university_id);
      trackAndToastHandler?.();
    }
  };

  handleChange = (e, attr) => {
    // make sure an empty space is not added
    if (attr === "email") {
      if (e.target.value.length === 1 && e.target.value === " ") {
        e.preventDefault();
        return;
      }
    }
    this.setState({ [attr]: e.target.value, error: "" });
  };

  handleAddOrRemoveEmail = (e) => {
    if (
      this.state.email &&
      (["Enter", "Tab", ",", ";"].includes(e.key) || e.type === "blur" || e.type === "click")
    ) {
      e.preventDefault();
      const email = {
        value: this.state.email.trim().toLowerCase(),
        isValid: false,
      };

      if (email.value && this.isValid(email.value)) {
        email.isValid = true;
      }

      const emailList = [...this.state.emailList, email];
      this.setState({
        emailList,
        email: "",
      });
    }

    if (e.key === "Backspace") {
      if (!this.state.email && this.state.emailList.length) {
        const emailList = [...this.state.emailList];
        emailList.pop();

        this.setState({
          emailList,
          error: "",
        });
      }
    }
  };

  isValid = (email) => {
    let error = null;

    if (!Validator.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (usedPersonalEmail(email)) {
      error = "Please enter a university or company email.";
    }

    if (this.state.emailList.some((e) => e.value === email)) {
      const temp = email;
      // clearing email state to display error message of a duplicate email
      this.setState({ email: "" });
      error = `${temp} has already been added.`;
    }
    if (error) {
      this.setState({ error });
      return false;
    }
    return true;
  };

  handlePaste = (e) => {
    e.preventDefault();

    const paste = e.clipboardData.getData("text");
    let emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    // take care of duplicates copied to clipboard
    emails = [...new Set(emails)];

    if (emails) {
      const emailList = this.state.emailList.map((e) => e.value);
      const filteredDuplicateEmails = emails.filter((email) => !emailList.includes(email));

      const toBeAdded = filteredDuplicateEmails.map((email) => ({
        value: email,
        isValid: !usedPersonalEmail(email),
      }));

      this.setState({
        emailList: [...this.state.emailList, ...toBeAdded],
      });
    }
  };

  // make sure the list of emails are all valid
  validEmailList = () => {
    const { emailList } = this.state;

    if (emailList.length < 1) return false;

    return !emailList.some((email) => !email.isValid);
  };

  handleNextStep = (e) => {
    const { emailList, error } = this.state;

    const email = this.state.email.trim().toLowerCase();

    if (email && this.isValid(email)) {
      const emailList = [...this.state.emailList, email];
      const toEmails = emailList.join(", ");

      this.setState({
        emailList,
        email: "",
        toEmails,
        error: "",
      });
    } else if (emailList.length > 0 && email.length === 0) {
      if (this.validEmailList()) {
        this.handleSubmitMessage(e);
      } else {
        this.setState({ error: "Please make sure that all addresses are properly formed." });
      }
    } else if (!email && emailList.length === 0) {
      this.setState({ error: "Please add an email to invite" });
    }
  };

  deleteEmailFromList = (emailIndex) => {
    const emailList = [...this.state.emailList];
    emailList.splice(emailIndex, 1);

    this.setState({
      emailList,
      error: "",
    });
  };

  focusInput = (e) => {
    const children = e.target.children;
    this.setState({ focused: true });
    if (children.length) children[children.length - 1].focus();
  };

  handleBlur = (e) => {
    this.handleAddOrRemoveEmail(e);
    this.setState({ focused: false });
  };

  handleCloseConfirmationModal = () => {
    const { closeModal } = this.props;

    closeModal();
    this.setState({ emailList: [] });
  };

  render() {
    const {
      open,
      closeModal,
      currentUser,
      activeTabIndex,
      typeOfInvite,
      modalHeader,
      modalSubheader,
      inviteSubject,
    } = this.props;

    const {
      message,
      toEmails,
      email,
      isExpanded,
      contactModalLoading,
      emailList,
      step,
      error,
      focused,
      role,
      dropdownActive,
    } = this.state;

    const emails = emailList.map((email, i) => {
      if (step === 0) {
        return (
          <div
            key={`email-tile-${i}`}
            className={css(
              styles.InviteModal_emailChip,
              !email.isValid && styles.InviteModal_invalidEmailChip
            )}
            data-testid="invite-modal-email"
          >
            {email.value}
            <div
              className={css(styles.InviteModal_deleteIcon)}
              onClick={() => this.deleteEmailFromList(i)}
            >
              <Icon name="X" size="xs" color={email.isValid ? COLORS.BLACK : COLORS.RED} />
            </div>
          </div>
        );
      }
      return (
        <div
          key={i}
          className={css(
            styles.InviteModal_emailChip,
            styles.ConfirmationInviteModal_emailChip,
            emailList.length === 1 && styles.InviteModal_oneEmailChip
          )}
          data-testid="invite-modal-email"
        >
          {email.value}
        </div>
      );
    });

    const placeholder =
      emails.length > 0
        ? "Add email"
        : typeOfInvite === "shareRFP"
        ? "Enter email address"
        : "Add one or more email addresses";

    return (
      <Modal
        open={open}
        onClose={closeModal}
        size="tiny"
        children
        className={`${css(styles.InviteModal_modal)}`}
        data-testid="invite-modal"
      >
        {step === 0 ? (
          <Modal.Content className={`${css(styles.InviteModal_content)}`}>
            <div className={css(styles.InviteModal_header)}>
              {modalHeader}
              <IconButton iconName="Close" size="sm" onClick={closeModal} variant="fourth" />
            </div>
            <div className={css(styles.InviteModal_subheader)}>{modalSubheader}</div>
            <div style={{ display: "flex" }}>
              <div>
                <div className={css(styles.InviteModal_inputLabel)}>Email Address</div>
                <div
                  className={css(
                    styles.InviteModal_modalInputDiv,
                    focused && styles.InviteModal_modalInputDivFocus,
                    emails.length > 0 && styles.InviteModal_modalInputDivFilled,
                    error && styles.InviteModal_errorField,
                    typeOfInvite === "university" && styles.InviteModal_smallerInputDiv
                  )}
                >
                  {emails}
                  <input
                    type="text"
                    className={css(
                      styles.InviteModal_modalInput,
                      emails.length === 0 && styles.InviteModal_modalInputZero
                    )}
                    style={{
                      width: email.length + "ch",
                      minWidth: emails.length > 0 ? "10ch" : "85%",
                    }}
                    placeholder={placeholder}
                    value={email}
                    autoFocus
                    onFocus={this.focusInput}
                    onChange={(e) => this.handleChange(e, "email")}
                    onKeyDown={this.handleAddOrRemoveEmail}
                    onPaste={this.handlePaste}
                    onBlur={this.handleBlur}
                    data-testid="invite-new-user-text-field"
                  />
                </div>
              </div>
              {typeOfInvite === "university" && (
                <div>
                  <div
                    className={css(
                      styles.InviteModal_inputLabel,
                      styles.InviteModal_inputLabelDropdown
                    )}
                  >
                    Role
                  </div>
                  <InviteModalDropdown
                    as={Dropdown}
                    fluid
                    admin={role === 2}
                    text={ROLES[role]}
                    selection
                    dropdownActive={dropdownActive}
                    onClick={() => this.setState({ dropdownActive: !dropdownActive })}
                    onBlur={() => this.setState({ dropdownActive: false })}
                    icon="chevron down"
                  >
                    <DropdownMenu as={Dropdown.Menu}>
                      <DropdownItem as={Dropdown.Item} onClick={() => this.setState({ role: 2 })}>
                        Administrator
                        <DropdownSubHeader>
                          Administrators can share opportunities with faculty, but cannot submit
                          proposals.
                        </DropdownSubHeader>
                      </DropdownItem>
                      <DropdownItem as={Dropdown.Item} onClick={() => this.setState({ role: 0 })}>
                        Faculty
                        <DropdownSubHeader>
                          Faculty conduct research and can submit proposals to opportunities
                        </DropdownSubHeader>
                      </DropdownItem>
                    </DropdownMenu>
                  </InviteModalDropdown>
                </div>
              )}
            </div>
            {email.length > 0 && (
              <div style={{ position: "relative" }}>
                <div
                  className={css(styles.InviteModal_emailDropdown)}
                  onClick={this.handleAddOrRemoveEmail}
                >
                  Invite '{email.trim().toLowerCase()}' by email
                </div>
              </div>
            )}
            <div className={css(styles.InviteModal_errorMessage)}>{error}</div>
            <div style={{ margin: "auto", paddingTop: "25px" }}>
              <div className={css(styles.InviteModal_inputLabel)}>Message (Optional)</div>
              <textarea
                className={css(styles.InviteModal_modalTextArea)}
                placeholder={
                  typeOfInvite === "shareRFP" ? "Add a message" : "Add a message to your invite"
                }
                value={message}
                rows={5}
                onFocus={(e) =>
                  e.currentTarget.setSelectionRange(
                    e.currentTarget.value.length,
                    e.currentTarget.value.length
                  )
                }
                onChange={(e) => this.handleChange(e, "message")}
                data-testid="invite-new-user-message-field"
              />
            </div>
            <div className={css(styles.InviteModal_modalButtonHolder)}>
              <Button
                type="button"
                disabled={contactModalLoading || emailList.length === 0}
                onClick={this.handleNextStep}
                data-testid="invite-new-user-submit-button"
              >
                {typeOfInvite === "shareRFP" ? "Share" : "Send Invite"}
              </Button>
            </div>
          </Modal.Content>
        ) : (
          <Modal.Content className={`${css(styles.InviteModal_content)} scrolling`}>
            <div className={css(styles.InviteModal_header)}>Invitation sent!</div>
            <div
              className={css(
                styles.InviteModal_subheader,
                styles.ConfirmationInviteModal_subheader
              )}
            >
              Your invitation to the following people has been sent:
            </div>
            <div>{emails}</div>
            <div
              className={css(styles.InviteModal_subheader, styles.ConfirmationInviteModal_footer)}
            >
              Once they accept your invitation, they will become part of your team!
            </div>
            <Button type="button" onClick={this.handleCloseConfirmationModal}>
              Got it
            </Button>
          </Modal.Content>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.profiles.currentUser,
  };
};

const mapDispatchToProps = {
  fetchUniversityMembers: fetchUniversityMembersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteModal);
