import { Button } from "@components/library";
import { AttachmentForDisplay } from "@components/library/Attachments/MessageAttachment";
import { COLORS, FONTS } from "@constants";
import { RequestAttachmentPartial } from "@tsTypes/request_attachments/_request_attachment";
import { stopPropagation } from "@utils/appUtils";
import { useMemo, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  attachments: (AttachmentForDisplay | RequestAttachmentPartial)[];
  focusedAttachmentId: number | undefined;
}

interface LoadState {
  [attachmentId: number]: {
    isLoading: boolean;
    hasError: boolean;
    numPdfPages?: number;
  };
}

const INITIAL_LOAD_STATE = {
  isLoading: true,
  hasError: false,
  numPdfPages: 0,
};

/**
 * Handles display of attachments within the message attachment gallery.
 * Loads all previews concurrently in the background but only displays the focused one.
 *
 * TODO:
 * - Add support for zoom
 */
const MessageAttachmentGalleryPreview = ({ attachments, focusedAttachmentId }: Props) => {
  // Prioritize the first focused attachment to load first
  const prioritizedAttachments = useMemo<AttachmentForDisplay[]>(() => {
    const focusedIndex = attachments.findIndex(
      (attachment) => attachment.id === focusedAttachmentId
    );
    return [...attachments.slice(focusedIndex), ...attachments.slice(0, focusedIndex)];
  }, [attachments]);

  const [loadStates, setLoadStates] = useState<LoadState>(() => {
    const newLoadStates = {};
    attachments.forEach((attachment) => {
      newLoadStates[attachment.id!] = INITIAL_LOAD_STATE;
    });
    return newLoadStates;
  });

  // Set given image attachment as loaded
  const handleImageLoad = (attachmentId: number) => {
    setLoadStates((oldLoadStates) => ({
      ...oldLoadStates,
      [attachmentId]: {
        isLoading: false,
        hasError: false,
      },
    }));
  };

  // Set given image attachment number of pages
  const handlePdfLoad = ({ numPages }: { numPages: number }, attachmentId: number) => {
    setLoadStates((oldLoadStates) => ({
      ...oldLoadStates,
      [attachmentId]: {
        isLoading: false,
        hasError: false,
        numPdfPages: numPages,
      },
    }));
  };

  const handleError = (attachmentId: number) => {
    setLoadStates((oldLoadStates) => ({
      ...oldLoadStates,
      [attachmentId]: {
        isLoading: false,
        hasError: true,
        numPdfPages: 0,
      },
    }));
  };

  console.log(prioritizedAttachments);

  // Render all attachments but only show the focused attachment
  return (
    <>
      {prioritizedAttachments.map((attachment) => {
        const { id, mime_content_type, download_url } = attachment;
        const url = "url" in attachment ? attachment.url : attachment.file_url;
        const isImage = mime_content_type?.startsWith("image/");
        const { isLoading, hasError, numPdfPages } = loadStates[id!];

        return (
          <Container key={`attachment-preview-${id}`} active={id === focusedAttachmentId}>
            {(() => {
              if (hasError) {
                return (
                  <Error>
                    We can&rsquo;t preview this file at the moment.
                    <br />
                    <a href={download_url} download>
                      <Button iconName="Download" margin="20px 0">
                        Download file
                      </Button>
                    </a>
                  </Error>
                );
              }
              if (isImage) {
                return (
                  <Image.Container>
                    <Loader active={isLoading} size="big" inverted />
                    <Image.Content
                      src={url}
                      active={!isLoading}
                      onError={() => handleError(id!)}
                      onLoad={() => handleImageLoad(id!)}
                      onClick={stopPropagation}
                    />
                  </Image.Container>
                );
              }
              // is PDF
              return (
                <Pdf.Container>
                  <div onClick={stopPropagation}>
                    <Document
                      file={url}
                      loading={
                        <Loader
                          style={{ marginTop: "35vh" }}
                          active
                          size="big"
                          inverted
                          inline="centered"
                        />
                      }
                      onLoadError={() => handleError(id!)}
                      onLoadSuccess={(pdf) => handlePdfLoad(pdf, id!)}
                    >
                      {!isLoading &&
                        Array.from(new Array(numPdfPages), (_, i) => (
                          <Page
                            loading=""
                            pageNumber={i + 1}
                            key={`attachment-pdf-${id}-page-${i + 1}`}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            onLoadError={() => handleError(id!)}
                          />
                        ))}
                    </Document>
                  </div>
                </Pdf.Container>
              );
            })()}
          </Container>
        );
      })}
    </>
  );
};

export default MessageAttachmentGalleryPreview;

const Container = styled.div`
  margin: 13px 0;
  height: 0;

  display: flex;
  flex: 0 0 85%;
  justify-content: space-around;
  align-items: center;
  gap: 30px;

  ${({ active }) => !active && "display: none;"}
`;

const Error = styled.div`
  display: block;

  ${FONTS.REGULAR_1}
  color: ${COLORS.WHITE};
  text-align: center;
`;

const Image = {
  Container: styled.div`
    height: 100%;

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Content: styled.img`
    max-width: 95%;
    max-height: 100%;

    object-fit: contain;

    ${({ active }) => !active && "display: none;"}
  `,
};

const Pdf = {
  Container: styled.div`
    max-width: 95%;
    max-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
  `,
};
