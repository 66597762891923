import { useEffectRefresh } from "@hooks/useEffectRefresh";
import api from "@requests/request";
import { getUser } from "@requests/users";
import { useQuery } from "@tanstack/react-query";
import { FundingPartial } from "@tsTypes/fundings/_funding";
import { ScientistAttachmentProposal, ScientistShowUser } from "@tsTypes/index";
import { PatentPartial } from "@tsTypes/patents/_patent";
import { PublicationPartial } from "@tsTypes/publications/_publication";
import appsignal from "@utils/appsignal";
import { useEffect, useState } from "react";
import { NetworkScientist } from "src/views/ScientistNetwork/ScientistNetwork";

export type PositionInfo = {
  id: number;
  location: string | undefined;
  title: string | undefined;
  duration: string | undefined;
};

export function ScientistQueryKey(id: number | undefined) {
  return ["useScientist", id];
}

export function useScientist(id: number | undefined): {
  scientist: NetworkScientist | undefined;
  about: string;
  positions: PositionInfo[];
  isScientistLoading: boolean;
} {
  const [scientist, setScientist] = useState<NetworkScientist>();
  const [about, setAbout] = useState("");
  const [positions, setPositions] = useState<PositionInfo[]>([]);

  const { isLoading } = useQuery({
    queryKey: ScientistQueryKey(id),
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await getUser(id);
      return response;
    },
    onError: () => {
      setAbout("");
      setPositions([]);
    },
    onSuccess: (response) => {
      if (!response) {
        setAbout("");
        setPositions([]);
        return;
      }
      const user = response.user;
      const scientistProfile = (user as ScientistShowUser).profile_info;
      setScientist({
        id: user.id,
        name: user.name,
        image: user.image,
        profile_id: user.profile_id,
        keywords: user.keywords,
        is_in_network: scientistProfile.is_in_network,
        profile_info: {
          id: scientistProfile.id,
          title: scientistProfile.title ?? "",
          location: scientistProfile.location ?? "",
        },
      });
      // Add about text
      setAbout(scientistProfile.about ?? "");
      const pos: PositionInfo[] = [];
      // Add university position
      if (scientistProfile.university_id) {
        pos.push({
          id: -1,
          location: scientistProfile.university ?? "",
          title: scientistProfile.title ?? "",
          duration:
            scientistProfile.university_start_year || scientistProfile.university_end_year
              ? `${scientistProfile.university_start_year ?? "?"} - ${
                  scientistProfile.university_end_year ?? "?"
                }`
              : undefined,
        });
      }
      // Add startup position
      if (scientistProfile.startup?.id) {
        pos.push({
          id: -2,
          location: scientistProfile.startup.startup_name ?? "",
          title: scientistProfile.is_startup_scientist ? scientistProfile.title ?? "" : undefined,
          duration:
            scientistProfile.startup.start_year || scientistProfile.startup.end_year
              ? `${scientistProfile.startup.start_year ?? "?"} - ${
                  scientistProfile.startup.end_year ?? "?"
                }`
              : undefined,
        });
      }
      // Add educations
      pos.push(
        ...scientistProfile.educations.map((education) => ({
          id: education.id,
          location: education.location ?? "",
          title: education.degree ?? education.field_of_study ?? "",
          duration:
            education.start_year || education.end_year
              ? `${education.start_year ?? "?"} - ${education.end_year ?? "?"}`
              : undefined,
        }))
      );
      setPositions(pos);
    },
  });

  return { scientist, about, positions, isScientistLoading: isLoading };
}

export function usePublications(scientistProfileId: number | undefined): {
  publications: PublicationPartial[];
  refresh: () => void;
} {
  const [publications, setPublications] = useState<PublicationPartial[]>([]);
  const { refreshKey, refresh } = useEffectRefresh();

  useEffect(() => {
    if (scientistProfileId) {
      (async () => {
        try {
          const responseJson = await api.get(`/publications?profile_info_id=${scientistProfileId}`);
          const pubs = responseJson.data.publications;
          setPublications(pubs);
        } catch (_) {
          setPublications([]);
        }
      })();
    }
  }, [scientistProfileId, refreshKey]);

  return { publications, refresh };
}

export function useFundings(scientistProfileId: number | undefined): {
  fundings: FundingPartial[];
  refresh: () => void;
} {
  const [fundings, setFundings] = useState<FundingPartial[]>([]);
  const { refreshKey, refresh } = useEffectRefresh();

  useEffect(() => {
    if (scientistProfileId) {
      (async () => {
        try {
          const responseJson = await api.get(`/fundings?profile_info_id=${scientistProfileId}`);
          const funds = responseJson.data.fundings;
          setFundings(funds);
        } catch (_) {
          setFundings([]);
        }
      })();
    }
  }, [scientistProfileId, refreshKey]);

  return { fundings, refresh };
}

export function usePatents(scientistProfileId: number | undefined): {
  patents: PatentPartial[];
  refresh: () => void;
} {
  const [patents, setPatents] = useState<PatentPartial[]>([]);
  const { refreshKey, refresh } = useEffectRefresh();

  useEffect(() => {
    if (scientistProfileId) {
      (async () => {
        try {
          const responseJson = await api.get(`/patents?profile_info_id=${scientistProfileId}`);
          const pats = responseJson.data.patents;
          setPatents(pats);
        } catch (_) {
          setPatents([]);
        }
      })();
    }
  }, [scientistProfileId, refreshKey]);

  return { patents, refresh };
}

export function useProposals(
  scientistProfileId: number | undefined,
  asUniversityAdmin = false
): ScientistAttachmentProposal[] {
  const [proposals, setProposals] = useState<ScientistAttachmentProposal[]>([]);

  useEffect(() => {
    if (scientistProfileId) {
      (async () => {
        try {
          const responseJson = await api.get(`/scientist_profile/proposals/${scientistProfileId}`);
          const rawProposals = asUniversityAdmin
            ? responseJson.data.proposals
            : responseJson.data.proposals.filter((p) => p.completed);
          setProposals(rawProposals);
        } catch (e) {
          appsignal.sendError(e, (span) => {
            span.setAction("ScientistQuickViewModal#useProposals");
          });
          setProposals([]);
        }
      })();
    }
  }, [scientistProfileId]);

  return proposals;
}
