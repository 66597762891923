import { Tag, TagTooltip } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { RfpPageRequest } from "@tsTypes/index";
import { MAX_KEYWORD_DISPLAY_COUNT } from "src/constants/requests";
import styled from "styled-components";

interface Props {
  request: RfpPageRequest;
}

const RfpKeywordsContainer = ({ request }: Props) => {
  const keywords = [...request.areas_of_expertise, ...request.disciplines];

  return (
    <Container>
      {keywords.length > 0 && (
        <div>
          <Heading>Seeking partners focused on</Heading>
          <Tags>
            {keywords.slice(0, MAX_KEYWORD_DISPLAY_COUNT).map((keyword) => (
              <Tag key={keyword} content={keyword} size="sm" theme="neutral" />
            ))}
            {keywords.length > MAX_KEYWORD_DISPLAY_COUNT && (
              <TagTooltip
                tags={keywords.slice(MAX_KEYWORD_DISPLAY_COUNT).map((keyword) => (
                  <Tag key={keyword} content={keyword} size="sm" theme="neutral" />
                ))}
              >
                <Tag
                  content={`${keywords.length - MAX_KEYWORD_DISPLAY_COUNT} more`}
                  size="sm"
                  theme="neutral"
                />
              </TagTooltip>
            )}
          </Tags>
        </div>
      )}
    </Container>
  );
};

export default RfpKeywordsContainer;

const Container = styled.div`
  padding: 24px;
  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 8px;
`;
const Heading = styled.div`
  margin-bottom: 20px;
  ${FONTS.SEMIBOLD_1};
`;
const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  & > div {
    margin-top: 8px;
  }
`;
