import Icon, { IconName } from "@components/library/Icon/Icon";
import { COLORS, FONTS } from "@constants";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  iconName: IconName;
  title: string | ReactNode;
  description: string | ReactNode;
  to: string;
  width: string;
}

export default function Step4NextStep({ iconName, title, description, to, width }: Props) {
  return (
    <Container to={to} width={width}>
      <Icon name={iconName} size="md" color={COLORS.BLACK} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
}

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: ${({ width }) => width};
  height: 152px;
  padding: 16px;

  cursor: pointer;
  border-radius: 6px;
  border: 1px solid ${COLORS.NEUTRAL_250};

  &:hover {
    text-decoration: none;
  }
`;

const Title = styled.h3`
  margin: 0;
  color: ${COLORS.HALO_BLUE};
  ${FONTS.REGULAR_1}
`;

const Description = styled.p`
  margin: 0;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.MEDIUM_3}
  line-height: 1.5;
`;
