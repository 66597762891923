import { Button } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import { signOut } from "@requests/users";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { assertAbsurd } from "@utils/tsUtils";
import { useEffect } from "react";
import styled from "styled-components";
import { UserDetailedType, UserInfo } from "../OnboardingContainer";
import * as S from "../StepStyles";

interface Props {
  values: UserInfo;
  setWork: (v?: string) => void;
  setRole: (v?: string) => void;
  setDetailedType: (v?: UserDetailedType) => void;
  onContinue: () => void;
}

function Step2({ values, setWork, setRole, setDetailedType, onContinue }: Props) {
  const { work, role, detailedType } = values;

  useEffect(() => {
    switch (detailedType) {
      case UserDetailedType.COMPANY_SPONSOR:
      case UserDetailedType.VENTURE_CAPITAL_SPONSOR:
      case UserDetailedType.GOVERNMENT_SPONSOR:
        setRole("company-sponsor");
        break;
      case UserDetailedType.UNIVERSITY_SCIENTIST:
        setRole("research-scientist");
        break;
      case UserDetailedType.STARTUP_SCIENTIST:
      case UserDetailedType.SUPPLIER:
      case UserDetailedType.CONSULTANT:
        setRole("company-scientist");
        break;
      case UserDetailedType.UNIVERSITY_ADMINISTRATOR:
        setRole("research-administrator");
        break;
      case undefined:
        break;
      default:
        assertAbsurd(detailedType);
    }

    if (detailedType) {
      track(SegmentEventName.Click, {
        react_component: "Onboarding - Step2",
        ui_component: "Detailed type selection",
        detailed_type: detailedType,
      });
    }
  }, [detailedType]);

  return (
    <S.StepContainer width="608px">
      <S.StepHeader
        subheading="Welcome to Halo"
        heading="Tell us about your organization and role."
      />
      <OptionsHeading>Research Institutions</OptionsHeading>
      <OptionsContainer>
        <Option
          selected={detailedType === UserDetailedType.UNIVERSITY_SCIENTIST}
          onClick={() => setDetailedType(UserDetailedType.UNIVERSITY_SCIENTIST)}
        >
          I&rsquo;m a <b>researcher</b> at a university, lab, or institute
        </Option>
        <Option
          selected={detailedType === UserDetailedType.UNIVERSITY_ADMINISTRATOR}
          onClick={() => setDetailedType(UserDetailedType.UNIVERSITY_ADMINISTRATOR)}
        >
          I&rsquo;m an <b>administrator</b> at a university
        </Option>
      </OptionsContainer>
      <OptionsHeading>Private Sector</OptionsHeading>
      <OptionsContainer>
        <Option
          selected={detailedType === UserDetailedType.COMPANY_SPONSOR}
          onClick={() => setDetailedType(UserDetailedType.COMPANY_SPONSOR)}
        >
          I&rsquo;m in <b>corporate R&D</b>, looking to post partnering requests
        </Option>
        <Option
          selected={detailedType === UserDetailedType.STARTUP_SCIENTIST}
          onClick={() => setDetailedType(UserDetailedType.STARTUP_SCIENTIST)}
          data-testid="startup-scientist-radio-button"
        >
          I&rsquo;m at a <b>startup</b>, looking to submit proposals or apply to programs
        </Option>
        <Option
          selected={detailedType === UserDetailedType.SUPPLIER}
          onClick={() => setDetailedType(UserDetailedType.SUPPLIER)}
        >
          I&rsquo;m at a <b>supplier or corporation</b>, looking to submit proposals
        </Option>
        <Option
          selected={detailedType === UserDetailedType.CONSULTANT}
          onClick={() => setDetailedType(UserDetailedType.CONSULTANT)}
        >
          I&rsquo;m at a <b>consultancy</b>, looking to submit proposals
        </Option>
      </OptionsContainer>
      <ButtonContainer work={work}>
        {FEATURE_FLAGS.SIGN_OUT_ON_ONBOARDING && (
          <Button
            type="button"
            onClick={async () => {
              await signOut();
            }}
          >
            Sign Out
          </Button>
        )}
        <Button
          type="button"
          data-testid="step2-submit-button"
          disabled={!role}
          onClick={onContinue}
          width="120px"
        >
          Continue
        </Button>
      </ButtonContainer>
    </S.StepContainer>
  );
}

export default Step2;

const ButtonContainer = styled.div<{ work?: string }>`
  margin-top: ${({ work }) => (work ? "40px" : "0px")};
`;

const OptionsHeading = styled.h2`
  margin: 0 0 16px;
  ${FONTS.SEMIBOLD_1}
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 56px;
`;

const Option = styled.div<{ selected?: boolean }>`
  flex-shrink: 0;
  width: 288px;
  padding: 16px;
  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 6px;
  cursor: pointer;
  ${FONTS.REGULAR_1}

  ${({ selected }) =>
    selected &&
    `
    border: 1px solid ${COLORS.HALO_BLUE};
    background: ${COLORS.BLUE_LIGHT_300};
    color: ${COLORS.BLUE_650};
  `}
`;
